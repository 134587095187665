import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class SnackBar {
	constructor(private snackBar: MatSnackBar, private translate: TranslateService) {}

	openSnackBar(message: string, action: string, duration?: number) {
		message = this.translate.instant(message);
		action = this.translate.instant(action);

		this.snackBar.open(message, action.toUpperCase(), {
			duration: duration ? duration : 2000,
		});
	}

	openSnackBarError(message: string) {
		if (message === 'UNAUTHORIZED') message = 'VALIDATIONS.unauthorized';
		if (message.includes('Http failure response')) message = 'VALIDATIONS.disconnected';
		message = this.capitalize(this.translate.instant(message));
		const action = 'error';
		this.snackBar.open(message, action.toUpperCase(), {
			duration: 3000,
		});
	}

	capitalize(name: string) {
		if (name.length > 0) {
			return name[0].toUpperCase() + name.substring(1)
		}
		else {
			return name;
		}
	}
}
