import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';

@Pipe({ name: 'formatSlot' })
export class FormatSlotPipe implements PipeTransform {
	constructor(private translate: TranslateService) {
		let lang = this.translate.currentLang;
		if (lang === 'du') lang = 'nl';
		if (lang) {
			dayjs.locale(lang);
		}
	}

	transform(value: string) {
		if (value) {
			const slotValues = value.split(' ');
			if (slotValues.length === 2) {
				return dayjs(slotValues[0]).format('dd D/M');
			}
		}
		return value;
	}
}
