<mat-card appearance="outlined">
	<mat-card-header>
		<mat-card-title>{{ 'BOOKINGS.availability' | translate | capitalize }}</mat-card-title>
	</mat-card-header>
	<mat-card-content class="mat-card-content">
		<form [formGroup]="formBookingPreferredDate" autocomplete="off">
			<mat-form-field appearance="outline">
				<mat-label>{{ 'FLIGHTS.date' | translate | capitalize }}</mat-label>
				<input matInput [matDatepicker]="picker" (focus)="picker.open()" formControlName="flightDate" />
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker touchUi="true" #picker></mat-datepicker>
			</mat-form-field>
			<mat-radio-group style="display: flex" class="radio-group" formControlName="flightPeriod">
				@for (period of periods; track $index) {
				<mat-radio-button class="radio-button" [value]="period">
					{{ 'FLIGHTS.' + period | translate | capitalize }}
				</mat-radio-button>
				}
			</mat-radio-group>
			<div style="display: flex; gap: 20px; margin-top: 20px"></div>
		</form>
	</mat-card-content>
	<mat-card-actions class="mat-card-actions">
		<button mat-flat-button (click)="save(formBookingPreferredDate.value)">
			{{ 'APPLICATION.save' | translate | capitalize }}
		</button>
		<button mat-raised-button (click)="cancel(formBookingPreferredDate.value)">{{ 'APPLICATION.cancel' | translate | capitalize }}</button>
	</mat-card-actions>
</mat-card>
