import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResponsiveService } from 'src/app/core/services/admin/responsive.service';
import { BookingService } from 'src/app/core/services/booking/booking.service';
import { FlightService } from 'src/app/core/services/flight/flight.service';
import { DialogEmailComponent } from 'src/app/features/dialog/dialog-email/dialog-email.component';

@Component({
	selector: 'app-booking-mail-card',
	templateUrl: './booking-mail-card.component.html',
	styleUrls: ['./booking-mail-card.component.scss'],
})
export class BookingMailCardComponent implements OnInit {
	@Input() bookingId: string;

	mails = [];
	//responsive
	isMobile: boolean;
	subs: Subscription[] = [];

	constructor(private bookingService: BookingService, private responsiveService: ResponsiveService, private dialog: MatDialog) {
		// responsiveService Subscriptions
		this.responsiveService.viewMobileChanged.subscribe((mobileView) => {
			this.isMobile = mobileView;
		});
	}

	ngOnInit(): void {
		if (this.bookingId) {
			const mailSub = this.bookingService.getBookingMails(this.bookingId).valueChanges.subscribe((result) => {
				this.mails = result.data.booking.mails;
				const mailsMerged = result.data.booking.mailsMerged.map((mail) => {
					// add a merge flag to each log
					return { ...mail, merge: true };
				});
				this.mails = this.mails.concat(mailsMerged);
				// sort this mails by date
				this.mails.sort((a, b) => {
					const dateA = new Date(a.resultAt);
					const dateB = new Date(b.resultAt);
					return dateA < dateB ? 1 : -1;
				});
			});
			this.subs.push(mailSub);
		}
	}
	ngOnDestroy(): void {
		this.subs.forEach((sub) => sub.unsubscribe());
	}

	showMail(email) {
		const emails = [email];
		if (emails.length === 0) return;
		const dialogRef = this.dialog.open(DialogEmailComponent, {
			data: {
				emails: emails,
				readOnly: true,
			},
		});
	}

	badgeColor(events) {
		let color = 'primary';
		events.forEach((event) => {
			if (event.event === 'dropped') color = 'warn';
			if (event.event === 'bounce') color = 'warn';
			if (event.event === 'blocked') color = 'warn';
		});
		return color;
	}

	sortMailEvents(events) {
		// make sure events are shown in the right order, even with the same timestamp

		// add 1s to timestamp in case of problem events, event = dropped, bounced, blocked
		// add 2s to timestamp in case of delivered events, event = delivered
		// then sort by timestamp
		// make a new array to avoid changing the original array
		const sortedEvents = events.map((event) => {
			let timestamp = new Date(event.timestamp);
			if (event.event === 'dropped' || event.event === 'bounce' || event.event === 'blocked') {
				timestamp = new Date(timestamp.getTime() + 1000);
			}
			if (event.event === 'delivered') {
				timestamp = new Date(timestamp.getTime() + 2000);
			}
			return { ...event, timestamp: timestamp };
		});
		return sortedEvents.sort((a, b) => {
			return a.timestamp < b.timestamp ? -1 : 1;
		});
	}
}
