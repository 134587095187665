import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-balloon-badge',
	templateUrl: './balloon-badge.component.html',
	styleUrls: ['./balloon-badge.component.scss'],
})
export class BalloonBadgeComponent implements OnInit {
	@Input() balloon: any;

	NOTIFY = 30;

	constructor() {}

	ngOnInit(): void {}
}
