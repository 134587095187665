<div style="display: flex; flex-direction: column; gap: 5px">
	@for (log of logs; track log) {
	<div style="display: flex; flex-direction: row; gap: 10px; justify-content: flex-start; align-items: center">
		<button *ngIf="bookingId" mat-icon-button class="mat-error-button" (click)="deleteLog(log?.id)"><mat-icon>delete</mat-icon></button>
		<button *ngIf="flightId" mat-icon-button class="mat-error-button" (click)="deleteLog(log?.id, log?.booking?.id)">
			<mat-icon>delete</mat-icon>
		</button>
		<div style="min-width: 140px; display: flex; flex-direction: row; gap: 10px; justify-content: flex-start; align-items: center">
			{{ log?.date | formatDate: 'YYYY-MM-DD HH:mm' }}
			<button mat-icon-button disabled="true"><mat-icon *ngIf="log.merge">merge</mat-icon></button>
		</div>
		<div *ngIf="!isMobile" style="min-width: 180px">({{ log?.user }})</div>
		<button
			*ngIf="bookingId && log?.flight?.id"
			mat-stroked-button
			[disabled]="log?.flight?.removed"
			(click)="navigateToFlight(log?.flight.id)"
			style="min-width: 280px; word-wrap: break-word; display: flex; justify-content: flex-start; align-items: center"
		>
			<mat-icon class="mat-accent-icon" *ngIf="log.action === 'INVI'">sms</mat-icon>
			<mat-icon class="mat-accent-icon" *ngIf="log.action === 'ADDE'">assignment</mat-icon>
			<mat-icon class="mat-accent-icon" *ngIf="log.action === 'CONF'">assignment_turned_in</mat-icon>
			<mat-icon *ngIf="log.action === 'REMO'">delete</mat-icon>
			{{ log?.flight?.balloon?.name }} {{ log?.flight?.hour | formatDate: 'DD MMM YY' }}
		</button>
		<div
			*ngIf="bookingId && !log?.flight?.id"
			style="min-width: 280px; word-wrap: break-word; display: flex; justify-content: flex-start; align-items: center"
		>
			{{ 'BOOKINGS.no_flight_added' | translate | capitalize }}
		</div>
		<button
			*ngIf="flightId"
			mat-stroked-button
			(click)="navigateToBooking(log?.booking.id)"
			style="min-width: 280px; word-wrap: break-word; display: flex; justify-content: flex-start; align-items: center"
		>
			<mat-icon class="mat-accent-icon" *ngIf="log.action === 'INVI'">sms</mat-icon>
			<mat-icon class="mat-accent-icon" *ngIf="log.action === 'ADDE'">assignment</mat-icon>
			<mat-icon class="mat-accent-icon" *ngIf="log.action === 'CONF'">assignment_turned_in</mat-icon>
			<mat-icon *ngIf="log.action === 'REMO'">delete</mat-icon>
			{{ log?.booking?.contactName }}
		</button>
		<div *ngIf="!isMobile" style="min-width: 180px">{{ 'BOOKINGS.LOG.' + log.action | translate | capitalize }}</div>
	</div>
	}
</div>
