<div *ngIf="flightCount > 0" style="margin-bottom: 5px">
	<div style="display: flex; flex-direction: column; gap: 5px">
		<mat-card data-tour="card-date" [ngClass]="getCardClass()" (click)="toggleFlights()" class="mat-elevation-z8">
			<mat-card-content>
				<div style="display: flex; gap: 10px; justify-content: space-between">
					<div style="display: flex; flex-direction: column; gap: 10px; min-width: 100px">
						<div style="display: flex">
							<span [ngStyle]="today ? { 'font-weight': 'bold', 'font-size': 'x-large' } : { 'font-size': 'x-large' }">
								{{ date | formatDate: 'dd DD' | capitalize }}
							</span>
							<span style="font-size: small">/{{ date | formatDate: 'M' }}</span>
						</div>
						<mat-card-subtitle style="display: flex" *ngIf="event">
							{{ event | capitalize }}
						</mat-card-subtitle>
					</div>
					<div style="display: flex; flex-direction: column">
						<div style="display: flex; flex-wrap: wrap; align-items: center">
							@for (flight of morningFlights; track flight.id) {
							<div style="display: flex; flex-wrap: wrap; align-items: center">
								<mat-icon class="mat-error-icon">fiber_manual_record</mat-icon>
								<span style="font-size: small">{{ flight.balloon?.name }}</span>
							</div>
							}
						</div>
					</div>
					<div style="display: flex; flex-direction: column">
						<div style="display: flex; flex-wrap: wrap; align-items: center">
							@for (flight of eveningFlights; track flight.id) {
							<div style="display: flex; flex-wrap: wrap; align-items: center">
								<mat-icon class="mat-error-icon">fiber_manual_record</mat-icon>
								<span style="font-size: small">{{ flight.balloon?.name }}</span>
							</div>
							}
						</div>
					</div>
					<div
						style="display: flex; flex-direction: column; justify-content: space-between; align-items: center"
						(click)="$event.stopPropagation()"
					>
						<button *ngIf="flightCount > 0 && guards.plan.includes(userRole)" mat-icon-button (click)="plan()">
							<mat-icon matTooltip="Plan">drag_indicator</mat-icon>
						</button>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
		<div *ngIf="!isXSmall" style="display: flex; flex-direction: column; gap: 5px">
			<div *ngIf="showFlights && morningFlights?.length > 0" @fadeSlideInOut style="display: flex; flex-wrap: wrap; gap: 5px">
				@for (flight of morningFlights; track flight.id) {
				<div style="width: 392px">
					<app-flight-card [flightId]="flight?.id" [userRole]="userRole" [shareBookings]="shareBookings"></app-flight-card>
				</div>
				}
			</div>
			<div *ngIf="showFlights && eveningFlights?.length > 0" @fadeSlideInOut style="display: flex; flex-wrap: wrap; gap: 5px">
				@for (flight of eveningFlights; track flight.id) {
				<div style="width: 392px">
					<app-flight-card [flightId]="flight?.id" [userRole]="userRole" [shareBookings]="shareBookings"></app-flight-card>
				</div>
				}
			</div>
		</div>
		<div *ngIf="isXSmall" style="display: flex; flex-direction: column; gap: 5px">
			<div *ngIf="showFlights && morningFlights?.length > 0" style="display: flex; flex-direction: column; gap: 5px">
				@for (flight of morningFlights; track flight.id) {
				<div style="max-width: 590px">
					<app-flight-card [flightId]="flight?.id" [userRole]="userRole" [shareBookings]="shareBookings"></app-flight-card>
				</div>
				}
			</div>
			<div *ngIf="showFlights && eveningFlights?.length > 0" style="display: flex; flex-direction: column; gap: 5px">
				@for (flight of eveningFlights; track flight.id) {
				<div style="max-width: 590px">
					<app-flight-card [flightId]="flight?.id" [userRole]="userRole" [shareBookings]="shareBookings"></app-flight-card>
				</div>
				}
			</div>
		</div>
	</div>
</div>
