import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	DeleteTagDocument,
	GetAllTagsDocument,
	GetAllTagsWithCountersDocument,
	GetTagDocument,
	GetTagsOfTypeDocument,
	GetTagsOfTypeWithCountersDocument,
	GetTagsOfTypeWithHistoryCountersDocument,
	GetTagsOfTypeWithUpcomingCountersDocument,
	GetTagWithCountersDocument,
	SaveTagDocument,
	Tag,
	TagInput,
	TagType,
} from '../../graphql/generated/gen-types';

@Injectable({
	providedIn: 'root',
})
export class TagService {
	constructor(private apollo: Apollo) {
	}

	getAllTags(includeCounters: boolean): QueryRef<any> {
		return this.apollo.watchQuery({
			query: includeCounters ? GetAllTagsWithCountersDocument : GetAllTagsDocument,
		});
	}

	createOrUpdateTag(input: TagInput, tagId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SaveTagDocument,
				variables: {
					tagInput: input,
					tagId: tagId,
				},
				refetchQueries: tagId
					? []
					: [
							{
								query: GetAllTagsDocument,
							},
							{
								query: GetTagsOfTypeDocument,
								variables: {
									tagType: input.type,
								},
							},
					  ],
			})
			.pipe(map((result: any) => result.data));
	}

	deleteTag(tag: Tag): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: DeleteTagDocument,
				variables: {
					tagId: tag.id,
				},
				update(cache, { data }) {
					//Delete from cache
					const normalizedId = cache.identify(tag);
					cache.evict({ id: normalizedId });
					cache.gc();
				},
			})
			.pipe(map((result: any) => result.data));
	}

	getTagsOfType(tagType: TagType, includeCounters: boolean, onlyUpcoming?: boolean): QueryRef<any> {
		let queryDocument = includeCounters ? GetTagsOfTypeWithCountersDocument : GetTagsOfTypeDocument;
		if (onlyUpcoming !== undefined && includeCounters) {
			queryDocument = onlyUpcoming === true ? GetTagsOfTypeWithUpcomingCountersDocument : GetTagsOfTypeWithHistoryCountersDocument;
		}
		
		return this.apollo.watchQuery({
			query: queryDocument,
			variables: {
				tagType: tagType,
			},
			fetchPolicy: 'network-only',
		});
	}

	getTagFromId(tagId: string, includeCounters: boolean): QueryRef<any> {
		return this.apollo.watchQuery({
			query: includeCounters ? GetTagWithCountersDocument : GetTagDocument,
			variables: {
				tagId: tagId,
			},
			fetchPolicy: 'cache-first',
		});
	}

	sortByTagName(tags: Tag[]): Tag[] {
		let sortedTags = [...tags];
		return sortedTags.sort((a, b) => {
			const nameA = a.tag.toUpperCase(); // ignore upper and lowercase
			const nameB = b.tag.toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return 0;
		});
	}

	addPrefix(tag: Tag): string {
		const prefix: string = 'tag:' + tag.id;
		return prefix;
	}
}