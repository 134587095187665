import autoTable from 'jspdf-autotable';
import { PaymentType } from '../graphql/generated/gen-types';

export function addAdballonDetailPassagiers(
	doc,
	document,
	currency = 'EUR',
	options: { column_width: number; margin_blocks: number; margin_top: number; margin_left: number }
) {
	const tableWidth = 2 * options.column_width + options.margin_blocks;
	const cellWidth = tableWidth / 6;
	doc.addPage();
	let Ypos = 15;

	// Header
	autoTable(doc, {
		head: [[{ content: 'BALLONVAART ' + document.data.header.date, colSpan: 3 }]],
		body: [
			[
				{
					content: document.data.header.balloon,
					colSpan: 2,
                    styles: { cellWidth: 100 },

				},
				document.data.header.takeoffLocation,
			],
			[
				{
					content: 'PIC: ' + document.data.header.pic,
					colSpan: 3,
				},
			],
			[
				{
					content: 'CREW: ' + document.data.header.ground_crews.map((gc) => gc.crew.name).join(', '),
					colSpan: 3,
					styles: { fontSize: 9 },
				},
			],
			[
				{
					content: document.data.header.flightComments,
					colSpan: 3,
					styles: { fontSize: 9 },
				},
			],
		],
		startY: options.margin_top,
		theme: 'grid',
		margin: { left: options.margin_left },
		styles: {
			halign: 'left',
			valign: 'middle',
			fontSize: 14,
			cellPadding: 1,
		},
		tableWidth: 2 * options.column_width + options.margin_blocks,
		headStyles: {
			fillColor: '#d3d3d3',
			textColor: '#000000',
			fontSize: 14,
			halign: 'center',
		},
	});

	// Booking overview
	const categories = [];
	document.data.payments.forEach((p) => {
		categories.push([
			{
				content: `${p.contactName}`,
				styles: { fontStyle: 'bold' },
			},
			{
				content: `${p.contactPhone}`,
				styles: { fontStyle: 'bold' },
			},
			{
				content: p.passengerCount,
				styles: { halign: 'center', fontStyle: 'bold' },
			},
		]);
		categories.push([{ content: p.comments ? p.comments : 'geen opmerkingen', colSpan: 3, styles: { fontSize: 10 } }]);
		p.passengers.forEach((pax, index) => {
			let paxName = pax.age ? pax.name + ' ' + `(${pax.age}y)` : pax.name;
			categories.push([{ content: index + 1 + '. ' + paxName, colSpan: 3 }]);
		});
	});
	const Ybetaaloverzicht = (doc as any).lastAutoTable.finalY;

	autoTable(doc, {
		head: [['RESERVATIE', 'TELEFOON', '#']],
		body: categories,
		startY: Ybetaaloverzicht + 3,
		theme: 'grid',
		margin: { left: options.margin_left },
		styles: {
			halign: 'left',
			valign: 'middle',
			fontSize: 12,
			// cellPadding: 4,
			cellPadding: {
				top: 1,
				bottom: 1,
				left: 2,
				right: 2,
			},
		},
		tableWidth: 2 * options.column_width + options.margin_blocks,
		headStyles: {
			fillColor: '#d3d3d3',
			textColor: '#000000',
			fontSize: 12,
			halign: 'left',
		},
	});
}
