import autoTable from 'jspdf-autotable';

export function addMontgolfiereBetaalOverzicht(doc, document, currency = 'EUR', options: any = {}) {
    const tableWidth = 2 * options.column_width + options.margin_blocks;
    const cellWidth = tableWidth / 6;
    doc.addPage();
    let Ypos = 15;

    // Header
    autoTable(doc, {
        head: [
            [
                {
                    content: 'PAYMENT OVERVIEW',
                    colSpan: 3,
                },
            ],
        ],
        body: [[document.data.header.balloon, document.data.header.passengersCount + ' PAX', document.data.header.date]],
        startY: Ypos,
        theme: 'grid',
        styles: {
            halign: 'center',
            valign: 'middle',
            fontSize: 20,
            cellPadding: 1,
            cellWidth: tableWidth / 3,
        },
        margin: { left: options.margin_left },
        tableWidth: 2 * options.column_width + options.margin_blocks,
        headStyles: {
            fillColor: '#d3d3d3',
            textColor: '#000000',
            fontSize: 14,
            halign: 'center',
        },
    });
    Ypos = (doc as any).lastAutoTable.finalY + options.margin_blocks;

    document.data.payments.forEach((p) => {
        const passengers = [];
        const myHeader = [
            {
                content: (p.contactName as string).toUpperCase(),
                colSpan: 2,
                styles: { fontSize: 10, fontStyle: 'bold', fillColor: '#d3d3d3' },
            },
            {
                content: p.passengers.length + ' PAX',
                styles: {
                    fontSize: 10,
                    fontStyle: 'bold',
                    halign: 'center',
                    fillColor: '#d3d3d3',
                },
            },
            {
                content: document.labels.payments.open_amount + ': ' + currency + ' ' + p.openAmount,
                colSpan: 2,
                styles: {
                    fontSize: 10,
                    fontStyle: 'bold',
                    textColor: p.openAmount > 0 ? '#B50023' : '#555555',
                    fillColor: '#d3d3d3',
                },
            },
            {
                content: '',
                styles: { fontSize: 10, fontStyle: 'bold', fillColor: '#d3d3d3' },
            },
        ];
        passengers.push(myHeader);
        passengers.push([
            {
                content: 'COMP',
                colSpan: 2,
                styles: { fontSize: 9, fontStyle: 'bold' },
            },
            {
                content: p.contactPhone,
                styles: { fontSize: 9 },
            },
            {
                content: document.labels.payments.paid_prepaymentamount + ': ' + (p.prepaymentAmount ? currency + ' ' + p.prepaymentAmount : ''),
                colSpan: 3,
                styles: { fontSize: 9, fontStyle: 'bold' },
            },
        ]);
        passengers.push([
            {
                content: p.comments ? p.comments : '',
                colSpan: 6,
                styles: { fontSize: 9 },
            },
        ]);
        let passengerNames = '';
        p.passengers.forEach((pax) => {
            if (passengerNames === '') {
                passengerNames = pax.name;
            } else {
                passengerNames = passengerNames + ', ' + pax.name;
            }
        });
        passengers.push([
            {
                content: passengerNames,
                colSpan: 6,
                styles: { fontSize: 9 },
            },
        ]);

        autoTable(doc, {
            head: [['NAME', 'TYPE', 'VOUCHER', 'REF', currency, 'PAYMENT']],
            body: passengers,
            startY: Ypos,
            theme: 'grid',
            showHead: 'never',
            margin: { left: options.margin_left },
            styles: {
                halign: 'left',
                valign: 'middle',
                fontSize: 9,
                cellPadding: 1,
                cellWidth: cellWidth,
            },
            tableWidth: tableWidth,
            headStyles: {
                fillColor: '#d3d3d3',
                textColor: '#000000',
                fontSize: 10,
                halign: 'center',
            },
        });
        Ypos = (doc as any).lastAutoTable.finalY;
    });
}