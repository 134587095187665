import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColorPickerControl } from '@iplab/ngx-color-picker';
import { Tag, TagType } from 'src/app/core/graphql/generated/gen-types';
import { TagService } from 'src/app/core/services/tag/tag.service';
import { SnackBar } from 'src/app/core/utility/snackBar';

@Component({
	selector: 'app-tag-new',
	templateUrl: './tag-new.component.html',
	styleUrls: ['./tag-new.component.scss'],
})
export class TagNewComponent implements OnInit {
	newTagForm: UntypedFormGroup;
	isLoading = false;

	colorPickerControl: ColorPickerControl;

	tagTypes: TagType[] = Object.values(TagType);
	tagId: string = '';

	constructor(
		private fb: UntypedFormBuilder,
		private tagService: TagService,
		private snackBar: SnackBar,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private ref: ChangeDetectorRef
	) {
		this.newTagForm = this.fb.group({
			tag: ['', Validators.required],
			type: ['', Validators.required],
			backgroundColor: ['#FFCA28'],
			fontColor: ['black'],
			enabled: [true],
			dashboard: [false],
		});
		this.tagId = this.data ? this.data.id : '';
		if (this.data) {
			if (this.data.type) {
				this.tagTypes = this.tagTypes.filter((tag) => tag === this.data.type);
				this.newTagForm.patchValue({
					type: data.type,
				});
			}
			if (this.data.color) {
				this.newTagForm.patchValue({
					backgroundColor: data.color,
				});
			}
		}
		this.colorPickerControl = new ColorPickerControl();
		this.colorPickerControl.hideAlphaChannel();
		this.colorPickerControl.valueChanges.subscribe((colorHex) => {
			this.newTagForm.get('backgroundColor').patchValue(colorHex.toHexString());
			let fontColor: string = this.getContrastingColor(colorHex.toHexString());
			this.newTagForm.get('fontColor').patchValue(fontColor);
			if (this.newTagForm.pristine) {
				this.newTagForm.markAsDirty();
			}
		});
		this.colorPickerControl.value.setFromString(this.newTagForm.get('backgroundColor').value);
	}

	ngOnInit(): void {
		if (this.tagId) {
			this.isLoading = true;
			this.tagService.getTagFromId(this.tagId, false).valueChanges.subscribe(
				(result) => {
					const tag = result.data.getTag;
					this.newTagForm.patchValue(tag);
					this.isLoading = false;
				},
				(error) => {
					this.snackBar.openSnackBarError(error.message);
					this.isLoading = false;
				}
			);
		}
	}

	saveTag(): void {
		this.isLoading = true;
		const input: Tag = this.newTagForm.value;
		this.tagService.createOrUpdateTag(input, this.tagId).subscribe(
			(result) => {
				this.isLoading = false;
			},
			(err) => {
				this.snackBar.openSnackBarError(err.message);
				this.isLoading = false;
			}
		);
	}

	getContrastingColor(color: string): string {
		if (!color) return 'black';
		let rgb = this.hexToRgb(color);
		let isLight: boolean = rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186;
		return isLight ? 'black' : 'white';
	}

	hexToRgb(hex: string) {
		let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
			  }
			: null;
	}
}
