import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Crew, CrewInFlight, CrewPerFlight, CrewStatus, Flight } from 'src/app/core/graphql/generated/gen-types';
import { CrewService } from 'src/app/core/services/crew/crew.service';
import { DialogEventComponent } from '../dialog-event/dialog-event.component';

@Component({
	selector: 'app-dialog-flight-pilot-crew',
	templateUrl: './dialog-flight-pilot-crew.component.html',
	styleUrls: ['./dialog-flight-pilot-crew.component.scss'],
})
export class DialogFlightPilotCrewComponent implements OnInit {
	flight: Flight;
	availableCrew: any[] = null;
	availablePilots: any[] = null;
	selectedPilot: CrewPerFlight;
	selectedCrew: CrewInFlight[] = [];
	availablePilotsFiltered;
	dirty = false;

	filterValue = '';

	mostPopularPilot: CrewPerFlight;
	mostPopularCrew: CrewInFlight[] = [];

	constructor(
		public dialogRef: MatDialogRef<DialogEventComponent>,
		private crewService: CrewService,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.flight = data;
		this.selectedPilot = this.flight?.pilot?.crew;
		this.flight.groundCrews?.forEach((gc) => {
			this.selectedCrew.push(gc);
		});
	}

	ngOnInit(): void {
		if (this.flight?.id) {
			this.crewService.crewsPerFlight(this.flight?.id).valueChanges.subscribe((result) => {
				// pilot with filter
				this.availablePilots = this.sortCrew(result.data.crewsPerFlight.filter((crew) => crew.isPilot));

				this.mostPopularPilot = this.availablePilots.filter((pilot) => pilot.mostPopular)[0];
				this.availableCrew = this.sortCrew(result.data.crewsPerFlight.filter((crew) => !crew.isPilot));

				this.mostPopularCrew = this.availableCrew
					.filter((crew) => crew.mostPopular)
					.map((crew) => {
						return {
							crew: crew,
							status: CrewStatus.Con,
						};
					});
			});
		}
	}

	selectMostPopular(type?: string) {
		// copy the most popular pilot and crew to the selected ones
		if (type === 'pilot' || !type) this.selectedPilot = this.mostPopularPilot;
		if (type === 'crew' || !type)this.selectedCrew = this.mostPopularCrew;
		this.dirty = true;
	}

	sortCrew(crewsPerFlight) {
		// sort by name of pilot or crew
		return crewsPerFlight.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});
	}

	selectPilot(pilot: CrewPerFlight) {
		this.selectedPilot = pilot;
		this.dirty = true;
	}

	removePilot() {
		this.selectedPilot = null;
		this.dirty = true;
	}

	selectCrew(crew: CrewPerFlight) {
		this.selectedCrew.push({ crew: crew, status: CrewStatus.Con });
		this.dirty = true;
	}
	removeCrew(crew: CrewPerFlight) {
		this.selectedCrew = this.selectedCrew.filter((c) => c.crew.id !== crew.id);
		this.dirty = true;
	}

	displayCrew(crewInFlight: CrewInFlight[]) {
		return crewInFlight?.map((p) => p.crew?.name?.split(' ')[0]).join(', ');
	}

	crewSelected(crew) {
		return this.selectedCrew.find((c) => c.crew.id === crew.id);
	}

	save() {
		this.dialogRef.close({ id: this.flight.id, pilot: this.selectedPilot, crew: this.selectedCrew });
	}

	cancel() {
		this.dialogRef.close();
	}

	applyFilter(event: Event) {
		this.filterValue = (event.target as HTMLInputElement).value;
	}

	filter(crew, filterValue) {
		return crew.filter((c) => c.name.toLowerCase().includes(filterValue.toLowerCase()));
	}
}
