<mat-card class="mat-elevation-z8" data-tour="card-booking">
	<mat-card-content>
		<div style="display: flex; gap: 5px">
			<div style="display: flex; flex-direction: column; gap: 5px; width: 100%">
				<div style="display: flex; justify-content: space-between" (click)="edit()">
					<div style="display: flex; gap: 5px">
						<div style="display: flex; gap: 0px">
							<mat-icon [color]="booking?.iconColor">{{ booking?.icon }}</mat-icon>
						</div>
						<div style="display: flex; flex-direction: column">
							<span style="font-size: medium; font-weight: bold">{{ booking?.contactName }}</span>
							<span style="font-size: small">{{ booking?.contactPhone }} {{ booking?.city ? '-' : '' }} {{ booking?.city }}</span>
						</div>
					</div>
					<div style="display: flex">
						<span style="font-size: medium; font-weight: bold"
							>{{ booking?.passengerCounters.passengerCount }} ({{ booking?.passengerCounters.totalWeight }} kg)</span
						>
					</div>
				</div>
				<div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 5px; font-size: small">
					{{ booking?.comments }}
				</div>
			</div>

			<div style="display: flex; flex-direction: column; justify-content: space-between; align-items: end; width: 40px">
				<mat-icon *ngIf="draggable">zoom_out_map</mat-icon>
				<!-- 
                <button  mat-menu-item 
                [matMenuTriggerFor]="dropdownMenu"
                [matMenuTriggerData]="{booking: booking}">
                    <mat-icon>more_vertical</mat-icon>
                </button> -->
			</div>
		</div>
	</mat-card-content>
</mat-card>

<mat-menu #dropdownMenu xPosition="before">
	<ng-template matMenuContent let-booking="booking">
		<!-- <button mat-menu-item
            (click)="checkin()" 
            [disabled]="booking?.status !== 'REA'">
            <mat-icon [color]="flight.status !== 'REA' ? '' : flight.passengersCheckedIn ? 'accent' : 'warn'" >desktop_windows</mat-icon>
            <span>{{ 'FLIGHTS.checkin' | translate | capitalize}}</span>
        </button>
        <button mat-menu-item 
            (click)="edit()" 
            [disabled]="!operator">
            <mat-icon >edit</mat-icon>
            <span>{{ 'FLIGHTS.update' | translate | capitalize}}</span>
        </button> -->
	</ng-template>
</mat-menu>
