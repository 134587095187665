import { Directive, ElementRef, Input, OnInit } from '@angular/core';

interface TagTheme {
	backgroundColor: string;
	color: string;
}

@Directive({
	selector: '[appTagTheme]',
})
export class TagThemeDirective implements OnInit {
	@Input('appTagTheme') theme: TagTheme;

	constructor(private el: ElementRef) {}

	ngOnInit(): void {
		const natEl = this.el.nativeElement;
		const { color, backgroundColor } = this.theme;
		// Text Color
		natEl.style.setProperty('--mdc-chip-label-text-color', color);
		// Background Color
		natEl.style.backgroundColor = backgroundColor;
	}
}
