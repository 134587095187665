<div *ngIf="crew?.isPilot && crew">
	<!-- Pilot GOOD -->
	<div *ngIf="crew?.isCurrent > NOTIFY" style="display: flex; flex-direction: column">
		<div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 5px">
			<mat-icon style="color: #1faa00">gpp_good</mat-icon>
			<span [ngStyle]="highlight ? { 'background-color': 'yellow' } : {}">{{ crew?.name }}</span>
			<mat-icon
				*ngIf="showAvailability && availability"
				[ngStyle]="{ color: getAvailability(availability).color }"
				matTooltip="{{ 'EVENTS.' + availability | translate | capitalize }}"
			>
				{{ getAvailability(availability).icon }}
			</mat-icon>
		</div>
		<ng-container *ngIf="groundCrews; then groundCrewsTemplate"></ng-container>
	</div>

	<!-- Pilot BAD -->
	<div *ngIf="crew?.isCurrent <= 0" style="display: flex; flex-direction: column">
		<div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 5px">
			<mat-icon
				[matTooltipDisabled]="disableTooltip"
				matTooltip="{{ 'ACTORS.error_not_current' | translate | capitalize }}"
				class="mat-error-icon"
				>gpp_bad</mat-icon
			>
			<span [ngStyle]="highlight ? { 'background-color': 'yellow' } : {}">{{ crew?.name }}</span>
			<mat-icon
				*ngIf="showAvailability && availability"
				[ngStyle]="{ color: getAvailability(availability).color }"
				matTooltip="{{ 'EVENTS.' + availability | translate | capitalize }}"
			>
				{{ getAvailability(availability).icon }}
			</mat-icon>
		</div>
		<ng-container *ngIf="groundCrews; then groundCrewsTemplate"></ng-container>
	</div>

	<!-- Pilot UGLY -->
	<div *ngIf="crew?.isCurrent <= NOTIFY && crew?.isCurrent > 0" style="display: flex; flex-direction: column">
		<div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 5px">
			<mat-icon
				style="color: #ffca28"
				[matTooltipDisabled]="disableTooltip"
				matTooltip="{{ 'ACTORS.warning_not_current' | translate | capitalize }}"
			>
				gpp_maybe
			</mat-icon>
			<span [ngStyle]="highlight ? { 'background-color': 'yellow' } : {}">{{ crew?.name }}</span>
			<mat-icon
				*ngIf="showAvailability && availability"
				[ngStyle]="{ color: getAvailability(availability).color }"
				matTooltip="{{ 'EVENTS.' + availability | translate | capitalize }}"
			>
				{{ getAvailability(availability).icon }}
			</mat-icon>
		</div>
		<ng-container *ngIf="groundCrews; then groundCrewsTemplate"></ng-container>
	</div>
</div>
<div *ngIf="!crew" style="color: darkgray; font-style: italic">
	{{ 'COMMUNICATIONS.none' | translate | capitalize }}
</div>
<!-- CREW Availability unknown -->
<div *ngIf="!crew?.isPilot && crew">
	<div
		*ngIf="availability === crewAvailability.Unk || !availability"
		style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 5px"
	>
		<mat-icon>directions_run</mat-icon>
		<span [ngStyle]="highlight ? { 'background-color': 'yellow' } : {}">{{ crew?.name }}</span>
	</div>
	<!-- CREW Availability YES -->
	<div
		*ngIf="showAvailability && availability === crewAvailability.Yes"
		style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 5px"
	>
		<mat-icon style="color: #1faa00" matTooltip="{{ 'EVENTS.' + availability | translate | capitalize }}">directions_run</mat-icon>
		<span [ngStyle]="highlight ? { 'background-color': 'yellow' } : {}">{{ crew?.name }}</span>
	</div>
	<!-- CREW Availability NO -->
	<div
		*ngIf="showAvailability && availability === crewAvailability.No"
		style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 5px"
	>
		<mat-icon class="mat-error-icon" matTooltip="{{ 'EVENTS.' + availability | translate | capitalize }}">directions_run</mat-icon>
		<span [ngStyle]="highlight ? { 'background-color': 'yellow' } : {}">{{ crew?.name }}</span>
	</div>
	<!-- CREW Availability MAYBE -->
	<div
		*ngIf="showAvailability && availability === crewAvailability.Maybe"
		style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 5px"
	>
		<mat-icon style="color: #ffca28" matTooltip="{{ 'EVENTS.' + availability | translate | capitalize }}">directions_run</mat-icon>
		<span [ngStyle]="highlight ? { 'background-color': 'yellow' } : {}">{{ crew?.name }}</span>
	</div>
</div>

<ng-template #groundCrewsTemplate>
	<div style="font-size: small">{{ groundCrewNames }}</div>
</ng-template>
