<mat-card appearance="outlined">
	<mat-card-header>
		<mat-card-title>{{ 'BOOKINGS.comments' | translate | capitalize }}</mat-card-title>
	</mat-card-header>
	<mat-card-content class="mat-card-content">
		<form *ngIf="flight" [formGroup]="formFlightComments" autocomplete="off">
			<div style="display: flex; gap: 20px">
				<mat-form-field appearance="outline" style="width: 380px">
					<mat-label>{{ 'BOOKINGS.comments' | translate | capitalize }}</mat-label>
					<textarea cdkTextareaAutosize matInput cdkAutosizeMinRows="1" formControlName="comments"></textarea>
				</mat-form-field>
			</div>
			<app-tag-autocomplete
				[tagType]="'flight'"
				[alreadyAssignedTags]="flight?.tagsData"
				(selectedTagsEvent)="setTags($event)"
			></app-tag-autocomplete>
		</form>
	</mat-card-content>
	<mat-card-actions class="mat-card-actions">
		<button mat-flat-button (click)="save(formFlightComments.value)">
			{{ 'APPLICATION.save' | translate | capitalize }}
		</button>
		<button mat-raised-button (click)="cancel(formFlightComments.value)">{{ 'APPLICATION.cancel' | translate | capitalize }}</button>
	</mat-card-actions>
</mat-card>
