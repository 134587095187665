import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';

@Pipe({ name: 'formatDate' })
export class FormatDatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {
	  let lang = this.translate.currentLang;
		if (lang === 'du') lang = 'nl';
		if (lang) {
			dayjs.locale(lang);
		}
  }

  transform(value: any, format: any) {
    if (value === '2999-12-31') {
      return ''
    }
    if (value) {
      return dayjs(value).format(format);
    }
    return value;
  }
}
