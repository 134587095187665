import autoTable from 'jspdf-autotable';

export function addMadouBetaalOverzicht(doc, document, options: { margin_top: number; margin_left: number; column_width: number; margin_blocks: number }) {
    doc.addPage();
    autoTable(doc, {
        head: [[{ content: 'Betaaloverzicht', colSpan: 4 }]],
        body: [
            [document.labels.header.balloon, document.data.header.balloon, document.labels.header.date, document.data.header.date],
            [
                document.labels.header.takeoffLocation,
                `${document.data.header.takeoffLocation}, ${document.data.header.takeoffAddress}`,
                document.labels.header.flightComments,
                document.data.header.flightComments,
            ],
        ],
        startY: options.margin_top,
        theme: 'grid',
        margin: { left: options.margin_left },
        styles: {
            halign: 'left',
            valign: 'middle',
            fontSize: 9,
            cellPadding: 1,
        },
        tableWidth: 2 * options.column_width + options.margin_blocks,
        headStyles: {
            fillColor: '#d3d3d3',
            textColor: '#000000',
            fontSize: 12,
            halign: 'center',
        },
    });

    const Ybetaaloverzicht = (doc as any).lastAutoTable.finalY;
    // Booking overview with categories
    const categories = [];
    document.data.payments.forEach((p) => {
        let index = 1;
        p.categories.forEach((cat) => {
            const category = cat.category ? cat.category.description : 'Onbekend';
            if (index === 1) {
                categories.push([
                    { content: p.contactName + (p.city ? ' (' + p.city + ')' : ''), rowSpan: p.categories.length },
                    { content: p.comments ? p.comments : '', rowSpan: p.categories.length, styles: { fontSize: 8 } },
                    { content: p.contactPhone, rowSpan: p.categories.length },
                    {
                        content: p.passengerCount,
                        styles: { halign: 'center' },
                        rowSpan: p.categories.length,
                    },
                    { content: cat.count + 'x ' + category },
                ]);
            } else {
                categories.push([{ content: cat.count + 'x ' + category }]);
            }
            index += 1;
        });
    });
    autoTable(doc, {
        head: [['Reservatie', 'Commentaar', 'Telefoon', '#', 'Categorie']],
        body: categories,
        startY: Ybetaaloverzicht + 3,
        theme: 'grid',
        margin: { left: options.margin_left },
        styles: {
            halign: 'left',
            valign: 'middle',
            fontSize: 9,
            cellPadding: 1,
        },
        tableWidth: 2 * options.column_width + options.margin_blocks,
        headStyles: {
            fillColor: '#d3d3d3',
            textColor: '#000000',
            fontSize: 12,
            halign: 'left',
        },
    });

    doc.addImage(window.location.origin + '/assets/images/madou/betaalgegevens.jpg', 'jpg', options.margin_left, 120, 170, 170);
}