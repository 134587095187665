import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BookingStatus, FlightStatus } from 'src/app/core/graphql/generated/gen-types';
import { ResponsiveService } from 'src/app/core/services/admin/responsive.service';
import { BookingService } from 'src/app/core/services/booking/booking.service';
import { FlightService } from 'src/app/core/services/flight/flight.service';

@Component({
	selector: 'app-booking-log-card',
	templateUrl: './booking-log-card.component.html',
	styleUrls: ['./booking-log-card.component.scss'],
})
export class BookingLogCardComponent implements OnInit {
	@Input() bookingId: string;
	@Input() flightId: string;
	@Input() status: FlightStatus;

	logs = [];
	//responsive
	isMobile: boolean;

	constructor(
		private router: Router,
		private bookingService: BookingService,
		private flightService: FlightService,
		private responsiveService: ResponsiveService
	) {
		// responsiveService Subscriptions
		this.responsiveService.viewMobileChanged.subscribe((mobileView) => {
			this.isMobile = mobileView;
		});
	}

	ngOnInit(): void {
		if (this.bookingId) {
			this.bookingService.getBookingLogs(this.bookingId).valueChanges.subscribe((result) => {
				this.logs = result.data.booking.logs;
				const logsMerged = result.data.booking.logsMerged.map((log) => {
					// add a merge flag to each log
					return { ...log, merge: true };
				});
				this.logs = this.logs.concat(logsMerged);
				// sort this logs by date
				this.logs.sort((a, b) => {
					const dateA = new Date(a.date);
					const dateB = new Date(b.date);
					return dateA < dateB ? 1 : -1;
				});
			});
		}
		if (this.flightId) {
			this.flightService.getFlightLogs(this.flightId).valueChanges.subscribe((result) => {
				this.logs = result.data.flight.logs;
				if (this.status === FlightStatus.Fin) this.logs = this.completedFilter(this.logs);
			});
		}
	}

	// filter out all logs not related to a finished flight
	completedFilter(logs: any[]) {
		return logs.filter((log) => log.booking.status === BookingStatus.Comp);
	}

	navigateToFlight(id?) {
		let url = '/flight';
		if (id) {
			url = url + '/' + id;
		}
		this.router.navigate([url]);
	}

	navigateToBooking(id?) {
		let url = '/booking';
		if (id) {
			url = url + '/' + id;
		}
		this.router.navigate([url]);
	}

	deleteLog(logId, bookingId?) {
		if (this.bookingId) {
			// delete log from this logs
			this.logs = this.logs.filter((log) => log.id !== logId);
			this.bookingService.deleteLog(this.bookingId, logId).subscribe((result) => {});
		}
		if (this.flightId) {
			// delete log from this logs
			this.logs = this.logs.filter((log) => log.id !== logId);
			this.bookingService.deleteLog(bookingId, logId).subscribe((result) => {});
		}
	}
}
