import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserService } from '..';
import { UserRole } from '../../graphql/generated/gen-types';

export class MenuItem {
	title: string;
	icon: string;
	link: string;
	active: boolean;
	expectedRoles: string[];
	subMenu?: MenuItem[];
	expanded?: boolean = false;
}

@Injectable({
	providedIn: 'root',
})
export class MenuService {
	menuItems: MenuItem[];
	menuItemsTemplate: MenuItem[] = [
		{
			title: 'Dashboard',
			icon: 'dashboard',
			link: 'dashboard',
			active: false,
			expectedRoles: [UserRole.Opr, UserRole.Cre, UserRole.Own, UserRole.Rep, UserRole.Ass, UserRole.Met],
		},
		{
			title: 'APPLICATION.flights',
			icon: 'flight_takeoff',
			link: 'flight',
			active: false,
			expectedRoles: [UserRole.Opr, UserRole.Ass, UserRole.Met],
		},
		{
			title: 'APPLICATION.calendar',
			icon: 'today',
			link: 'calendar',
			active: false,
			expectedRoles: [UserRole.Opr, UserRole.Cre, UserRole.Ass, UserRole.Met],
		},
		{
			title: 'APPLICATION.bookings',
			icon: 'perm_contact_calendar',
			link: 'booking',
			active: false,
			expectedRoles: [UserRole.Opr, UserRole.Ass],
		},
		{
			title: 'APPLICATION.vouchers',
			icon: 'receipt',
			link: 'voucher',
			active: false,
			expectedRoles: [UserRole.Opr, UserRole.Ass],
		},
		{
			title: 'APPLICATION.apps',
			icon: 'apps',
			link: '',
			active: false,
			expectedRoles: [UserRole.Opr],
			expanded: false,
			subMenu: [
				{
					title: 'APPLICATION.balloons',
					icon: 'lightbulb_outline',
					link: 'balloon',
					active: false,
					expectedRoles: [UserRole.Opr],
				},
				{
					title: 'APPLICATION.cylinders',
					icon: 'local_gas_station',
					link: 'cylinder',
					active: false,
					expectedRoles: [UserRole.Opr],
				},
				{
					title: 'APPLICATION.crew',
					icon: 'group',
					link: 'crew',
					active: false,
					expectedRoles: [UserRole.Opr],
				},
				{
					title: 'APPLICATION.regions',
					icon: 'bubble_chartroup',
					link: 'region',
					active: false,
					expectedRoles: [UserRole.Opr],
				},
				{
					title: 'APPLICATION.locations',
					icon: 'location_on',
					link: 'location',
					active: false,
					expectedRoles: [UserRole.Opr],
				},
			],
		},
		{
			title: 'APPLICATION.settings',
			icon: 'settings',
			link: 'settings',
			active: false,
			expectedRoles: [UserRole.Opr],
		},
		{
			title: 'APPLICATION.reports',
			icon: 'insights',
			link: 'report',
			active: false,
			expectedRoles: [UserRole.Opr, UserRole.Rep],
		},
		{
			title: 'APPLICATION.subscription',
			icon: 'credit_card',
			link: 'subscription',
			active: false,
			expectedRoles: [UserRole.Opr],
		},
		{
			title: 'APPLICATION.admin',
			icon: 'admin_panel_settings',
			link: 'admin',
			active: false,
			expectedRoles: [UserRole.Own],
		},
		{
			title: 'APPLICATION.releasenotes',
			icon: 'open_in_browser',
			link: 'admin/releasenotes',
			active: false,
			expectedRoles: [UserRole.Own],
		},
	];

	private menuItemChangedSource = new Subject<MenuItem>();
	private menuItemsList = new BehaviorSubject([]);
	menuItemChanged = this.menuItemChangedSource.asObservable();
	menuItemsListChanged = this.menuItemsList.asObservable();

	constructor(private router: Router, private userService: UserService) {
		this.initializeMenuItems();

		router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			const activeLink = event.url.split('/');
			for (const menuItem of this.menuItems) {
				menuItem.active = false;

				if (menuItem.link === activeLink[1]) {
					menuItem.active = true;
					this.changeMenuItem(menuItem);
				}
			}
		});
	}

	private changeMenuItem(menuItem: MenuItem) {
		this.menuItemChangedSource.next(menuItem);
	}

	private initializeMenuItems() {
		this.userService.userProfileChanged.subscribe((userProfile) => {
			const userRole = userProfile.role;
			this.menuItems = this.menuItemsTemplate.filter((menuItem) => menuItem.expectedRoles.findIndex((role) => role === userRole) > -1);
			this.menuItemsList.next(this.menuItems);
		});
	}

	getMenuItems() {
		return this.menuItems;
	}
}
