<mat-chip-listbox>
	@for (tag of tags; track tag) {
	<mat-chip-option
		*ngIf="upcoming ? tag.upcoming : tag.history"
		[disabled]="disableAll"
		[ngStyle]="{
			'background-color': tag.backgroundColor || 'initial',
			color: tag.fontColor || 'initial',
			cursor: disableAll ? 'not-allowed' : 'pointer'
		}"
		(click)="!disableAll && selectTag(tag)"
	>
		<div *ngIf="upcoming === undefined">{{ tag.tag }}</div>
		<div *ngIf="upcoming !== undefined">{{ tag.tag }} ({{ upcoming ? tag.upcoming : tag.history }})</div>
	</mat-chip-option>
	}
</mat-chip-listbox>
