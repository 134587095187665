import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { MailEventLatest } from 'src/app/core/graphql/generated/gen-types';

@Component({
	selector: 'app-booking-maileventlatest',
	templateUrl: './booking-maileventlatest.component.html',
	styleUrls: ['./booking-maileventlatest.component.scss'],
})
export class BookingMaileventlatestComponent implements OnInit {
	@Input() mailEventLatest: MailEventLatest;

	constructor() {}

	ngOnInit(): void {}

	tooltipMailEventLatest(): String {
		let tooltip = null;
		if (this.mailEventLatest) {
			const tooltipDate = dayjs(this.mailEventLatest.date).format('DD MMM YY HH:mm');
			tooltip = `${this.mailEventLatest.event} ${tooltipDate}`;
		}
		return tooltip;
	}
}
