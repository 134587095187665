import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Booking } from 'src/app/core/graphql/generated/gen-types';
import { ResponsiveService } from 'src/app/core/services/admin/responsive.service';

@Component({
	selector: 'app-flight-bookings',
	templateUrl: './flight-bookings.component.html',
	styleUrls: ['./flight-bookings.component.scss'],
})
export class FlightBookingsComponent implements OnInit {
	@Input() bookings: Booking[];
	@Input() filter: string;

	isSmall: boolean;
	isXSmall: boolean;
	isMobile: boolean;

	constructor(private router: Router, private responsiveService: ResponsiveService) {
		// responsiveService Subscriptions
		this.responsiveService.SmallChanged.subscribe((isSmall) => {
			this.isSmall = isSmall;
		});
		this.responsiveService.XSmallChanged.subscribe((isXSmall) => {
			this.isXSmall = isXSmall;
		});
		this.responsiveService.viewMobileChanged.subscribe((mobileView) => {
			this.isMobile = mobileView;
		});
	}

	ngOnInit() {}

	findFilter(booking) {
		let found = false;
		if (this.filter) {
			booking.passengers.forEach((pax) => {
				found = found || pax.name.toLowerCase().includes(this.filter.toLowerCase());
			});
			found = found || booking.contactName.toLowerCase().includes(this.filter.toLowerCase());
		}
		return found;
	}

	navigateToBooking(id?) {
		let url = '/booking';
		if (id) {
			url = url + '/' + id;
		}
		this.router.navigate([url]);
	}
}
