<div style="margin-left: 10px">
	<mat-list>
		@for (menuItem of menuItems; track $index) {
		<div *ngIf="!menuItem.subMenu" [routerLink]="menuItem.link">
			<mat-list-item class="mat-list-item" matRipple (click)="toggle(menuItem)">
				<mat-icon matListItemIcon style="color: aliceblue">{{ menuItem.icon }}</mat-icon>
				<span matListItemTitle style="color: aliceblue">{{ menuItem.title | translate | capitalize }}</span>
			</mat-list-item>
		</div>
		<div *ngIf="menuItem.subMenu">
			<mat-list-item (click)="toggle(menuItem)" class="mat-list-item" matRipple>
				<mat-icon matListItemIcon style="color: aliceblue">expand_more</mat-icon>
				<span matListItemTitle style="color: aliceblue">{{ menuItem.title | translate | capitalize }}</span>
			</mat-list-item>
		</div>
		<div *ngIf="menuItem.expanded">
			@for (subMenu of menuItem.subMenu; track $index) {
			<div [routerLink]="subMenu.link" style="margin-left: 30px">
				<mat-list-item (click)="toggle(subMenu.link)" class="mat-list-item" matRipple>
					<mat-icon matListItemIcon style="color: aliceblue">{{ subMenu.icon }}</mat-icon>
					<span matListItemTitle style="color: aliceblue">{{ subMenu.title | translate | capitalize }}</span>
				</mat-list-item>
			</div>
			}
		</div>
		}
	</mat-list>
</div>
