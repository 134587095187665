import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
	SaveCrewDocument,
	DeleteCrewDocument,
	GetCrewDocument,
	GetCrewsDocument,
	CrewInput,
	CrewConversationsDocument,
	CrewsPerFlightDocument,
	PilotsPerFlightDocument,
	GetAllCrewForDialogDocument,
} from '../../graphql/generated/gen-types';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class CrewService {
	currentUser;

	constructor(private apollo: Apollo, private authenticationService: AuthenticationService) {
		this.authenticationService.currentUser.subscribe((user) => {
			this.currentUser = user;
		});
	}

	public getList(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetCrewsDocument,
		});
	}

	public get(crewId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetCrewDocument,
			variables: {
				crewId,
			},
		});
	}

	public getAllCrewForDialog(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetAllCrewForDialogDocument,
		});
	}

	public crewConversations(offset: number): QueryRef<any> {
		return this.apollo.watchQuery({
			query: CrewConversationsDocument,
			variables: {
				offset,
			},
		});
	}

	public save(crewInput: CrewInput, crewId: string): Observable<any> {
		// refetch crew list on save
		const refetchQueries: any[] = [
			{
				query: GetCrewsDocument,
			},
		];

		// refetch balloon document on update (not on new)
		if (crewId) {
			refetchQueries.push({
				query: GetCrewDocument,
				variables: {
					crewId,
				},
			});
		}

		return this.apollo
			.mutate<any>({
				mutation: SaveCrewDocument,
				variables: {
					crewInput,
					crewId,
				},
				refetchQueries,
			})
			.pipe(map((result: any) => result.data));
	}

	public delete(crewId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: DeleteCrewDocument,
				variables: {
					crewId,
				},
				refetchQueries: [
					{
						query: GetCrewsDocument,
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}
	public crewsPerFlight(flightId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: CrewsPerFlightDocument,
			variables: {
				flightId,
			},
			fetchPolicy: 'no-cache',
		});
	}
	public pilotsPerFlight(flightId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: PilotsPerFlightDocument,
			variables: {
				flightId,
			},
			fetchPolicy: 'no-cache',
		});
	}
}
