import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SmsLatest } from 'src/app/core/graphql/generated/gen-types';

@Component({
	selector: 'app-booking-smslatest',
	templateUrl: './booking-smslatest.component.html',
	styleUrls: ['./booking-smslatest.component.scss'],
})
export class BookingSmslatestComponent implements OnInit {
	@Input() smsUnread: number;
	@Input() smsLatest: SmsLatest;
	tooltip: string;

	constructor(private translateService: TranslateService) {}

	ngOnInit(): void {
		if (this.smsLatest && this.smsLatest.summary) {
			this.tooltip = this.smsLatest.summary;
		}
		if (this.smsLatest?.status === 'failed' && this.smsLatest?.error) {
			this.tooltip = this.translateService.instant(`COMMUNICATIONS.${this.smsLatest.error}`);
		}
	}
}
