import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BookingService } from 'src/app/core/services/booking/booking.service';
import { SnackBar } from 'src/app/core/utility/snackBar';

@Component({
	selector: 'app-booking-card-group',
	templateUrl: './booking-card-group.component.html',
	styleUrls: ['./booking-card-group.component.scss'],
})
export class BookingCardGroupComponent implements OnInit {
	@Input() group: any;
	@Input() draggable: boolean;

	bookingSub: Subscription;

	constructor(private bookingService: BookingService, private snackBar: SnackBar) {}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		if (this.bookingSub) this.bookingSub.unsubscribe();
	}

	drop(event: CdkDragDrop<string[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else if (event.container.id.toString() === '-1' && !(event.previousContainer.id.toString() === '0')) {
			// dropped in waiting list from flight
			transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
			const previousFlight = event.previousContainer.id;
			const bookingDragged = event.item.data;
			this.bookingSub = this.bookingService.removeBookingFromFlight(bookingDragged.id, previousFlight).subscribe(
				() => {},
				(err) => this.snackBar.openSnackBarError(err.message)
			);
		}
	}
}
