<div style="display: flex; flex-direction: column; gap: 20px">
	@for (mail of mails; track mail) {
	<div style="display: flex; flex-wrap: wrap; justify-content: space-between; gap: 10px">
		<!-- fxL: [gap]="isMobile ? '10px' : '25px'"  -->
		<div style="display: flex; gap: 20px">
			<div *ngIf="mail?.resultAt" style="display: flex; flex-direction: column; gap: 3px; min-width: 140px">
				<div style="display: flex">{{ 'MAIL.sent' | translate | capitalize }}<mat-icon *ngIf="mail.merge">merge</mat-icon></div>
				<span style="font-size: x-small">{{ mail?.resultAt | formatDate: 'DD MMM YY HH:mm' }}</span>
			</div>
			<span *ngIf="!mail?.resultAt" style="min-width: 140px">{{ 'APPLICATION.waiting' | translate | capitalize }}</span>
			<div style="display: flex; flex-direction: column; gap: 20px">
				<button
					mat-stroked-button
					style="min-width: 120px; word-wrap: break-word; display: flex; align-items: center"
					(click)="showMail(mail)"
					[matBadge]="mail.events.length"
					matBadgePosition="after"
					[matBadgeColor]="badgeColor(mail.events)"
					[matBadgeHidden]="mail.events.length === 0"
				>
					<mat-icon *ngIf="mail?.resultAt" class="mat-accent-icon">mail</mat-icon>
					<mat-icon *ngIf="!mail?.resultAt">mail</mat-icon>
					{{ mail?.subject }}
				</button>
				@for (event of sortMailEvents(mail.events); track $index; let i = $index) {
				<div style="display: flex; gap: 10px">
					<div style="display: flex; flex-direction: column; gap: 3px">
						<!-- processing -->
						<mat-icon *ngIf="event.event === 'processed'" style="color: #ffca28"> circle </mat-icon>
						<mat-icon *ngIf="event.event === 'deferred'" style="color: #ffca28"> circle </mat-icon>
						<!-- problem -->
						<mat-icon *ngIf="event.event === 'dropped'" class="mat-error-icon"> circle </mat-icon>
						<mat-icon *ngIf="event.event === 'bounce'" class="mat-error-icon"> circle </mat-icon>
						<mat-icon *ngIf="event.event === 'blocked'" class="mat-error-icon"> circle </mat-icon>
						<!-- Delivered -->
						<mat-icon *ngIf="event.event === 'delivered'" style="color: #1faa00"> circle </mat-icon>
						<!-- engagement -->
						<mat-icon *ngIf="event.event === 'open'" style="color: #1faa00"> radio_button_unchecked </mat-icon>
						<mat-icon *ngIf="event.event === 'click'" style="color: #1faa00"> radio_button_unchecked </mat-icon>
						<mat-icon *ngIf="i < mail.events.length - 1" style="color: grey"> south </mat-icon>
					</div>
					<div style="display: flex; flex-direction: column; gap: 3px">
						{{ event.event }} {{ event.url }}
						<span style="font-size: x-small">{{ event.timestamp | formatDate: 'DD MMM YY HH:mm' }}</span>
					</div>
				</div>
				}
			</div>
		</div>
	</div>
	}
</div>
