<mat-card style="width: 320px">
	<mat-card-header>
		<mat-card-title>
			{{ (tagId ? 'TAGS.edit' : 'TAGS.new_tag') | translate | capitalize }}
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<form [formGroup]="newTagForm" style="display: flex; flex-direction: column; gap: 10px; margin-top: 20px">
			<mat-form-field appearance="outline" style="width: 100%">
				<mat-label>{{ 'TAGS.tag_name' | translate | capitalize }}</mat-label>
				<input matInput type="text" formControlName="tag" />
			</mat-form-field>
			<mat-form-field appearance="outline" style="width: 100%">
				<mat-label>{{ 'TAGS.tag_type' | translate | capitalize }}</mat-label>
				<mat-select formControlName="type">
					@for (type of tagTypes; track $index) {
					<mat-option [value]="type">{{ 'COMMUNICATIONS.' + type | translate }}</mat-option>
					}
				</mat-select>
			</mat-form-field>
			<mat-checkbox formControlName="dashboard" [checked]="newTagForm?.value?.dashboard">{{
				'TAGS.show_on_dashboard' | translate | capitalize
			}}</mat-checkbox>
			&nbsp;
			<div>{{ 'TAGS.select_tag_color' | translate | capitalize }} ({{ 'SETTINGS.optional' | translate }})</div>
			<button
				mat-raised-button
				[ngStyle]="{
					'background-color': newTagForm.controls['backgroundColor'].value ? newTagForm.controls['backgroundColor'].value : '#FFCA28',
					color: newTagForm.controls['fontColor'].value ? newTagForm.controls['fontColor'].value : 'black'
				}"
				(click)="r.toggle()"
				[satPopoverAnchor]="r"
				matTooltip="{{ 'REGIONS.change_color' | translate | capitalize }}"
			>
				<mat-icon>bookmark</mat-icon>
			</button>
			<sat-popover #r hasBackdrop xAlign="after" yAlign="center">
				<chrome-picker [control]="colorPickerControl"></chrome-picker>
			</sat-popover>
			<div class="button">
				<div>{{ 'SETTINGS.example' | translate | capitalize }}:</div>
				<mat-chip-set>
					<mat-chip
						[ngStyle]="{
							'background-color': newTagForm.controls['backgroundColor'].value ? newTagForm.controls['backgroundColor'].value : '#FFCA28',
							color: newTagForm.controls['fontColor'].value ? newTagForm.controls['fontColor'].value : 'black'
						}"
					>
						<span [ngStyle]="{ color: newTagForm.controls['fontColor'].value ? newTagForm.controls['fontColor'].value : 'black' }">
							{{ newTagForm.controls['tag'].value ? newTagForm.controls['tag'].value : ('TAGS.tag_name' | translate | capitalize) }}</span
						>
					</mat-chip>
				</mat-chip-set>
			</div>
		</form>
	</mat-card-content>
	<mat-card-actions class="mat-card-actions">
		<button
			mat-raised-button
			mat-dialog-close
			style="width: 100%"
			(click)="saveTag()"
			[disabled]="!newTagForm.valid || newTagForm.pristine || isLoading"
		>
			<div style="display: flex; justify-content: center; align-items: center; gap: 10px">
				<div>{{ 'TAGS.save' | translate | capitalize }}</div>
				<mat-spinner diameter="28" *ngIf="isLoading"></mat-spinner>
			</div>
		</button>
		<button mat-raised-button mat-dialog-close style="width: 100%">{{ 'APPLICATION.close' | translate | capitalize }}</button>
	</mat-card-actions>
</mat-card>
