import autoTable from 'jspdf-autotable';
import { PaymentType } from '../graphql/generated/gen-types';

export function addBallonschateaudoexBetaalOverzicht(doc, document, currency = 'EUR', options: { column_width: number; margin_blocks: number; margin_top: number; margin_left: number }) {
    const tableWidth = 2 * options.column_width + options.margin_blocks;
    const cellWidth = tableWidth / 6;
    doc.addPage();
    let Ypos = 15;

    // Header
    autoTable(doc, {
        head: [[{ content: 'PAYMENTS', colSpan: 4 }]],
        body: [
            [document.data.header.balloon, document.data.header.date, document.data.header.pic, document.data.header.takeoffLocation],
            [
                {
                    content: document.data.header.flightComments,
                    colSpan: 4,
                    styles: { fontSize: 9 },
                },
            ],
        ],
        startY: options.margin_top,
        theme: 'grid',
        margin: { left: options.margin_left },
        styles: {
            halign: 'left',
            valign: 'middle',
            fontSize: 14,
            cellPadding: 1,
        },
        tableWidth: 2 * options.column_width + options.margin_blocks,
        headStyles: {
            fillColor: '#d3d3d3',
            textColor: '#000000',
            fontSize: 14,
            halign: 'center',
        },
    });

    // Booking overview
    const categories = [];
    document.data.payments.forEach((p) => {
        let invoiceAndVat = `${p.invoiceName ? p.invoiceName : ''}\n${p.VATnumber ? p.VATnumber : ''}`;
        categories.push([
            {
                content: `${p.contactName}\n${p.contactPhone}`,
                styles: { fontStyle: 'bold', fontSize: 10 },
                colSpan: 2,
            },
            {
                content: p.passengerCount,
                styles: { halign: 'center', fontSize: 10 },
            },
            {
                content: p.prepaymentAmount ? currency + ' ' + p.prepaymentAmount : '',
                styles: {
                    halign: 'center',
                    fontSize: 10,
                },
            },
            {
                content: currency + ' ' + p.openAmount,
                styles: {
                    fontSize: 10,
                    fontStyle: 'bold',
                    textColor: p.openAmount > 0 ? '#B50023' : '#555555',
                    halign: 'center',
                },
            },
            {
                content: invoiceAndVat,
                styles: { fontStyle: 'bold', fontSize: 10 },
            },
            {
                content: currency + ' ' + p.totalAmount,
                styles: {
                    fontSize: 10,
                    fontStyle: 'bold',
                    textColor: '#555555',
                    halign: 'center',
                },
            },
        ]);
        categories.push([{ content: p.comments ? p.comments : '', colSpan: 7 }]);
        p.passengers.forEach((pax, index) => {
            let paxName = pax.age ? pax.name + ' ' + `(${pax.age}y)` : pax.name;
            categories.push([
                { content: index + 1 + '. ' + paxName, colSpan: 3 },
                { content: pax.paymentType },
                { content: currency + ' ' + (pax.paymentAmount ? pax.paymentAmount : 0), styles: { halign: 'center' } },
                {
                    content:
                        (pax.category ? 'Cat: ' + pax.category.name + ', ' : '') +
                        (pax.paymentTypeEnum === PaymentType.Coup && pax.voucher ? 'Voucher: ' + pax.voucher + ', ' : '') +
                        (pax.paymentTypeEnum === PaymentType.Vouc && pax.voucherNumber ? 'Voucher: ' + pax.voucherNumber + ', ' : '') +
                        (pax.paymentReference ? 'Ref: ' + pax.paymentReference : ''),
                    colSpan: 2,
                },
            ]);
        });
    });
    const Ybetaaloverzicht = (doc as any).lastAutoTable.finalY;

    autoTable(doc, {
        head: [['BOOKING', '', '#', 'PREPAID', 'OPEN', 'INVOICE NAME & VAT', 'TOTAL']],
        body: categories,
        startY: Ybetaaloverzicht + 3,
        theme: 'grid',
        margin: { left: options.margin_left },
        styles: {
            halign: 'left',
            valign: 'middle',
            fontSize: 8,
            // cellPadding: 4,
            cellPadding: {
                top: 1,
                bottom: 1,
                left: 2,
                right: 2,
            },
        },
        tableWidth: 2 * options.column_width + options.margin_blocks,
        headStyles: {
            fillColor: '#d3d3d3',
            textColor: '#000000',
            fontSize: 12,
            halign: 'left',
        },
    });
}