export type DisplayedColumn = {
	name: string;
	minSize: number;
};

// minSize is the minimum screensize to show the column
// minSize = 10 is to always hide the column

export const flightColumnsUpcoming: DisplayedColumn[] = [
	{ name: 'status', minSize: 1 },
	{ name: 'date', minSize: 1 },
	{ name: 'hour', minSize: 2 },
	{ name: 'name', minSize: 1 },
	{ name: 'nameBadge', minSize: 3 },
	{ name: 'location', minSize: 1 },
	{ name: 'event', minSize: 4 },
	{ name: 'pilot', minSize: 4 },
	{ name: 'comments', minSize: 3 },
	{ name: 'bookings', minSize: 3 },
	{ name: 'passengersWeight', minSize: 5 },
	{ name: 'passengers', minSize: 1 },
	{ name: 'action', minSize: 2 },
];

export const flightColumnsBooking: DisplayedColumn[] = [
	{ name: 'status', minSize: 1 },
	{ name: 'date', minSize: 1 },
	{ name: 'hour', minSize: 2 },
	{ name: 'name', minSize: 1 },
	{ name: 'nameBadge', minSize: 3 },
	{ name: 'location', minSize: 1 },
	{ name: 'pilot', minSize: 4 },
	{ name: 'comments', minSize: 3 },
	// { name: 'bookings', minSize: 3 },
	// { name: 'passengersWeight', minSize: 4 },
	{ name: 'passengers', minSize: 1 },
	{ name: 'action', minSize: 1 },
];

export const flightColumnsUpcomingSummary: DisplayedColumn[] = [
	{ name: 'status', minSize: 1 },
	{ name: 'date', minSize: 1 },
	{ name: 'hour', minSize: 2 },
	{ name: 'name', minSize: 1 },
	{ name: 'location', minSize: 1 },
];

export const flightColumnsHistory: DisplayedColumn[] = [
	{ name: 'status', minSize: 1 },
	{ name: 'date', minSize: 1 },
	{ name: 'hour', minSize: 2 },
	{ name: 'name', minSize: 1 },
	{ name: 'nameBadge', minSize: 3 },
	{ name: 'location', minSize: 3 },
	{ name: 'event', minSize: 4 },
	{ name: 'pilot', minSize: 4 },
	{ name: 'comments', minSize: 3 },
	{ name: 'bookings', minSize: 3 },
	{ name: 'passengersWeight', minSize: 5 },
	{ name: 'passengers', minSize: 3 },
	{ name: 'action', minSize: 2 },
];
