import { Component, Host, Inject, Input, OnInit, Optional } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SatPopoverComponent } from '@ncstate/sat-popover';
import { Booking, Tag } from 'src/app/core/graphql/generated/gen-types';

@Component({
	selector: 'app-booking-comments',
	templateUrl: './booking-comments.component.html',
	styleUrls: ['./booking-comments.component.scss'],
})
export class BookingCommentsComponent implements OnInit {
	@Input() booking: Booking;

	selectedTags: Tag[];

	formBookingComments: UntypedFormGroup;

	constructor(private formBuilder: UntypedFormBuilder, public popover: SatPopoverComponent) {}

	ngOnInit() {
		this.formBookingComments = this.formBuilder.group({
			id: this.booking.id,
			comments: this.booking.comments,
			save: false,
		});
	}

	cancel(form) {
		form.save = false;
		this.popover.close({ save: form.save, comments: form.comments, tags: this.selectedTags });
	}

	save(form) {
		form.save = true;
		this.popover.close({ save: form.save, comments: form.comments, tags: this.selectedTags });
	}

	setTags(tags: Tag[]): void {
		if (!tags) return;
		this.selectedTags = tags;
	}
}
