import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class TranslateCustomService {
	en = require('../../../../assets/i18n/en.json');
	nl = require('../../../../assets/i18n/nl.json');
	fr = require('../../../../assets/i18n/fr.json');
	de = require('../../../../assets/i18n/de.json');
	cs = require('../../../../assets/i18n/cs.json');
	it = require('../../../../assets/i18n/it.json');
	pt = require('../../../../assets/i18n/pt.json');
	es = require('../../../../assets/i18n/es.json');

	constructor(private translateService: TranslateService) {}

	translate(text: string, lang?: string) {
		let translations = this.en;
		if (!lang) lang = this.translateService.currentLang;
		switch (lang) {
			case 'nl':
				translations = this.nl;
				break;
			case 'fr':
				translations = this.fr;
				break;
			case 'de':
				translations = this.de;
				break;
			case 'cs':
				translations = this.cs;
				break;
			case 'it':
				translations = this.it;
				break;
			case 'pt':
				translations = this.pt;
				break;
			case 'es':
				translations = this.es;
				break;
		}
		const domain = text.substring(0, text.indexOf('.'));
		const placeholder = text.substring(domain.length + 1);

		if (domain && placeholder) {
			const translation = translations[domain][placeholder];
			return translation;
		} else {
			return text;
		}
	}

	capitalizeAndTranslate(text: string, lang?: string) {
		let output = this.translate(text, lang);
		output = output.charAt(0).toUpperCase() + output.slice(1);
		return output;
	}
}
