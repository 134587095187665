import { Component, Input, OnInit } from '@angular/core';
import { Region } from 'src/app/core/graphql/generated/gen-types';

@Component({
	selector: 'app-region-badge',
	templateUrl: './region-badge.component.html',
	styleUrls: ['./region-badge.component.scss'],
})
export class RegionBadgeComponent implements OnInit {
	@Input() region: Region;
	constructor() {}

	ngOnInit(): void {}
}
