<div style="display: flex; gap: 20px">
	<mat-form-field appearance="outline" style="min-width: 380px" (click)="trigger.openPanel()">
		<mat-label>{{ 'TAGS.tag_name' | translate | capitalize }}</mat-label>
		<mat-chip-grid #chipList>
			@for (tag of selectedTags; track tag) {
			<mat-chip-row
				(removed)="removeTagFromSelected(tag)"
				[appTagTheme]="{
					backgroundColor: tag.backgroundColor,
					color: tag.fontColor,
				  }"
			>
				{{ tag.tag }}
				<button matChipRemove>
					<mat-icon [ngStyle]="{ color: tag.fontColor }">cancel</mat-icon>
				</button>
			</mat-chip-row>
			}
		</mat-chip-grid>
		<div style="display: flex; align-items: center">
			<input
				tabindex="-1"
				matInput
				style="width: 100%"
				placeholder="Select tag"
				autocomplete="off"
				#tagInput
				[formControl]="tagControl"
				[matAutocomplete]="auto"
				[matChipInputFor]="chipList"
				[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
				(matChipInputTokenEnd)="addFromInput($event)"
				#trigger="matAutocompleteTrigger"
			/>
			<mat-icon
				style="cursor: pointer"
				matTooltip="{{ 'TAGS.new_tag' | translate | capitalize }}"
				(click)="openCreateTagDialog(); $event.stopPropagation()"
				>add_box</mat-icon
			>
		</div>
		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectFromAutoComplete($event)">
			@for (tag of filteredTags | async; track tag) {
			<mat-option [value]="tag.tag">
				<mat-icon [ngStyle]="tag.backgroundColor ? { color: tag.backgroundColor } : {}">bookmark</mat-icon>{{ tag.tag }}
			</mat-option>
			}
		</mat-autocomplete>
	</mat-form-field>
</div>
