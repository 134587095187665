<app-toolbar (toggleMenu)="toggleMenu()"></app-toolbar>
<mat-sidenav-container>
	<mat-sidenav style="display: flex; flex-direction: column" [mode]="mode" [opened]="sideNavOpened">
		<app-side-menu></app-side-menu>
	</mat-sidenav>
	<mat-sidenav-content>
		<div *ngIf="!xsmall && subscription?.sms?.pctUsed >= 95 && !subscription?.sms?.ignoreWarning" class="message">
			<div style="display: flex; justify-content: space-around; align-items: center; margin-top: 5px">
				<mat-icon class="mat-error-icon">warning</mat-icon>
				<div>
					{{ 'SUBSCRIPTION.sms_warning' | translate | capitalize }}
					<a routerLink="/subscription">{{ 'SUBSCRIPTION.order_sms' | translate | capitalize }}</a>
				</div>
				<mat-icon class="mat-error-icon">warning</mat-icon>
			</div>
		</div>
		<div *ngIf="!xsmall && releaseNotesCount" class="message">
			<div style="display: flex; justify-content: space-around; align-items: center">
				<div style="display: flex; justify-content: center; align-items: center; gap: 10px">
					<p>{{ ('VALIDATIONS.new_features_available' | translate | capitalize).replace('{0}', releaseNotesCount) }}</p>
					<button mat-icon-button (click)="showReleaseNotes()"><mat-icon>open_in_browser</mat-icon></button>
				</div>
			</div>
		</div>
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
