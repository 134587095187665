import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'intphone' })
export class IntPhonePipe implements PipeTransform {
  transform(value: any) {
    if (value?.includes('+32')) {
      // show the number in the format +32 473 72 00 04 in case it is a belgian number +32473720004
      return value.replace('+32', '+32 ').replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4');
    }
    if (value?.includes('+33')) {
      // show the number in the format +33 X XX XX XX XX in case it is a french number +33647372000
      return value.replace('+33', '+33 ').replace(/(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
    }
    if (value?.includes('+31')) {
      // show the number in the format +31 X XXXXXXXX in case it is a Dutch number +31647372000
      return value.replace('+31', '+31 ').replace(/(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
    }
    if (value?.includes('+44')) {
      // show the number in the format +44 7XXX XXXXXX in case it is a UK number +44647372000
      return value.replace('+44', '+44 ').replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2 $3');
    }
    if (value?.includes('+49')) {
      // show the number in the format +49 1XX XXXXXXX in case it is a German number +49647372000
      return value.replace('+49', '+49 ').replace(/(\d{3})(\d{7})/, '$1 $2');
    }
    if (value?.includes('+41')) {
      // show the number in the format +49 1XX XXXXXXX in case it is a German number +49647372000
      return value.replace('+41', '+41 ').replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
    }
    return value;
  }
}
