import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Tag, TagType } from 'src/app/core/graphql/generated/gen-types';
import { TagService } from 'src/app/core/services/tag/tag.service';
import { SnackBar } from 'src/app/core/utility/snackBar';

@Component({
	selector: 'app-tag-select',
	templateUrl: './tag-select.component.html',
	styleUrls: ['./tag-select.component.scss'],
})
export class TagSelectComponent implements OnInit {
	@Input() disableAll = false;
	@Input() tagType: TagType;
	@Input() upcoming: any;
	@Output() selectedTags = new EventEmitter<Tag[]>();
	tags: Tag[];
	selectedFilterTags: Tag[] = [];

	constructor(private tagService: TagService, private snackBar: SnackBar) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.upcoming) {
			this.getData();
		}
	}

	ngOnInit(): void {
		this.getData();
	}

	getData(): void {
		if (this.tagType) {
			this.tagService.getTagsOfType(this.tagType, true, this.upcoming).valueChanges.subscribe(
				(result) => {
					const tags: Tag[] = this.tagService.sortByTagName(result.data.getTagsOfType);
					this.tags = tags;
				},
				(error) => {
					this.snackBar.openSnackBarError(error.message);
				}
			);
		} else {
			this.tagService.getAllTags(true).valueChanges.subscribe(
				(result) => {
					const tags: Tag[] = this.tagService.sortByTagName(result.data.getAllTags);
					this.tags = tags;
				},
				(error) => {
					this.snackBar.openSnackBarError(error.message);
				}
			);
		}
	}

	selectTag(selectedTag: Tag) {
		if (this.selectedFilterTags.length === 0) {
			this.selectedFilterTags.push(selectedTag);
			this.selectedFilterTags = [...this.selectedFilterTags];
			this.selectedTags.emit(this.selectedFilterTags);
			return;
		}
		if (this.selectedFilterTags.length > 0) {
			if (this.selectedFilterTags.find((tag) => tag.id === selectedTag.id)) {
				this.selectedFilterTags = this.selectedFilterTags.filter((tag) => tag.id !== selectedTag.id);
			} else {
				this.selectedFilterTags.push(selectedTag);
			}
		}
		this.selectedFilterTags = [...this.selectedFilterTags];
		this.selectedTags.emit(this.selectedFilterTags);
	}

	isSelected(selectedTag: Tag) {
		if (this.selectedFilterTags.length === 0) return false;
		if (this.selectedFilterTags.length > 0) {
			if (this.selectedFilterTags.find((tag) => tag.id === selectedTag.id)) return true;
		}
		return false;
	}
}