import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	GetEventsDocument,
	DeleteEventDocument,
	SaveEventDocument,
	EventInput,
	CrewAvailabilityInput,
	SaveCrewAvailabilityDocument,
	CrewCalendarDocument,
	CrewAvailability,
	SetMonthAvailabilityDocument,
	DeleteMonthAvailabilityEventsDocument,
	FetchHolidaysDocument,
} from '../../graphql/generated/gen-types';
@Injectable({
	providedIn: 'root',
})
export class EventService {
	currentUser;

	constructor(private apollo: Apollo, private authenticationService: AuthenticationService) {
		this.authenticationService.currentUser.subscribe((user) => {
			this.currentUser = user;
		});
	}

	public getList(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetEventsDocument,
		});
	}

	public delete(eventId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: DeleteEventDocument,
				variables: {
					eventId,
				},
				refetchQueries: [
					{
						query: GetEventsDocument,
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public save(eventInput: EventInput, eventId?: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SaveEventDocument,
				variables: {
					eventInput,
					eventId,
				},
				refetchQueries: [
					{
						query: GetEventsDocument,
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public saveEventWithNoRefetch(eventInput: EventInput, eventId?: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SaveEventDocument,
				variables: {
					eventInput,
					eventId,
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public saveCrewAvailability(
		crewAvailabilityInput: CrewAvailabilityInput,
		year: number,
		month: number,
		eventId: string,
		crewId?: string
	): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SaveCrewAvailabilityDocument,
				variables: {
					crewAvailabilityInput,
					eventId,
				},
				refetchQueries: [
					{
						query: CrewCalendarDocument,
						variables: {
							year: year,
							month: month,
							userId: crewId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public setMonthAvailability(availability: CrewAvailability, year: number, month: number, crewId): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SetMonthAvailabilityDocument,
				variables: {
					availability,
					year,
					month,
					crewId,
				},
				refetchQueries: [
					{
						query: CrewCalendarDocument,
						variables: {
							year: year,
							month: month,
							userId: crewId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public deleteMonthAvailabilityEvents(year: number, month: number, crewId): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: DeleteMonthAvailabilityEventsDocument,
				variables: {
					year,
					month,
					crewId,
				},
				refetchQueries: [
					{
						query: CrewCalendarDocument,
						variables: {
							year: year,
							month: month,
							userId: crewId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}
	public fetchHolidays(year: number, countryCode: string, language: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: FetchHolidaysDocument,
				variables: {
					year,
					countryCode,
					language,
				},
			})
			.pipe(map((result: any) => result.data));
	}
}
