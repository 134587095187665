export type DisplayedColumn = {
	name: string;
	minSize: number;
};

// minSize is the minimum screensize to show the column
// minSize = 10 is to always hide the column

export const bookingColumnsOpen: DisplayedColumn[] = [
	{ name: 'status', minSize: 1 },
	{ name: 'bookingDate', minSize: 3 },
	{ name: 'contactName', minSize: 1 },
	{ name: 'phone', minSize: 1 },
	{ name: 'comments', minSize: 4 },
	{ name: 'region', minSize: 4 },
	{ name: 'city', minSize: 5 },
	{ name: 'passengers', minSize: 2 },
	{ name: 'preferredDate', minSize: 4 },
	{ name: 'flightDate', minSize: 3 },
	{ name: 'messages', minSize: 3 },
	{ name: 'up', minSize: 10 },
];

export const bookingColumnsPayment: DisplayedColumn[] = [
	{ name: 'status', minSize: 1 },
	{ name: 'bookingDate', minSize: 3 },
	{ name: 'contactName', minSize: 1 },
	{ name: 'phone', minSize: 1 },
	{ name: 'comments', minSize: 4 },
	{ name: 'city', minSize: 4 },
	{ name: 'passengers', minSize: 2 },
	{ name: 'totalAmount', minSize: 3 },
	{ name: 'paid_amount', minSize: 3 },
	{ name: 'openAmount', minSize: 2 },
];

export const bookingColumnsHistory: DisplayedColumn[] = [
	{ name: 'status', minSize: 1 },
	{ name: 'bookingDate', minSize: 3 },
	{ name: 'contactName', minSize: 1 },
	{ name: 'comments', minSize: 4 },
	{ name: 'phone', minSize: 1 },
	{ name: 'region', minSize: 4 },
	{ name: 'city', minSize: 4 },
	{ name: 'passengers', minSize: 3 },
	{ name: 'flightDate', minSize: 3 },
	{ name: 'flightPeriod', minSize: 4 },
	{ name: 'flight_balloon', minSize: 4 },
	{ name: 'flight_location', minSize: 4 },
	{ name: 'flight_pilot', minSize: 4 },
	{ name: 'up', minSize: 10 },
];

export const bookingColumnsDashboard: DisplayedColumn[] = [
	{ name: 'status', minSize: 1 },
	{ name: 'bookingDate', minSize: 3 },
	{ name: 'contactCard', minSize: 1 },
	{ name: 'comments', minSize: 2 },
	{ name: 'region', minSize: 4 },
	{ name: 'passengersCard', minSize: 3 },
	{ name: 'preferredDate', minSize: 3 },
	{ name: 'flightDate', minSize: 3 },
	{ name: 'flight_confirmation', minSize: 1 },
	{ name: 'messages', minSize: 3 },
];

export const bookingColumnsFlight: DisplayedColumn[] = [
	{ name: 'status', minSize: 1 },
	{ name: 'contactCard', minSize: 1 },
	{ name: 'comments', minSize: 3 },
	{ name: 'passengersCard', minSize: 3 },
	{ name: 'mismatchReasons', minSize: 3 },
	{ name: 'action', minSize: 1 },
];

export const bookingColumnsFlightEnd: DisplayedColumn[] = [
	{ name: 'status', minSize: 1 },
	{ name: 'contactCard', minSize: 1 },
	{ name: 'comments', minSize: 3 },
	{ name: 'passengersCard', minSize: 3 },
];

export const bookingColumnsFlightAll: DisplayedColumn[] = [
	{ name: 'status', minSize: 1 },
	{ name: 'contactCard', minSize: 1 },
	{ name: 'comments', minSize: 3 },
	{ name: 'passengersCard', minSize: 3 },
	{ name: 'mismatchReasons', minSize: 3 },
	{ name: 'action', minSize: 1 },
];

export const bookingColumnsFlightPayment: DisplayedColumn[] = [
	{ name: 'status', minSize: 1 },
	{ name: 'contactCard', minSize: 1 },
	{ name: 'comments', minSize: 3 },
	{ name: 'passengersCard', minSize: 3 },
	{ name: 'openAmount', minSize: 2 },
	{ name: 'action', minSize: 1 },
];
