<div style="display: flex; flex-direction: column; justify-content: space-evenly; gap: 2px">
	<mat-card appearance="outlined" style="height: 160px">
		<mat-card-content>
			<div style="display: flex; gap: 5px">
				<div style="display: flex; flex-direction: column; gap: 5px; justify-content: space-evenly; width: 100%; height: 140px">
					<!-- flight header (date, balloon) -->
					<div
						style="display: flex; justify-content: space-between; align-items: center; font-size: medium; font-weight: bold"
						(click)="edit()"
					>
						<div style="display: flex; gap: 5px; align-items: center">
							<mat-icon [color]="flight?.icon?.color">{{ flight?.icon?.sign }}</mat-icon>
							<span>{{ flight?.date | formatDate: 'dd DD MMM' }}</span>
						</div>
						<div style="display: flex; align-items: center" (click)="$event.stopPropagation()">
							<button *ngIf="flight?.visibility === 'PUB'" mat-icon-button (click)="toggleVisibility()">
								<mat-icon>visibility</mat-icon>
							</button>
							<button *ngIf="flight?.visibility === 'PRI'" mat-icon-button (click)="toggleVisibility()">
								<mat-icon class="mat-error-icon">visibility_off</mat-icon>
							</button>
						</div>
						<div style="display: flex; align-items: center">
							<span style="font-size: medium">{{ flight?.balloon?.name | uppercase }}</span>
							<mat-icon [color]="flight?.period === 'MOR' ? 'accent' : 'warn'">fiber_manual_record</mat-icon>
						</div>
					</div>
					<!-- divider with occupancy -->
					<mat-progress-bar
						style="display: flex"
						mode="determinate"
						[value]="flight?.occupancy"
						[color]="flight?.period === 'MOR' ? 'accent' : 'primary'"
					></mat-progress-bar>
					<!-- Time, weather and location -->
					<div style="display: flex; justify-content: space-between; align-items: center">
						<div style="display: flex; flex-direction: column">
							<div style="display: flex; font-size: small">
								{{ flight?.date | formatDate: 'HH:mm' }}
							</div>
							<div style="display: flex; font-size: small; align-items: center">
								{{ flight?.meteo?.tempTakeoff ? flight?.meteo?.tempTakeoff : '??' }}°C -
								{{ flight?.meteo?.windGl ? flight?.meteo?.windGl : '??' }}kt
								<mat-icon [ngStyle]="getWindRotationStyle(flight?.meteo?.windGlDirection)">arrow_right_alt</mat-icon>
							</div>
						</div>
						<div style="display: flex">
							<button [disabled]="true" mat-stroked-button style="background-color: #1aa194; color: white">
								{{ flight?.location?.name | uppercase }}
							</button>
						</div>
					</div>
					<!-- passengers, weight and pilot -->
					<div style="display: flex; justify-content: space-between; align-items: center; gap: 5px">
						<button
							mat-stroked-button
							data-tour="card-flight-bookings"
							[disabled]="flight?.bookings?.count === 0 || !shareBookings || !guards.bookings.includes(userRole)"
							(click)="toggleBookings()"
						>
							<div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 5px">
								<div *ngIf="flight?.passengerCounters.passengersCountAdded !== 0" style="display: flex; align-items: center; gap: 5px">
									<span>{{ flight?.passengerCounters.passengersCountAdded }}</span>
									<mat-icon class="transform-small mat-warn-icon">assignment</mat-icon>
								</div>
								<div *ngIf="flight?.passengerCounters.passengersCountInvited !== 0" style="display: flex; align-items: center; gap: 5px">
									<span>{{ flight?.passengerCounters.passengersCountInvited }}</span>
									<mat-icon class="transform-small mat-warn-icon">sms</mat-icon>
								</div>
								<div style="display: flex; align-items: center; gap: 5px">
									<span>{{ flight?.passengerCounters.passengersCountConfirmed }}/{{ flight?.balloon?.capacity }}</span>
									<mat-icon class="transform-small">assignment_turned_in</mat-icon>
								</div>
							</div>
						</button>

						<div style="display: flex; gap: 5px; align-items: center">
							<span>{{ flight?.liftSpare }}kg</span>
							<mat-icon *ngIf="flight?.liftSpare >= 0">check_circle_outline</mat-icon>
							<mat-icon *ngIf="!(flight?.liftSpare >= 0)" color="warn">block</mat-icon>
						</div>
						<div style="display: flex; gap: 5px; align-items: center">
							<span *ngIf="flight?.pilot?.crew?.name">{{ flight?.pilot?.crew?.name.substr(0, 10) }}</span>
							<mat-icon [color]="flight?.pilot ? 'primary' : 'warn'" (click)="showDialogPilotCrew(flight)">emoji_people</mat-icon>
						</div>
					</div>
				</div>
				<!-- buttons if operator -->
				<div style="display: flex; flex-direction: column; justify-content: space-between; align-items: center; width: 40px; height: 140px">
					<button *ngIf="guards.checkIn.includes(userRole)" mat-icon-button (click)="checkin()" [disabled]="flight?.status !== 'REA'">
						<mat-icon [color]="flight?.status !== 'REA' ? '' : flight?.passengersCheckedIn ? 'accent' : 'warn'">desktop_windows</mat-icon>
					</button>
					<button
						mat-icon-button
						*ngIf="guards.edit.includes(userRole)"
						[matMenuTriggerFor]="dropdownMenu"
						[matMenuTriggerData]="{ flight: flight }"
					>
						<mat-icon>more_vertical</mat-icon>
					</button>
					<button *ngIf="flight?.bookings?.count > 0" mat-icon-button (click)="toggleBookings()">
						<mat-icon *ngIf="!showBookings">keyboard_arrow_down</mat-icon>
						<mat-icon *ngIf="showBookings">keyboard_arrow_up</mat-icon>
					</button>
				</div>
			</div>
		</mat-card-content>
	</mat-card>
	<div *ngIf="draggable" cdkDropList [id]="flight?.id" [cdkDropListData]="flightBookingList" (cdkDropListDropped)="drop($event)">
		<div style="display: flex; flex-direction: column; justify-content: space-evenly; gap: 2px">
			@for (booking of flightBookingList; track booking) {
			<div cdkDrag [cdkDragData]="booking">
				<app-booking-card [bookingId]="booking?.id" [draggable]="draggable"></app-booking-card>
			</div>
			}
			<mat-list dense *ngIf="flightBookingList?.length === 0">
				<mat-list-item>
					<h3 *ngIf="flight?.id" style="display: flex; justify-content: center; align-items: center" cdkDragHandle mat-line>
						{{ 'CALENDAR.no_bookings_yet' | translate | capitalize }}
					</h3>
					<h3 *ngIf="flight?.id === -1" style="display: flex; justify-content: center; align-items: center" cdkDragHandle mat-line>
						{{ 'CALENDAR.drag_and_drop_reservations_here_to_remove_from_this_flight' | translate | capitalize }}
					</h3>
					<h3 *ngIf="flight?.id === 0" style="display: flex; justify-content: center; align-items: center" mat-line>
						{{ 'CALENDAR.good_job' | translate | uppercase }}
					</h3>
					<h3 *ngIf="flight?.id === 0" style="display: flex; justify-content: center; align-items: center" mat-line>
						{{ 'CALENDAR.all_bookings_are_added_to_a_flight' | translate | capitalize }}
					</h3>
					<h3 *ngIf="flight?.id === 0" style="display: flex; justify-content: center; align-items: center" mat-line>
						{{ 'CALENDAR.you_cannot_drop_bookings_here' | translate | capitalize }}
					</h3>
				</mat-list-item>
			</mat-list>
		</div>
	</div>
	<div *ngIf="showBookings && !draggable" @fadeSlideInOut style="display: flex; flex-direction: column; gap: 2px">
		@for (booking of flight?.bookings.list; track booking) {
		<app-booking-card [bookingId]="booking?.id" [draggable]="draggable"></app-booking-card>
		}
	</div>
</div>

<mat-menu #dropdownMenu xPosition="before">
	<ng-template matMenuContent let-flight="flight">
		<button mat-menu-item *ngIf="guards.checkIn.includes(userRole)" (click)="checkin()" [disabled]="flight?.status !== 'REA'">
			<mat-icon [color]="flight?.status !== 'REA' ? '' : flight?.passengersCheckedIn ? 'accent' : 'warn'">desktop_windows</mat-icon>
			<span>{{ 'FLIGHTS.checkin' | translate | capitalize }}</span>
		</button>
		<button mat-menu-item (click)="edit()" [disabled]="!guards.edit.includes(userRole)">
			<mat-icon>edit</mat-icon>
			<span>{{ 'FLIGHTS.update' | translate | capitalize }}</span>
		</button>
	</ng-template>
</mat-menu>
