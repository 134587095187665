import { Component, OnInit } from '@angular/core';
import { MenuItem, MenuService } from 'src/app/core/services';

@Component({
	selector: 'app-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
	menuItems: MenuItem[];

	constructor(private menuService: MenuService) {
		this.menuService.menuItemsListChanged.subscribe((menuItems) => (this.menuItems = menuItems));
	}

	ngOnInit(): void {}

	toggle(menuItem: MenuItem): void {
		menuItem.expanded = !menuItem.expanded;
		// set all other menuItems to expanded false
		this.menuItems.forEach((item) => {
			if (item !== menuItem) {
				item.expanded = false;
			}
		});
	}
}
