{
	"CHARTS": {
		"last1months": "1m",
		"last3months": "3m",
		"last6months": "6m",
		"last12months": "12m",
		"last24months": "24m"
	},
	"VALIDATIONS": {
		"error": "Onverwachte fout, controleer alle velden",
		"minimun_one_adult_or_Child_is_required": "Je hebt minimun één volwassen of kind nodig",
		"error_tenant_does_not_exist": "de opgegeven operator bestaat niet",
		"not_possible": "deze actie is niet mogelijk",
		"required": "verplicht veld",
		"tenant_is_required": "operator is een verplicht veld",
		"email_is_required": "email is een verplicht veld",
		"wrong_password": "foutief wachtwoord",
		"check_email": "controleer het email adres",
		"International_phone_format": "internationaal formaat vereist",
		"email": "ongeldig email adres",
		"duplicate_email": "email adres bestaat reeds",
		"no_integer": "geen geldig geheel getal",
		"token_has_expired": "login is niet meer geldig",
		"wrong_credentials": "foute login gegevens",
		"date_later_than_today": "datum niet vroeger dan 90 dagen geleden",
		"save_or_cancel_updates": "bewaar of annuleer eerst je wijzigingen",
		"warning": "opgelet",
		"capacity_must_be_between_0_and_30": "capaciteit moet tussen 0 en 100 liggen",
		"validation_errors": "verbeter eerst de fouten in het formulier",
		"mismatch_location": "regio past niet",
		"mismatch_availability": "tijdstip past niet",
		"mismatch_start_date": "vlucht datum te vroeg",
		"mismatch_end_date": "vlucht datum te laat",
		"mismatch_type": "vlucht type past niet",
		"mismatch_flight": "toegevoegd aan andere vlucht",
		"missing_function": "deze functie wordt binnenkort verwacht",
		"no_category": "geen passagierscategorieën gevonden, ga eerst naar instellingen om 1 of meerdere categorieën toe te voegen.",
		"no_region": "geen regio's gevonden, ga eerst naar regio's om 1 of meerdere regio's toe te voegen.",
		"lift_mismatch": "er zijn onvoldoende gegevens om de lift te kunnen berekenen",
		"lift_missing_location": "locatie ontbreekt",
		"lift_missing_balloon_volume": "volume van ballon is niet gekend",
		"lift_missing_balloon_weight": "gewicht van ballon is niet gekend of onvolledig",
		"lift_missing_pilot": "piloot is niet gekend",
		"lift_missing_pilot_weight": "het gewicht van de piloot is niet gekend",
		"lift_missing_temp_takeoff": "de temperatuur bij opstijgen is niet gekend",
		"lift_missing_pressure": "de luchtdruk bij opstijgen is niet gekend",
		"mobile_missing_signature": "niet alle passagiers zijn ingecheckt",
		"mobile_missing_forecast": "Geen weersvoorspelling beschikbaar",
		"mobile_documents": "vervaldata documenten",
		"mobile_doc_insurance": "verzekering tot",
		"mobile_doc_airworthiness": "luchtwaardig tot",
		"mobile_doc_license": "licentie piloot tot",
		"mobile_clear": "opnieuw",
		"mobile_agree": "akkoord",
		"mobile_self_sign": "plaats X",
		"click_to_fly": "bevestigen",
		"pax_good_to_fly": "pax ok",
		"lift_good_to_fly": "lift ok",
		"fuel_good_to_fly": "brandstof ok",
		"weather_good_to_fly": "weer ok",
		"docs_good_to_fly": "documenten ok",
		"pilot_sign_off": "bevestig alle parameters en teken dit scherm af voor goedkeuring. Commentaar op de parameters kan toegevoegd te worden.",
		"agree": "akkoord",
		"redo": "opnieuw",
		"new_version": "er is een nieuwe versie",
		"reload_the_app": "wil je Quick Release nu opnieuw starten?",
		"new_features_available": "{0} nieuwe functie(s) beschikbaar",
		"show_new_features": "Toon nieuwe functies",
		"bookings_with_open_amount": "reservaties met openstaand saldo",
		"bookings_to_confirm": "te bevestigen",
		"bookings_bookmarked": "bladwijzers",
		"cylinder_capacity_must_be_between_0_and_200": "capaciteit moet tussen 0 en 200 liggen",
		"cylinder_weight_must_be_between_0_and_200": "het gewicht moet tussen 0 en 200 liggen",
		"unauthorized": "you are not authorized for this operation",
		"disconnected": "connectivity problem, do you have internet?",
		"possible_duplicates": "er is mogelijk een dubbele reservatie (hetzelfde e-mail adres werd gevonden in een andere reservatie)",
		"possible_duplicates_with_flights": "er is mogelijk een dubbele reservatie met 2 verschillende vluchtdata",
		"duplicate_flight": "ballon is dubbel ingepland",
		"duplicate_pilot": "piloot is dubbel ingepland"
	},
	"HINTS": {
		"add_country_code": "Voeg landcode toe +32...",
		"no_contact_selected": "Selecteer een contactpersoon",
		"message_filter": "filter aan/uit",
		"role_can_only_be_set_after_creation": "de rol kan pas ingesteld worden na het aanmaken",
		"please_wait": "even geduld"
	},
	"SKEYES": {
		"ballooning_bulletin": "Ballooning Bulletin",
		"load": "Skeyes login",
		"disclaimer": "Het Ballooning Bulletin is een dienst van Skeyes.",
		"instructions": "Geef je Skeyes login en paswoord in om het Ballooning Bulletin te raadplegen. Enkel piloten met een geldige licentie kunnen het Ballooning Bulletin raadplegen. De gegevens worden enkel gebruikt om het Ballooning Bulletin te raadplegen en worden niet opgeslagen. Gebruiksvoorwaarden Skeyes zijn van toepassing.",
		"username": "Skeyes Username",
		"password": "Skeyes Password",
		"loaded_by": "ingeladen door",
		"loading_can_take_up_to_15sec": "het inladen kan tot 15 seconden duren",
		"error": "Onverwachte fout, controleer alle velden",
		"slot_warning": "dit bulletin is mogelijk niet van toepassing voor deze vlucht"
	},
	"APPLICATION": {
		"domain": "domein",
		"Du": "nederlands (Nederland)",
		"Nl": "nederlands (België)",
		"Fr": "français (french)",
		"En": "english",
		"Es": "español (spanish)",
		"Cs": "cesky (czech)",
		"It": "Italiano (Italian)",
		"De": "Deutsch (German)",
		"Pt": "Português (portuguese)",
		"Lv": "Latviešu (Latvian)",
		"date": "datum",
		"name": "naam",
		"call_sign": "call sign",
		"capacity": "capaciteit",
		"flight_count": "aantal vluchten",
		"sku": "beschrijving",
		"unit_count": "eenheden",
		"unit_cost": "prijs per eenheid",
		"send_sms": "verzonden sms",
		"receive_sms": "ontvangen sms",
		"sms_count": "aantal sms",
		"payments": "betalingen",
		"running_month": "huidige maand",
		"history_balloons": "historiek ballonnen",
		"history_sms": "historiek sms",
		"total_cost": "prijs",
		"sorry": "sorry",
		"flights": "vluchten",
		"calendar": "kalender",
		"bookings": "reservaties",
		"vouchers": "bonnen",
		"balloons": "ballonnen",
		"crew": "crew en piloten",
		"regions": "regio's",
		"locations": "locaties",
		"reports": "rapporten",
		"admin": "beheer",
		"releasenotes": "release notes",
		"subscription": "abonnement",
		"log_sms_in": "inkomende SMS",
		"log_sms_out": "uitgaande SMS",
		"settings": "instellingen",
		"cylinders": "gasflessen",
		"language": "taal",
		"sms": "SMS",
		"help": "help",
		"search": "zoek",
		"live_filter_off": "Gebruik de search button om te zoeken. Als er meer dan 25 zoek resultaten getoond moeten worden, dan is live search niet mogelijk",
		"add_booking": "nieuwe reservatie",
		"add_voucher": "nieuwe bon",
		"add_flight": "nieuwe vlucht",
		"privacy_policy": "privacy beleid",
		"terms": "voorwaarden",
		"sign in to use your Quick Release Account": "meld je aan voor je Quick Release Account",
		"your username and password didn't match": "er is een probleem met je gebruikersnaam of wachtwoord",
		"please try again": "probeer opnieuw",
		"username": "gebruiker",
		"password": "wachtwoord",
		"sign_in": "log in",
		"send_PIN": "stuur code",
		"use_PIN": "gebruik SMS code",
		"pin_successfully_sent": "PIN met succes verstuurd",
		"use_password": "gebruik wachtwoord",
		"email": "email",
		"send_email_to": "selecteer bestemmeling",
		"select_template": "selecteer template",
		"sent": "verzonden",
		"waiting": "bezig met verzenden",
		"pin": "code",
		"sign_out": "log uit",
		"save": "bewaar",
		"save_and_back": "bewaar en terug",
		"cancel": "annuleer",
		"previous": "vorige",
		"delete": "verwijder",
		"refresh": "vernieuw",
		"today": "vandaag",
		"classic": "terug naar oude interface",
		"month": "maand",
		"week": "week",
		"morning_flights": "ochtendvluchten vandaag",
		"evening_flights": "avondvluchten vandaag",
		"morning_flights_tomorrow": "ochtendvluchten morgen",
		"yesterday_flights": "avondvluchten gisteren",
		"action": "actie",
		"version": "versie",
		"hide_details": "details verbergen",
		"loads_faster": "details verbergen zal de laadsnelheid van de vluchten verbeteren",
		"assign": "toewijzen",
		"assigned": "toegewezen",
		"assign_to": "vlucht toewijzen aan",
		"close": "sluit",
		"apps": "configuraties"
	},
	"CALENDAR": {
		"waiting_list": "wachtlijst",
		"no_bookings_yet": "Nog geen reservaties",
		"drag_and_drop_reservations_here_to_add_to_this_flight": "sleep reservaties naar hier om aan de vlucht toe te voegen",
		"drag_and_drop_reservations_here_to_remove_from_this_flight": "sleep reservaties naar hier om te verwijderen van de vlucht",
		"good_job": "goed gewerkt",
		"all_bookings_are_added_to_a_flight": "alle reservaties werden aan een vlucht toegevoegd",
		"you_cannot_drop_bookings_here": "je kan hier geen reservaties droppen",
		"show_calendar_crew": "toon kalendar van crew",
		"set_month": "zet volledige maand",
		"clear_month": "verwijder alles",
		"plan": "plan",
		"refetch": "vernieuw"
	},
	"ACTORS": {
		"COM": "CPL",
		"NCO": "PPL",
		"NOL": "geen licentie",
		"NOA": "geen login",
		"OPR": "operator",
		"CRE": "piloot/crew (enkel toegewezen vluchten)",
		"REP": "rapporten lezen",
		"ASS": "Operator assistent (vluchten & reservaties)",
		"MET": "meteo (enkel vluchten)",
		"user_role": "gebruikersrol",
		"name": "naam",
		"phone": "telefoon",
		"email": "email",
		"used_for_login": "email gebruikt voor login",
		"emailNotifications": "email notificaties",
		"emails_from_the_system_will_be_send_to_this_address": "emails verzonden vanuit het systeem worden naar dit adres gestuurd",
		"driver_license": "rijbewijs",
		"pilot_license": "vergunning ",
		"ground_crew": "crew",
		"language": "taal",
		"confirm_message": "bevestig om te verwijderen",
		"confirm": "bevestigen",
		"contacts": "contacten",
		"update": "wijzig",
		"delete": "verwijder",
		"save": "bewaar",
		"add_contact": "voeg contact toe",
		"crew": "crew",
		"pilot": "piloot",
		"add_crew": "voeg crew toe",
		"national_license": "nationale licentie",
		"part_fcl_license": "PART-FCL licentie",
		"license_group_A": "groep A",
		"license_group_B": "groep B",
		"license_group_C": "groep C",
		"license_group_D": "groep D",
		"license_gas": "gasballon",
		"license_number": "licentie nummer",
		"license_expiry_date": "vervaldag licentie / OPC",
		"license_extra_crew": "extra crew",
		"medical_expiry_date": "vervaldag medische keuring",
		"weight": "gewicht",
		"warning_not_current": "Piloot voldoet binnen de 30 dagen niet meer aan de voorwaarden, controleer de vervaldata",
		"error_not_current": "Piloot voldoet niet meer aan de voorwaarden, controleer de vervaldata",
		"assigned_to_flight": "toegewezen aan vlucht",
		"your_flight": "jouw vlucht",
		"your_flights": "jouw vluchten",
		"pilots": "piloten"
	},
	"BALLOONS": {
		"name": "naam",
		"call_sign": "call sign",
		"capacity": "aantal passagiers",
		"total_weight": "totaal gewicht",
		"balloons": "ballonnen",
		"confirm_message": "bevestig om te verwijderen",
		"confirm": "bevestigen",
		"update": "wijzig",
		"delete": "verwijder",
		"save": "bewaar",
		"add_balloon": "voeg ballon toe",
		"group": "groep",
		"MLM": "MTM (Minimum Landing Mass)",
		"MTOM": "MTOM (Maximum Take-Off Mass)",
		"min_fuel": "gemiddeld verbruik (Liter per uur)",
		"fuel": "verbruik bij 20°C en maximum aantal passagiers",
		"envelope": "ballon",
		"envelope_manufacturer": "ballon fabrikant",
		"envelope_type": "ballon type",
		"envelope_volume": "ballon volume",
		"envelope_weight": "ballon gewicht",
		"envelope_hours": "ballon vlieg uren",
		"envelope_inspection_date": "ARC geldig tot",
		"balloon_temp": "max ballon temperatuur",
		"burner": "brander",
		"burner_manufacturer": "brander fabrikant",
		"burner_type": "brander type",
		"burner_weight": "brander gewicht",
		"cylinder": "gasflessen",
		"cylinder_manufacturer": "fabrikant",
		"cylinder_volume": "volume",
		"cylinder_count": "aantal flessen",
		"cylinder_empty_weight": "leeg gewicht fles",
		"cylinder_full_weight": "vol gewicht fles",
		"cylinder_total_weight": "totaal gewicht flessen",
		"cylinder_number": "nr",
		"cylinder_serial": "serienummer",
		"basket": "mand",
		"basket_manufacturer": "mand fabrikant",
		"basket_type": "mand type",
		"basket_weight": "mand gewicht",
		"insurance_company": "verzekeringsmaatschappij",
		"insurance_due_date": "vervaldag verzekering",
		"add_cylinder": "voeg gasflessen toe",
		"error_not_current": "Ballon op de grond, pas de vervaldata aan",
		"warning_not_current": "Ballon vervalt binnen 30 dagen, pas de vervaldata aan"
	},
	"VOUCHERS": {
		"status": "status",
		"create_booking": "maak reservatie",
		"available": "beschikbaar",
		"used": "verbruikt",
		"expired": "verlopen",
		"voucher_type": "type",
		"voucher_reference": "referentie",
		"issue_date": "datum uitgifte",
		"expiry_date": "vervaldatum",
		"contactName": "contact naam",
		"contactPhone": "telefoon",
		"contactLanguage": "taal",
		"contactEmail": "e-mail",
		"contact_city": "stad",
		"recipient_info": "details van de persoon die de voucher zal ontvangen en gebruiken",
		"buyer_info": "details van de persoon die de voucher heeft gekocht",
		"details_info": "specifieke informatie over de voucher zelf",
		"buyer": "aangekocht door",
		"recipient": "ontvanger",
		"recipientName": "naam",
		"recipientPhone": "telefoon",
		"recipientLanguage": "taal",
		"recipientEmail": "e-mail",
		"recipientAddress": "adres",
		"recipientPostalCode": "postcode",
		"recipientCity": "stad",
		"recipientCountry": "land",
		"details": "details",
		"comments": "opmerkingen",
		"paymentAmount": "bedrag",
		"paymentStatus": "betaalstatus",
		"paymentDate": "betaaldatum",
		"adults": "aantal volwassenen",
		"children": "aantal kinderen",
		"external": "extern",
		"external_source": "bron",
		"tickets": "tickets",
		"voucher": "bon",
		"available_vouchers": "beschikbare bonnen",
		"confirm_message": "ben je zeker dat je deze bon wil verwijderen?",
		"IMPORT": {
			"import": "importeer",
			"upload_csv": "upload CSV",
			"upload_valid_csv_with_this_header": "upload een geldig CSV bestand met de volgende header",
			"example_with_2_adults": "voorbeeld van cadeau bon voor 2 volwassenen",
			"choose_file": "selecteer bestand",
			"check_file": "valideer bestand",
			"check_errors": "verbeter onderstaande fouten",
			"not_a_valid_CSV": "dit is geen geldig bestand",
			"valid_CSV": "geen validatie fouten",
			"click_import": "klik op importeren",
			"number_of_vouchers": "aantal bonnen",
			"import_status": "import status",
			"imported_succesfully": "met succes geïmporteerd",
			"imported_with_errors": "fouten tijdens het importeren",
			"finish": "beëindig",
			"row": "rij",
			"error_more_less_columns": "meer of minder kolommen dan verwacht",
			"error_header": "fout in de header",
			"error_contact_name": "fout in contactName, deze is verplicht",
			"error_contact_phone": "fout in contactPhone, deze is verplicht en van het formaat M:+32...",
			"error_contact_language": "fout in contactLanguage, deze is verplicht",
			"error_voucher_type": "fout in voucher type, deze is verplicht",
			"error_voucher_reference": "fout in voucher reference, deze is verplicht",
			"error_issue_date": "fout in issue date, deze is verplicht en moet een datum zijn in het formaat YYYY-MM-DD",
			"error_expiry_date": "fout in expiry date, deze is verplicht en moet een datum zijn in het formaat YYYY-MM-DD",
			"error_payment_date": "fout in payment date, deze is verplicht en moet een datum zijn in het formaat YYYY-MM-DD",
			"error_payment_status": "fout in payment status, deze is verplicht en moet OPEN of PAID zijn",
			"error_payment_type": "fout in payment type, deze is verplicht één van volgende waardes: NOPA, CASH, WIRE, BANC, CRED, ONLI",
			"error_adults": "fout in adults, dit moet een cijfer zijn",
			"error_children": "fout in children, dit moet een cijfer zijn",
			"error_import": "fout tijdens import",
			"succes_import": "met succes geïmporteerd",
			"cancel": "annuleer"
		}
	},
	"BOOKINGS": {
		"id": "nr",
		"availability": "beschikbaarheid",
		"PERI": "altijd beschikbaar",
		"DATE": "beschikbaar op datum",
		"MOR": "ochtend",
		"EVE": "avond",
		"mo": "ma",
		"tu": "di",
		"we": "wo",
		"th": "do",
		"fr": "vr",
		"sa": "za",
		"su": "zo",
		"save": "bewaar",
		"child": "kind",
		"name": "naam",
		"weight": "gewicht",
		"luggage": "bagage",
		"paymentType": "betalingswijze",
		"paymentReference": "betalingsreferentie",
		"paymentAmount": "bedrag",
		"paymentStatus": "betaalstatus",
		"paymentDate": "betaaldatum",
		"delete": "verwijder",
		"OPEN": "open",
		"UNK": "toegevoegd",
		"INV": "uitgenodigd per sms",
		"CON": "bevestigd",
		"completed": "uitgevoerd",
		"CAN": "geannuleerd",
		"CPP": "privé",
		"COP": "commercieel",
		"NCP": "recreatief",
		"booking_date": "reservatie datum",
		"days_expired": "{{ d }} dagen verlopen",
		"startDate": "start datum",
		"endDate": "eind datum",
		"status": "status",
		"bookingType": "type reservatie",
		"bookingCode": "reservatie code",
		"bookingCodeHint": "klik op de code om de passagiersapp te openen",
		"bookingCodeMissingEmail": "zonder email adres hebben de passagiers geen toegang tot de passagiers app",
		"comments": "opmerkingen",
		"region": "regio",
		"source": "bron",
		"new_source": "nieuwe bron",
		"you_can_manage_all_sources_in_settings": "je kan alle bronnen beheren in de instellingen",
		"source_validation": "bron is verplicht, min 3, max 50 karakters en moet uniek zijn",
		"source_list_validation": "Kies een bron uit de lijst of maak eerst een nieuwe bron aan",
		"locationType": "locatie",
		"REGI": "alle locaties in een regio",
		"FIXE": "specifieke locatie in een regio",
		"PRIV": "privé locatie",
		"contactPerson": "contact persoon",
		"contactName": "contact naam",
		"contactPhone": "contact telefoon",
		"contactEmail": "contact email",
		"contactLanguage": "contact taal",
		"action_needed": "actie nodig",
		"answer_flight_yes_no": "Antwoord VLUCHT JA of VLUCHT NEE",
		"flight_yes": "VLUCHT JA",
		"flight_no": "VLUCHT NEE",
		"PAID": "betaald",
		"REFUNDED": "terugbetaald",
		"not_paid": "te betalen",
		"CASH": "contant",
		"COUP": "bon (uit systeem)",
		"VOUC": "bon",
		"NOPA": "geen betaling",
		"WIRE": "overschrijving",
		"BANC": "bancontact",
		"CRED": "credit card",
		"ONLI": "online",
		"INVO": "factuur",
		"only_available_with_paymenttype_voucher": "enkel beschikbaar bij betaalwijze bon",
		"bookings": "reservaties",
		"booking": "reservatie",
		"confirm": "bevestigen",
		"update": "wijzig",
		"contact": "contact",
		"passengers": "passagiers",
		"cancel": "annuleer",
		"next": "volgende",
		"flightDate": "vlucht datum",
		"flightPeriod": "vlucht periode",
		"flight_balloon": "vlucht ballon",
		"flight_location": "vlucht locatie",
		"flight_pilot": "vlucht piloot",
		"add_passenger": "voeg passagier toe",
		"messages": "berichten",
		"no_bookings_found": "geen reservaties gevonden",
		"inviteCount": "uitnodigingen",
		"confirm_message": "ben je zeker dat je deze reservatie wil verwijderen?",
		"always_available": "altijd beschikbaar vanaf",
		"limited_availability": "beperkte beschikbaarheid vanaf",
		"end_before": "vlucht voor",
		"passenger": "passagier",
		"details": "details",
		"payment_details": "betalingen",
		"address_details": "adres info",
		"disability": "speciale noden",
		"category": "categorie",
		"address": "adres",
		"postalCode": "postcode",
		"city": "stad",
		"country": "land",
		"signature": "handtekening",
		"added_flight": "toegewezen vlucht",
		"select_flight": "selecteer vlucht",
		"no_flight_added": "geen vlucht toegewezen",
		"no_flight_data": "er worden enkel beschikbare ballonnen en vluchten getoond als de locatie specifiek of privé is en de beschikbaarheid op datum is",
		"available_flights": "beschikbare vluchten",
		"no_available_flights": "geen beschikbare vluchten",
		"available_balloons": "beschikbare ballonnen",
		"no_available_balloons": "geen beschikbare ballonnen",
		"totalAmount": "totaal bedrag",
		"paid_amount": "betaald",
		"openAmount": "saldo",
		"prepayment": "voorschot",
		"prepaymentDate": "voorschot datum",
		"passengers_to_pay": "passagier(s) te betalen",
		"mark_paid": "markeer als voldaan",
		"remove_from_passengerlist": "verwijder van passagierslijst",
		"add_to_passengerlist": "voeg extra passagier toe",
		"checkin_passengers": "terug naar check in passagiers",
		"view_checked_in": "toon ingecheckte passagiers",
		"hide_checked_in": "verberg ingecheckte passagiers",
		"import": "importeer",
		"upload_csv": "upload CSV",
		"upload_valid_csv_with_this_header": "upload een geldig CSV bestand met de volgende header",
		"example_in_region_with_id_1_with_2_adults_and_0_children": "voorbeeld van reservatie met 2 volwassenen voor de regio met id 1",
		"choose_file": "selecteer bestand",
		"check_file": "valideer bestand",
		"check_errors": "verbeter onderstaande fouten",
		"not_a_valid_CSV": "dit is geen geldig bestand",
		"valid_CSV": "geen validatie fouten",
		"click_import": "klik op importeren",
		"number_of_bookings": "aantal reservaties",
		"number_of_objects_upcoming": "open reservaties/vluchten",
		"number_of_objects_history": "gesloten reservaties/vluchten",
		"import_status": "import status",
		"imported_succesfully": "met succes geïmporteerd",
		"imported_with_errors": "fouten tijdens het importeren",
		"finish": "beëindig",
		"row": "rij",
		"error_more_less_columns": "meer of minder kolommen dan verwacht",
		"error_header": "fout in de header",
		"error_contact_name": "fout in contactName, deze is verplicht",
		"error_contact_phone": "fout in contactPhone, deze is verplicht en van het formaat M:+32...",
		"error_contact_language": "fout in contactLanguage, deze is verplicht",
		"error_region": "fout in region, deze is verplicht en moet het ID bevatten van de regio",
		"error_adults": "fout in adults, dit moet een cijfer zijn",
		"error_children": "fout in children, dit moet een cijfer zijn",
		"error_import": "fout tijdens import",
		"succes_import": "met succes geïmporteerd",
		"flight_confirmation": "vlucht bevestigen",
		"date_filter_is_yyyy-mm-dd": "datum filter = yyyy-mm-dd",
		"select_your_view": "selecteer de weergave",
		"VIEW": {
			"standard": "standaard weergave",
			"openAmount": "met openstaand saldo",
			"toConfirm": "te bevestigen",
			"bookmarked": "met bladwijzers",
			"availableThisYear": "beschikbaarheid dit jaar",
			"availableNext30Days": "beschikbaarheid volgende 30 dagen",
			"flyingThisYear": "vlucht dit jaar",
			"flyingNext30Days": "vlucht volgende 30 dagen",
			"withoutFlight": "zonder vlucht"
		},
		"flight_confirmed": "vlucht bevestigd",
		"invited_waiting_answer": "uitgenodigd, wachtend op antwoord",
		"MERGE": {
			"merge": "voeg samen",
			"load": "laad",
			"restart": "begin opnieuw",
			"select_booking": "selecteer reservaties",
			"select_booking_hint": "de linkse reservatie zal verwijderd worden, de rechtse reservatie zal behouden blijven",
			"merge_booking": "voeg reservaties samen",
			"merge_booking_hint": "geef je akkoord om te verwijderen alvorens te bewaren",
			"booking_id_to_delete": "reservatie id om te verwijderen",
			"booking_id_to_merge": "reservatie id om samen te voegen",
			"error_loading": "fout bij het laden, kies een andere reservatie id of probeer opnieuw",
			"warning_to_switch": "waarschuwing: je kiest ervoor om de meest recente reservatie (links) te verwijderen",
			"save_and_delete": "bewaar deze reservatie en verwijder",
			"confirm_to_delete": "akkoord om te verwijderen",
			"replace": "vervang",
			"add": "voeg samen",
			"undo": "herstel",
			"fields_are_identical": "velden zijn identiek",
			"remove": "verwijder"
		},
		"LOG": {
			"logbook": "logboek",
			"outbox": "Verzonden e-mails",
			"only_logs_from_UI": "gebeurtenissen uit Quick Release (niet vanuit UP of via website integratie)",
			"ADDE": "toegevoegd aan de vlucht",
			"REMO": "verwijderd uit de vlucht",
			"CONF": "bevestigd voor de vlucht",
			"cancel": "vlucht geannuleerd, terug naar wachtlijst",
			"COMP": "vlucht uitgevoerd, reservatie afgesloten",
			"INVI": "uitgenodigd per SMS voor deelname aan de vlucht",
			"CREA": "reservatie aangemaakt in het systeem",
			"update": "reservatie details gewijzigd",
			"DELE": "reservatie verwijderd uit het systeem",
			"MAIL": "email verstuurd",
			"view_email": "toon email",
			"flight": "vlucht"
		},
		"age": "leeftijd",
		"age_amount": "jaar",
		"transport": "vervoer",
		"invoice_name": "factuur naam",
		"vat_number": "btw nummer"
	},
	"COMMUNICATIONS": {
		"sent_at": "verzonden om",
		"phone": "telefoon",
		"name": "naam",
		"text": "tekst",
		"status": "status",
		"message_id": "bericht ID",
		"message_price": "bericht prijs",
		"network": "netwerk",
		"remaining_balance": "resterend saldo",
		"received_at": "ontvangen om",
		"msisdn": "msisdn",
		"message_timestamp": "tijdstip bericht",
		"keyword": "trefwoord",
		"pilot_invitation_sms": "SMS aan piloten",
		"crew_invitation_sms": "SMS aan crew",
		"passenger_invitation_sms": "SMS aan passagiers",
		"cancel_sms": "annulatie SMS",
		"reminder_sms": "herinnering SMS",
		"sms_in": "inbound sms",
		"messages": "berichten",
		"flight": "vlucht",
		"booking": "reservatie",
		"sms_out": "outbound sms",
		"to": "naar",
		"unanswered_messages": "onbeantwoorde berichten",
		"mark": "markeer",
		"mark_all": "markeer alles",
		"mark_paid": "markeer betaald",
		"mark_not_paid": "markeer niet betaald",
		"messages_as_read": "berichten als gelezen",
		"you": "jij",
		"mail_copy": "E-mail overzicht",
		"action_succeed": "actie succesvol",
		"accepted": "geaccepteerd",
		"delivered": "afgeleverd",
		"failed": "niet afgeleverd",
		"unknown": "reden niet gekend",
		"retry": "probeer opnieuw",
		"wrongnumber": "verkeerd nummer",
		"none": "geen"
	},
	"DASHBOARDS": {
		"flights": "vluchten",
		"no_flights": "geen vluchten",
		"upcoming": "aankomende",
		"historical": "beëindigd",
		"this_month": "deze maand",
		"bookings": "reservaties",
		"vouchers": "bonnen",
		"open": "open",
		"completed": "uitgevoerd",
		"expired": "verlopen",
		"idea_voting": "Stem op ideeën",
		"search": "zoek",
		"payments": "betalingen",
		"charts": "grafieken",
		"flights_of_today_and_tomorrow_morning": "alle vluchten van vandaag en morgen ochtend",
		"flights_of_next_7_days": "alle vluchten van de komende 7 dagen",
		"bookings_to_confirm_bookmarked_and_of_today_or_tomorrow_morning": "alle reservaties te bevestigen of beschikbaar vandaag of morgen ochtend of recente reservaties of met een dashboard-tag",
		"basic_charts_of_reservations_and_flights": "standaard grafieken voor reservaties en vluchten",
		"charts_are_no_longer_shown_on_the_dashboard_please_navigate_to_reports": "grafieken worden niet meer op het dashboard getoond, je vindt ze bij rapporten",
		"navigate_to_reports": "Navigeer naar rapporten",
		"most_recent_sms_per_contact": "meest recente berichten"
	},
	"FLIGHTS": {
		"save": "bewaar",
		"unknown": "onbekend",
		"CON": "bevestigd",
		"invited_by_sms": "uitgenodigd per sms",
		"DEC": "geweigerd",
		"MOR": "ochtend",
		"EVE": "avond",
		"CPF": "commerciële privé vlucht",
		"COF": "commerciële vlucht",
		"NCF": "recreatieve vlucht",
		"PRI": "niet gedeeld",
		"PUB": "gedeeld",
		"SCH": "gepland",
		"REA": "klaar",
		"MARK": {
			"SCH": "plan",
			"REA": "zet klaar",
			"CAN": "annuleer",
			"FIN": "beëindig"
		},
		"INFO": {
			"SCH": "bepaal alle vlucht details en markeer dan klaar",
			"REA": "goed gedaan! Deze vlucht is klaar voor lift-off",
			"CAN": "deze vlucht is geannuleerd, je kan ze verwijderen uit het systeem",
			"FIN": "deze vlucht is uitgevoerd. Goed gewerkt!"
		},
		"checkin": "check in",
		"CAN": "geannuleerd",
		"FIN": "beëindigd",
		"balloon": "ballon",
		"date": "datum",
		"period": "periode",
		"event": "event",
		"hour": "uur",
		"sunrise": "zonsopgang",
		"sunset": "zonsondergang",
		"flight_type": "type vlucht",
		"location": "opstijglocatie ",
		"visibility": "zichtbaarheid",
		"web": "web",
		"status": "status",
		"comment": "opmerkingen",
		"pilot": "piloot",
		"pilot_status": "status",
		"action_needed": "actie nodig",
		"ground_crew": "crew",
		"pilot_and_crew": "piloot en crew",
		"not_enough_data": "onvoldoende gegevens",
		"answer_pilot_yes_no": "Antwoord PILOOT JA of PILOOT NEE",
		"pilot_yes": "PILOOT JA",
		"pilot_no": "PILOOT NEE",
		"Flight date cannot be set in the past": "vluchtdatum kan niet in het verleden vallen",
		"answer_crew_yes_no": "Antwoord CREW JA of CREW NEE",
		"crew_yes": "CREW JA",
		"crew_no": "CREW NEE",
		"flights": "vluchten",
		"flight_bookings": "reservaties",
		"UNK": "toegevoegd",
		"INV": "uitgenodigd per sms",
		"invite_all": "SMS uitnodiging allen",
		"inviteCount": "uitnodigingen",
		"invite_count_1": "uitnodiging",
		"mo": "ma",
		"tu": "di",
		"we": "wo",
		"th": "do",
		"fr": "vr",
		"sa": "za",
		"su": "zo",
		"invite": "stuur sms",
		"mail": "e-mail",
		"reply": "antwoord",
		"confirm": "bevestigen",
		"remove": "verwijder",
		"contactName": "contact naam",
		"bookingType": "reservatie type",
		"region": "regio",
		"passengers": "passagiers",
		"no_bookings_selected": "geen reservaties geselecteerd",
		"matching_bookings": "reservaties",
		"mismatching_bookings": "reservaties op een andere vlucht",
		"mismatchReasons": "vlucht matching",
		"add_all": "voeg allen toe",
		"add": "voeg toe",
		"already_added": "al toegevoegd",
		"messages": "berichten",
		"calendar": "kalender",
		"download_manifest": "passagierslijst",
		"send_sms_reminder": "SMS herinnering",
		"update": "wijzig",
		"are_you_sure": "ben je zeker",
		"reminder_sms_will_be_sent": "herinnering SMS zal verzonden worden",
		"edit_sms": "bewerk SMS",
		"preview_sms": "voorbeeld",
		"send": "stuur",
		"send_to_pax": "stuur naar PAX",
		"send_to_pax_and_crew": "stuur naar PAX en CREW",
		"send_up_url_with_sms": "stuur UP url met SMS",
		"sent": "verzonden",
		"message_has_been_sent": "bericht werd verzonden",
		"message_has_not_been_sent": "bericht werd niet verzonden",
		"not_sent": "niet verzonden",
		"not_confirmed_bookings_will_be_removed_from_flight": "de volgende, niet bevestigde, reservaties zullen verwijderd worden van de vlucht",
		"flight_will_be_cancelled": "Vlucht zal geannuleerd worden",
		"flight_will_be_finished": "Vlucht zal beëindigd worden",
		"flight_will_be_reopened": "Vlucht zal opnieuw in de planning komen",
		"yes_cancel_it": "ja, annuleer",
		"no_please": "neen",
		"cancel_sms_will_be_sent": "annuleer SMS zal verzonden worden",
		"not_cancelled": "niet geannuleerd",
		"flight_has_not_been_cancelled": "vlucht werd niet geannuleerd",
		"confirm_message": "ben je zeker om te verwijderen?",
		"flight_pilot_in_command": "pilot in command",
		"invite_by_sms": "stuur sms",
		"unsupported_phonenumber": "telefoonnummer wordt niet ondersteund",
		"sms_unclear": "onduidelijke sms",
		"no_pilot_in_command_selected": "geen pilot in command geselecteerd",
		"flight_crew": "crew",
		"no_crew_selected": "geen crew geselecteerd",
		"available_pilots_and_crew": "beschikbare piloten en crew",
		"select_pilot": "selecteer piloot",
		"select_crew": "selecteer crew",
		"other_flight": "andere vlucht",
		"add_as_crew": "crew",
		"add_as_pilot": "piloot",
		"warning": "waarschuwing",
		"creating_a_flight_can_lead_to_costs": "deze actie kan kosten genereren",
		"proceed": "ga verder",
		"cancel": "annuleer",
		"passenger_count": "passagiers",
		"crew_count": "crew",
		"type": "type",
		"no_pilot": "geen piloot",
		"seats": "passagiers ",
		"delete": "verwijder",
		"set_ready": "zet klaar",
		"address": "adres",
		"postalCode": "postcode",
		"city": "stad",
		"country": "land",
		"manifest": "passagierslijst",
		"certificates": "certificaten",
		"takeoffTime": "vertrek tijd",
		"landingLocation": "landing locatie",
		"landingDetails": "landing details",
		"total_weight": "totaal gewicht",
		"landingTime": "landing tijd",
		"flightTime": "vlieg tijd",
		"and": "en",
		"name": "naam",
		"ref": "referentie",
		"amount": "bedrag (EUR)",
		"weight": "gewicht",
		"signature": "handtekening",
		"reminder_sms": "herinnering SMS",
		"also_to_pilot_and_crew": "ook naar piloot en crew",
		"cancel_sms": "annuleer SMS",
		"no_flights_found": "geen vluchten gevonden",
		"no_location": "geen locatie",
		"show": "toon",
		"hide": "verberg",
		"lift": "lift",
		"lift_needed": "nodige lift",
		"lift_available": "draagvermogen",
		"lift_spare": "reserve draagvermogen",
		"takeoff_location": "opstijglocatie",
		"temp": "temperatuur",
		"takeoff_height": "opstijghoogte",
		"maximum_height": "maximum hoogte",
		"temp_takeoff": "opstijgtemperatuur",
		"temp_max": "maximum hoogte temperatuur",
		"weather_qfe": "luchtdruk (QNH)",
		"weather_pmax": "maximum hoogte luchtdruk",
		"balloon_volume": "ballon volume",
		"balloon_temp": "max ballon temperatuur",
		"create_add": "voeg toe aan nieuwe vlucht",
		"weather": "weer",
		"copy_weather_to": "kopieer weer naar",
		"use_meteoblue": "gebruik meteoblue",
		"skeyes_ballooning_bulletin": "skeyes ballooning bulletin",
		"use_skeyes": "gebruik Skeyes",
		"weather_forecast": "weersvoorspelling",
		"no_weather_forecast": "er is geen weersvoorspelling beschikbaar",
		"windSummary": "samenvatting wind",
		"weather_source": "bron",
		"weather_forecast_date": "datum voorspelling",
		"vfr_visual": "zicht",
		"vfr_clouds": "wolkendek",
		"wind_gl": "wind grond niveau",
		"wind_gl_short": "wind GL",
		"wind_dir": "richting",
		"wind_gl_direction": "wind richting grond niveau",
		"wind_10": "wind 1000FT",
		"wind_10_direction": "wind richting 1000FT",
		"wind_20": "wind 2000FT",
		"wind_20_direction": "wind richting 2000FT",
		"wind": "wind",
		"clear": "clear",
		"fuel_spare": "reserve brandstof",
		"planned_flight_time": "geplande vliegtijd",
		"spare_flight_time": "reserve vliegtijd",
		"maximum_flight_time": "beschikbare vliegtijd",
		"select_balloon": "selecteer de ballon",
		"select_location": "selecteer de locatie",
		"offsets": "pas sunrise/sunset offsets aan",
		"flight_hour_will_be_calculated_based_on_sunset_minus_the_offset_minutes": "het uur van de vlucht wordt berekend op basis van zonsondergang (of zonsopgang) min het aantal offset minuten, afgerond naar beneden op het half uur. Negatieve waardes (zonsopgang bv.) zijn toegelaten.",
		"offset_sunrise": "offset sunrise (minuten)",
		"offset_sunset": "offset sunset (minuten)",
		"payment_overview": "betaaloverzicht",
		"go_back": "ga terug",
		"traject": {
			"trajectory": "traject",
			"custom_flight_profile": "aangepast vlucht profiel",
			"duration": "duur",
			"duration_is_required": "duur is verplicht",
			"level": "niveau in hPa",
			"level_is_required": "niveau is verplicht",
			"save": "bewaar",
			"no_trajectory_without_meteo": "geen traject zonder weersvoorspelling",
			"update_custom_profile": "wijzig aangepast profiel",
			"refresh_weather": "vernieuw weer",
			"talking_to_meteoblue": "communicatie met meteoblue",
			"refresh_hint": "meteoblue doet elke 12u een update van hun weermodel. Snellere updates hebben geen zin en zijn uitgeschakeld."
		},
		"LANDING": {
			"landing": "landing",
			"landing_details": "details",
			"map": "map",
			"no_details": "geen details ingevuld",
			"landing_data": "landingsgegevens kan je voorlopig enkel invullen in het mobiele check-in scherm van de vlucht",
			"refuelVolume": "getankt volume gas"
		},
		"FILTER": {
			"date_range_filter": "datum filter",
			"from": "van",
			"till": "tot",
			"excluded": "uitgesloten",
			"no_date_filter_active": "geen datum filter actief",
			"enter_a_date_range": "geef een datum range in",
			"start_date": "start datum",
			"end_date": "eind datum",
			"month": "maand",
			"week": "week",
			"day": "dag",
			"flights": "vluchten"
		},
		"flight_no_bookings": "deze vlucht heeft nog geen boekingen",
		"mor_flight": "ochtend vlucht",
		"eve_flight": "avond vlucht",
		"mor_flights": "ochtend vluchten",
		"eve_flights": "avond vluchten",
		"no_mor_flights": "geen ochtend vluchten",
		"no_eve_flights": "geen avond vluchten",
		"responded_pilots": "gereageerde piloten",
		"delete_flight": "verwijder vlucht",
		"toggle_visibility": "verander zichtbaarheid",
		"tags": "tags",
		"COPY": {
			"copy_flight": "kopieer vlucht",
			"select_multiple_dates": "selecteer meerdere datums om dezelfde vlucht te kopiëren met dezelfde locatie, ballon, piloot en crew",
			"selected_dates": "geselecteerde datums",
			"no_tags": "geen tags",
			"copy": "kopieer"
		},
		"PILOTANDCREW": {
			"selected": "geselecteerd",
			"most_used": "meest gebruikt",
			"this_is_the_most_used_pilot_and_crew": "dit is de meest gebruikte piloot en crew voor deze ballon",
			"accept": "accepteer",
			"all_pilots_and_crew": "alle piloten en crew",
			"select": "kies"
		}
	},
	"MANIFEST": {
		"fuel_consumption": "brandstof verbruik",
		"fuel_needed": "brandstof nodig",
		"fuel_available": "brandstof beschikbaar",
		"load_chart_and_passenger_list": "Lift Berekening en Passagierslijst",
		"passenger_list": "Passagierslijst",
		"weather_conditions": "Weersomstandigheden",
		"fuel_calculations": "Brandstof berekening",
		"load_chart": "Lift berekening",
		"after_flight": "Na de vlucht",
		"visible_defects": "Zichtbare defecten",
		"yes_no": "Ja/Nee",
		"date": "Datum",
		"signature": "Handtekening",
		"defect": "Defect",
		"action": "Actie",
		"certificate": "Certficaat",
		"authority": "Authoriteit",
		"initials": "Initialen",
		"confirmation": "Dit bevestigt dat de gespecifieerde werken, tenzij anders vermeld, werden uitgevoerd in overeenstemming met PART-ML en dat hierdoor het luchtvaartuig beschouwd wordt als klaar voor vrijgave voor gebruik."
	},
	"LOCATIONS": {
		"name": "naam",
		"description": "beschrijving",
		"region": "regio",
		"address": "adres",
		"postalCode": "postcode",
		"city": "stad",
		"country": "land",
		"height": "hoogte",
		"maximum height": "maximum hoogte",
		"locations": "opstijglocaties",
		"confirm_message": "ben je zeker om te verwijderen?",
		"confirm": "bevestigen",
		"update": "wijzig",
		"delete": "verwijder",
		"regions": "regio's",
		"save": "bewaar",
		"add_location": "voeg opstijglocatie toe",
		"add_region": "voeg regio toe",
		"hot_airfield": "hot airfield",
		"coordinates": "coördinaten",
		"geolocate": "lokaliseer",
		"no_results": "geen resultaten",
		"contact": "contact",
		"takeoff_location": "opstijglocatie",
		"takeoff_location_subtitle": "De coördinaten van de opstijglocatie worden gebruikt voor de weersvoorspelling, traject en lift berekening.",
		"meetingpoint": "verzamellocatie",
		"meetingpoint_subtitle": "Het adres van de verzamellocatie wordt gecommuniceerd aan de passagiers (UP).",
		"same_as_takeoff": "zelfde als opstijglocatie",
		"meetingpointAddress": "adres verzamellocatie",
		"CONTACT": {
			"name": "naam",
			"type": "type",
			"comment": "opmerkingen",
			"phone": "telefoon",
			"email": "email",
			"when_to_contact": "wanneer te contacteren",
			"how_to_contact": "hoe te contacteren",
			"NOMSG": "contactpersoon moet niet elke vlucht verwittigd worden",
			"MAIL": "email",
			"SMS": "sms",
			"mail": "tijdens het voorbereiden van de vlucht, zal je de contactpersoon een email kunnen sturen",
			"sms": "tijdens het voorbereiden van de vlucht, zal je de contactpersoon een sms kunnen sturen",
			"no_message": "De contact persoon zal je niet kunnen verwittigen via email of sms",
			"add_sms_template": "voeg sms template toe",
			"add_mail_template": "voeg email template toe",
			"mail_template": "email template",
			"sms_template": "sms template",
			"warning_email": "je hebt nog geen mail gestuurd naar de contactpersoon",
			"warning_sms": "je hebt nog geen sms gestuurd naar de contactpersoon"
		}
	},
	"FORECAST": {
		"no_mobile": "Meteo is momenteel niet beschikbaar op kleine schermen",
		"select_favorite": "Selecteer een locatie als favoriet om de meteoblue voorspelling te tonen",
		"no_forecast": "Er is geen meteo beschikbaar voor deze locatie. Controleer latitude en longitude",
		"remove_favorite": "verwijder favoriet",
		"mark_favorite": "markeer favoriet",
		"favorite_description": "deze locatie is je favoriete locatie. Op het dashboard tonen we de weersvoorpelling voor deze locatie",
		"non_favorite_description": "deze locatie is geen favoriete locatie. Markeer ze als favoriet om de weersvoorspelling te tonen op het dashboard",
		"meteo": "meteo",
		"time": "uur",
		"summary": "samenvatting"
	},
	"REGIONS": {
		"name": "naam",
		"locations": "opstijglocaties",
		"no_locations_yet": "nog geen locaties",
		"name_required": "naam verplicht",
		"change_color": "klik hier om de regiokleur te veranderen",
		"reset_color": "reset kleur",
		"confirm_color": "bevestig kleur",
		"cancel_color": "annuleer kleur"
	},
	"EVENTS": {
		"new_event": "nieuw event",
		"information": "voeg een event toe op datum of laad de feestdagen",
		"new_crew_event": "nieuwe afspraak",
		"crew_information": "voeg een datum toe waarop je niet beschikbaar bent",
		"holidays": "feestdagen",
		"load_public_holidays_of_year_from_country": "laad feestdagen van {0} voor {1}",
		"date": "datum",
		"description": "beschrijving",
		"save": "bewaar",
		"cancel": "annuleer",
		"required": "verplicht",
		"crew": "crew",
		"NO": "niet beschikbaar",
		"YES": "beschikbaar",
		"MAYBE": "misschien beschikbaar",
		"UNK": "beschikbaarheid onbekend"
	},
	"SETTINGS": {
		"user_default_language": "standaard gebruikerstaal",
		"submit": "bewaar",
		"preferences_language": "taal",
		"preferences_sms": "sms",
		"smsTitle": "titel",
		"smsTemplate": "template",
		"pilotInvitationSms": "SMS aan piloot",
		"example": "voorbeeld",
		"update": "wijzig",
		"crewInvitationSms": "SMS aan crew",
		"passengerInvitationSms": "SMS aan passagiers",
		"cancelSms": "annulatie SMS",
		"reminderSms": "herinnering SMS",
		"locationContactSms": "SMS aan contactpersoon",
		"emailTitle": "titel",
		"no_emailTitle": "geen titel",
		"emailType": "type",
		"emailTo": "email aan",
		"emailSubject": "onderwerp",
		"emailBody": "bericht",
		"emailAttachments": "bijlages",
		"emailTest": "email test",
		"mailFromEmail": "email account adres",
		"mailFromName": "email account naam",
		"mailReplyToEmail": "email account reply to adres",
		"mailAccountantName": "email naam boekhouder",
		"mailAccountantEmail": "email adres boekhouder",
		"bookingConfirmEmail": "Email bevestiging reservatie",
		"bookingFlightCancelEmail": "Email einde vlucht",
		"bookingFlightConfirmEmail": "Email passagiers van een vlucht",
		"crewFlightEmail": "Email vlucht details aan crew",
		"crewOtpEmail": "Email login instructies",
		"pilotFlightEmail": "Email vlucht details aan piloot",
		"flight_email": "Email vlucht details",
		"locationContactEmail": "Email locatie contact",
		"flight_email_passengers": "Email passagiers",
		"flight_sms_passengers": "SMS passagiers",
		"load_default": "laad een standaard tekst",
		"passenger_categories": "passagierscategorieen",
		"category_name": "naam",
		"category_description": "beschrijving",
		"category_child": "kind",
		"category_price": "prijs",
		"category_default": "standaard",
		"delete": "verwijder",
		"mobile_questions": "passagiersvragen (mobiel)",
		"question": "vraag",
		"kind": "type",
		"active": "actief",
		"title": "titel",
		"description": "beschrijving",
		"language": "taal",
		"texts": "teksten",
		"name": "naam",
		"address": "adres",
		"email": "email",
		"weight": "gewicht",
		"luggage": "bagage",
		"child": "kind",
		"accompany": "begeleiding",
		"pay": "betaling",
		"signature": "handtekening",
		"optional": "optioneel",
		"mandatory": "verplicht",
		"order": "volgorde",
		"titles": "vragen",
		"descriptions": "bijhorende teksten",
		"yes": "ja",
		"no": "neen",
		"nl": "nederlands",
		"fr": "frans",
		"en": "engels",
		"de": "duits",
		"id": "id",
		"download_sample": "download voorbeeld",
		"drag_and_drop_file_here": "drag en drop je bestand hier",
		"browse_for_file": "kies je bestand",
		"operator_settings": "operator instellingen",
		"operator_backup_and_sync": "backup en sync",
		"operator_bookings": "reservatie instellingen",
		"operator_flights": "vlucht instellingen",
		"operator_crew": "crew instellingen",
		"operator_myflight": "UP (Passengers App) instellingen",
		"export": "export",
		"you_can_activate_a_daily_backup": "je kan een dagelijkse export in HTML toegestuurd krijgen op een specifiek e-mail adres. Van zodra je de export activeert, ontvang je meteen een export op het opgegeven e-mail adres, daarna elke dag rond 04u.",
		"daily_backup": "dagelijkse export",
		"backup_email": "E-mail adres",
		"only_update_when_inactive": "je kan het e-mail adres enkel veranderen als de dagelijkse export niet actief is",
		"exported_files": "geëxporteerde bestanden",
		"bookings": "alle open reservaties",
		"flights": "alle geplande vluchten",
		"vouchers": "alle cadeau bonnen",
		"flights_bookings_passengers": "alle geplande vluchten met de reservaties en passagiers van de vlucht",
		"calendarUrl": "kalender ICS URL",
		"you_can_subscribe_to_all_flights_in_your_personal_calendar": "je kan je abonneren op alle aankomende vluchten in je persoonlijke (smartphone) kalender",
		"geticsurl": "genereer ICS URL",
		"readthedocs": "lees de documentatie",
		"crew": {
			"title": "Crew",
			"shareBookings": "crew kan details zien van de reservaties in het dashboard",
			"shareBookingsHint": "als dit niet aangevinkt is, kan de crew enkel de vluchten zien waarvoor ze ingeschreven zijn, maar niet de details van de reservaties",
			"allowFlightSelection": "crew kan vluchten selecteren in de kalender",
			"allowFlightSelectionHint": "als dit niet aangevinkt is, kan de crew enkel de beschikbaarheid aangeven in de kalender, maar niet zelf de vluchten selecteren"
		},
		"booking": {
			"title": "reservaties",
			"childAge": "leeftijd kind",
			"childAgeHint": "Elke passagier met deze leeftijd of jonger, zal automatische aangeduid worden als kind",
			"disabledPaymentTypes": "uitgeschakelde betaalmethodes",
			"disabledPaymentTypesHint": "de geselecteerde betaalmethodes zijn NIET beschikbaar",
			"none_of_the_paymentTypes_are_disabled": "geen enkele betaalmethode is uitgeschakeld",
			"disabledPaymentTypesSave": "bewaar betaalmethodes",
			"sources": "bronnen"
		},
		"flight": {
			"title": "vluchten",
			"sunriseOffset": "zonsopgang offset",
			"sunriseOffsetHint": "het uur van de vlucht wordt berekend op basis van zonsopgang min het aantal offset minuten, afgerond naar beneden op het half uur.",
			"sunsetOffset": "zonsondergang offset",
			"sunsetOffsetHint": "het uur van de vlucht wordt berekend op basis van zonsondergang min het aantal offset minuten, afgerond naar beneden op het half uur.",
			"meetingTimeOffset": "verzameluur offset (deze feature is nog niet beschibaar)",
			"meetingTimeOffsetHint": "het uur van afspraak voor de vlucht wordt berekend op basis van het vlucht uur min het aantal offset minuten. Indien 0, dan wordt het uur van afspraak niet getoond.",
			"sunrise": "zonsopgang",
			"sunset": "zonsondergang",
			"hourMeeting": "verzameluur",
			"hour": "vlucht uur",
			"save": "bewaar offsets"
		},
		"myflight": {
			"title": "Quick Release UP (Passengers App)",
			"autoConfirm": "automatisch bevestigen",
			"autoConfirmHint": "als dit aangevinkt is, dan moet de operator de vlucht niet meer bevestigen",
			"endOfSeason": "einde seizoen",
			"endOfSeasonHint": "als dit aangevinkt is, dan kunnen de passagiers geen vluchten meer aanvragen via UP",
			"flights": "Welke vluchten kan een passagier kiezen via UP?",
			"ALL": "alle vluchten",
			"REGIONAL": "regionale vluchten (vluchten die plaatsvinden in de regio van de reservatie)",
			"NONE": "geen vluchten (passagiers kunnen enkel hun namen en gewichten doorgeven)",
			"parameters": "parameters",
			"branding": "branding",
			"logo": "logo",
			"logoHint": "upload een logo dat getoond wordt in de UP app",
			"drag_and_drop_file_here": "drag en drop je .jpg of .png bestand hier (max 500kB)",
			"colors": "kleuren",
			"colorsHint": "klik op elke kleur om ze aan te passen aan je eigen huisstijl. Je kan een kleur kiezen uit het palette van je logo in de pop-up",
			"example": "voorbeeld",
			"reset": "reset kleuren",
			"save": "bewaar kleuren en logo",
			"primary_background_color": "achtergrond kleur van de app",
			"secondary_background_color": "achtergrond kleur van de tegels",
			"primary_foreground_color": "kleur van de tekst",
			"secondary_foreground_color": "kleur van de informatie tekst",
			"invalid_file_type_or_size": "ongeldig bestandstype of te groot bestand"
		},
		"cao": {
			"title": "CAO"
		},
		"sms": {
			"footerEnabled": "voettekst toevoegen aan SMS",
			"footerEnabledHint": "als dit aangevinkt is, dan wordt de voettekst niet toegevoegd aan de uitnodigings SMS"
		}
	},
	"REPORTS": {
		"flights": "vluchten",
		"bookings_open": "open reservaties",
		"bookings": "nieuwe reservaties",
		"vouchers": "bonnen",
		"payments": "betalingen",
		"sms": "SMS"
	},
	"MAIL": {
		"hide": "verberg details",
		"show": "toon details",
		"sent": "verzonden"
	},
	"SUBSCRIPTION": {
		"sms_available": "beschikbaar in abonnement",
		"sms_sent": "verzonden SMS",
		"pct_sms_used": "% van je SMS zijn verbruikt",
		"sms_info": "Neem contact op met ons om bijkomende SMS te bestellen van zodra je meer dan 95% verbruikt hebt",
		"sms_warning": "Je hebt 95% of meer van je SMS tegoed verbruikt.",
		"order_sms": "Bestel nu bijkomend SMS tegoed",
		"sms_ignore_warning": "Verberg meldingen over het SMS verbruik",
		"pct_plan_used": "% van je seats zijn verbruikt",
		"plan_seats_used": "totale capaciteit ballonnen",
		"plan_valid_until": "geldig tot",
		"plan_info": "Neem contact op met ons om over te schakelen naar een hoger plan als de beschikbare capaciteit niet meer volstaat of om je huidig abonnement te vernieuwen"
	},
	"TAGS": {
		"delete": "verwijder tag",
		"enabled": "ingeschakeld",
		"enable_tag": "tag inschakelen",
		"disabled": "uitgeschakeld",
		"disable_tag": "tag uitschakelen",
		"save": "tag opslaan",
		"new_tag": "nieuwe tag aanmaken",
		"tag_name": "tag naam",
		"tag_type": "tag type",
		"select_tag_color": "selecteer kleur",
		"color": "kleur",
		"tag_already_added": "tag is al toegevoegd",
		"edit": "wijzig tag",
		"flight_tags": "vlucht tags",
		"tags": "tags",
		"show_on_dashboard": "toon op het dashboard"
	}
}
