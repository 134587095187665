<!-- GOOD -->
<div *ngIf="balloon?.isCurrent > NOTIFY" style="display: flex; flex-direction: row; align-items: center; gap: 5px">
	<mat-icon style="color: #1faa00">gpp_good</mat-icon>
	<div style="flex: 1; word-break: break-word">{{ balloon?.name }}</div>
</div>

<!-- BAD -->
<div
	*ngIf="balloon?.isCurrent <= 0"
	style="display: flex; flex-direction: row; align-items: center; gap: 5px"
	matTooltip="{{ 'BALLOONS.error_not_current' | translate | capitalize }}"
>
	<mat-icon class="mat-error-icon">gpp_bad</mat-icon>
	<div style="flex: 1; word-break: break-word">{{ balloon?.name }}</div>
</div>

<!-- UGLY -->
<div
	*ngIf="balloon?.isCurrent <= NOTIFY && balloon?.isCurrent > 0"
	style="display: flex; flex-direction: row; align-items: center; gap: 5px"
	matTooltip="{{ 'BALLOONS.warning_not_current' | translate | capitalize }}"
>
	<mat-icon style="color: #ffca28">gpp_maybe</mat-icon>
	<div style="flex: 1; word-break: break-word">{{ balloon?.name }}</div>
</div>
