import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BookingService } from 'src/app/core/services/booking/booking.service';
import { SnackBar } from 'src/app/core/utility/snackBar';

@Component({
	selector: 'app-booking-card',
	templateUrl: './booking-card.component.html',
	styleUrls: ['./booking-card.component.scss'],
})
export class BookingCardComponent implements OnInit {
	@Input() bookingId: any;
	@Input() draggable: boolean;
	@Input() dashboard: boolean;

	booking: any;
	bookingSub: Subscription;

	constructor(private bookingService: BookingService, private router: Router, private snackBar: SnackBar) {}

	ngOnInit(): void {
		if (this.bookingId) {
			this.bookingSub = this.bookingService.getBooking(this.bookingId).valueChanges.subscribe(
				(result) => {
					this.booking = result.data.booking;
				},
				(err) => this.snackBar.openSnackBarError(err.message)
			);
		}
	}

	ngOnDestroy(): void {
		if (this.bookingSub) this.bookingSub.unsubscribe();
	}

	edit() {
		this.navigateToBooking(this.booking.id);
	}

	navigateToBooking(id?) {
		let url = '/booking';
		if (id) {
			url = url + '/' + id;
		}
		if (this.dashboard) {
			url = `${url}/dashboard`;
		}
		this.router.navigate([url]);
	}
}
