<!-- sms -->
<mat-icon *ngIf="!smsLatest" style="color: #f5f5f5"><span class="material-symbols-outlined">sms</span></mat-icon>
<mat-icon
	*ngIf="smsLatest && !smsUnread && smsLatest.direction === 'OUT' && smsLatest.status === 'unknown'"
	style="color: lightgrey"
	[matTooltip]="tooltip"
	>sms</mat-icon
>
<mat-icon
	*ngIf="smsLatest && !smsUnread && smsLatest.direction === 'OUT' && smsLatest.status === 'sent'"
	style="color: #ffca28"
	[matTooltip]="tooltip"
	>sms</mat-icon
>
<mat-icon
	*ngIf="smsLatest && !smsUnread && smsLatest.direction === 'OUT' && smsLatest.status === 'delivered'"
	style="color: #1faa00"
	[matTooltip]="tooltip"
	>sms</mat-icon
>
<mat-icon
	*ngIf="smsLatest && !smsUnread && smsLatest.direction === 'OUT' && smsLatest.status === 'failed'"
	color="warn"
	[matTooltip]="tooltip"
	>sms</mat-icon
>
<mat-icon
	*ngIf="smsLatest && !smsUnread && smsLatest.direction === 'IN'"
	style="color: #1faa00"
	class="material-symbols-outlined"
	[matTooltip]="tooltip"
	>sms</mat-icon
>
<mat-icon
	*ngIf="smsLatest && smsUnread && smsLatest.direction === 'IN'"
	color="accent"
	[matTooltip]="tooltip"
	[matBadge]="smsUnread"
	matBadgeColor="warn"
	>sms</mat-icon
>
