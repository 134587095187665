import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
	AddCrewToFlightFromCalendarDocument,
	CalendarDocument,
	ChangeFlightStatusDocument,
	CopyFlightDocument,
	CopyWeatherDocument,
	DeleteFlightDocument,
	Flight,
	FlightCountersDocument,
	FlightCrewInput,
	FlightInput,
	FlightPeriod,
	FlightPeriodsDocument,
	FlightStatus,
	GetBookingFlightDocument,
	GetCertificateFlightDocument,
	GetFlightDashboardDocument,
	GetFlightDocument,
	GetFlightLogsDocument,
	GetFlightsListDocument,
	GetFlightsListFromDateCrewDocument,
	GetFlightsListFromDateDocument,
	GetFlightsListSummaryDocument,
	GetFlightsOfDashboardDocument,
	GetHistoricalFlightsListDocument,
	GetHistoricalFlightsListSummaryDocument,
	GetMobileFlightDocument,
	GetScheduledFlightsDocument,
	GetTagsOfTypeWithCountersDocument,
	GetUpcomingFlightDocument,
	GetUpcomingFlightsDocument,
	LandingInput,
	OrderByFlight,
	OrderDirection,
	PilotSignOffInput,
	SaveFlightCrewDocument,
	SaveFlightDocument,
	SaveFlightLandingDocument,
	SavePilotSignOffDocument,
	Tag,
	TagType,
	ToggleFlightVisibilityDocument,
	UpdateFlightCommentsDocument,
	UpdateFlightTagsDocument,
} from '../../graphql/generated/gen-types';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class FlightService {
	currentUser;

	constructor(private apollo: Apollo, private authenticationService: AuthenticationService) {
		this.authenticationService.currentUser.subscribe((user) => {
			this.currentUser = user;
		});
	}

	public get(flightId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetFlightDocument,
			variables: {
				flightId,
			},
			fetchPolicy: 'cache-and-network',
		});
	}

	public getFromNetwork(flightId: string): Observable<any> {
		return this.apollo.query({
			query: GetFlightDocument,
			variables: {
				flightId,
			},
			fetchPolicy: 'network-only',
		});
	}

	public getDashboard(flightId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetFlightDashboardDocument,
			variables: {
				flightId,
			},
		});
	}

	public getFlightLogs(flightId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetFlightLogsDocument,
			variables: {
				flightId,
			},
		});
	}

	public getBookingFlight(flightId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetBookingFlightDocument,
			variables: {
				flightId,
			},
		});
	}

	public getMobileFlight(flightId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetMobileFlightDocument,
			variables: {
				flightId,
			},
		});
	}

	public getCertificateFlight(flightId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetCertificateFlightDocument,
			variables: {
				flightId,
			},
		});
	}

	public getFlightsOfDashboard(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetFlightsOfDashboardDocument,
		});
	}

	public getUpcomingFlights(
		orderBy?: OrderByFlight,
		orderDirection?: OrderDirection,
		limit?: number,
		cursor?: string,
		filter?: string
	): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetUpcomingFlightsDocument,
			variables: {
				orderBy,
				orderDirection,
				limit,
				cursor,
				filter,
			},
			fetchPolicy: 'network-only',
		});
	}

	public getFlightsList(limit?: number, cursor?: string, filter?: string[], filterRange?: any): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetFlightsListDocument,
			variables: {
				limit,
				cursor,
				filter,
				filterRange,
			},
			fetchPolicy: 'network-only',
		});
	}

	public getFlightsListSummary(limit?: number, cursor?: string, filter?: string[], filterRange?: any): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetFlightsListSummaryDocument,
			variables: {
				limit,
				cursor,
				filter,
				filterRange,
			},
			fetchPolicy: 'network-only',
		});
	}

	public getHistoricalFlightsList(limit?: number, cursor?: string, filter?: string[], filterRange?: any): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetHistoricalFlightsListDocument,
			variables: {
				limit,
				cursor,
				filter,
				filterRange,
			},
			fetchPolicy: 'network-only',
		});
	}

	public getHistoricalFlightsListSummary(limit?: number, cursor?: string, filter?: string[], filterRange?: any): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetHistoricalFlightsListSummaryDocument,
			variables: {
				limit,
				cursor,
				filter,
				filterRange,
			},
			fetchPolicy: 'network-only',
		});
	}

	public getFlightsListFromDate(date: any): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetFlightsListFromDateDocument,
			variables: {
				date,
			},
		});
	}

	public getFlightsListFromDateCrew(date: any): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetFlightsListFromDateCrewDocument,
			variables: {
				date,
			},
		});
	}

	public getUpcomingFlight(flightId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetUpcomingFlightDocument,
			variables: {
				flightId,
			},
		});
	}

	public getScheduledFlights(
		orderBy?: OrderByFlight,
		orderDirection?: OrderDirection,
		limit?: number,
		cursor?: string,
		filter?: string
	): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetScheduledFlightsDocument,
			variables: {
				orderBy,
				orderDirection,
				limit,
				cursor,
				filter,
			},
			fetchPolicy: 'network-only',
		});
	}

	public flightCounters(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: FlightCountersDocument,
			fetchPolicy: 'no-cache',
		});
	}
	public flightPeriods(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: FlightPeriodsDocument,
			fetchPolicy: 'no-cache',
		});
	}

	public saveFlight(flightInput: FlightInput, flightId: string): Observable<any> {
		if (flightId) {
			// in case of EDIT then refetch the flight
			return this.apollo
				.mutate<any>({
					mutation: SaveFlightDocument,
					variables: {
						flightInput,
						flightId,
					},
					refetchQueries: [
						{
							query: GetFlightDocument,
							variables: {
								flightId,
							},
						},
						{
							query: GetFlightsOfDashboardDocument,
						},
					],
				})
				.pipe(map((result: any) => result.data));
		} else {
			// in case of NEW then just save the flight
			return this.apollo
				.mutate<any>({
					mutation: SaveFlightDocument,
					variables: { flightInput, flightId },
					refetchQueries: [
						{
							query: GetFlightsOfDashboardDocument,
						},
					],
				})
				.pipe(map((result: any) => result.data));
		}
	}

	public delete(flight: Flight): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: DeleteFlightDocument,
				variables: {
					flightId: flight.id,
				},
				update(cache, { data }) {
					//Delete from cache
					const normalizedId = cache.identify(flight);
					cache.evict({ id: normalizedId });
					cache.gc();
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public toggleVisibility(flightId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: ToggleFlightVisibilityDocument,
				variables: {
					flightId,
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public changeStatus(flightId: string, flightStatus: FlightStatus): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: ChangeFlightStatusDocument,
				variables: {
					flightId,
					flightStatus,
				},
				refetchQueries: [FlightCountersDocument],
			})
			.pipe(map((result: any) => result.data));
	}

	public saveFlightCrew(flightCrewInput: FlightCrewInput[], flightId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SaveFlightCrewDocument,
				variables: {
					flightCrewInput,
					flightId,
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public addCrewToFlightFromCalendar(flightCrewInput: FlightCrewInput, flightId: string, year?: number, month?: number): Observable<any> {
		let refetchQueries = [];

		if (year && month) {
			refetchQueries.push([
				{
					query: CalendarDocument,
					variables: {
						year,
						month,
					},
				},
			]);
		}
		return this.apollo
			.mutate<any>({
				mutation: AddCrewToFlightFromCalendarDocument,
				variables: {
					flightCrewInput,
					flightId,
				},
				refetchQueries,
			})
			.pipe(map((result: any) => result.data));
	}

	public savePilotSignOff(pilotSignOffInput: PilotSignOffInput, flightId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SavePilotSignOffDocument,
				variables: {
					pilotSignOffInput,
					flightId,
				},
				refetchQueries: [
					{
						query: GetMobileFlightDocument,
						variables: {
							flightId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public saveFlightLanding(landingInput: LandingInput, flightId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SaveFlightLandingDocument,
				variables: {
					landingInput,
					flightId,
				},
				refetchQueries: [
					{
						query: GetMobileFlightDocument,
						variables: {
							flightId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public copyWeather(sourceFlightId: string, destinationFlightIds: string[]): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: CopyWeatherDocument,
				variables: {
					sourceFlightId,
					destinationFlightIds,
				},
			})
			.pipe(map((result: any) => result.data));
	}
	public copyFlight(sourceFlightId: string, destinationDates: string[]): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: CopyFlightDocument,
				variables: {
					sourceFlightId,
					destinationDates,
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public updateFlightComments(flightId: string, comments: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateFlightCommentsDocument,
				variables: {
					flightId: flightId,
					comment: comments,
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public updateFlightTags(flightId: string, tags: Tag[]): Observable<any> {
		const tagIds: string[] = tags.map((tag) => tag.id);
		return this.apollo
			.mutate<any>({
				mutation: UpdateFlightTagsDocument,
				variables: {
					flightId: flightId,
					tags: tagIds,
				},
				refetchQueries: [
					{
						query: GetTagsOfTypeWithCountersDocument,
						variables: {
							tagType: TagType.Flight,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	updateCacheSkeyesForecast(flightId, skeyesForecast) {
		// Get existing flight data from cache
		const data: any = this.apollo.client.readQuery({
			query: GetFlightDocument,
			variables: { flightId },
		});

		// Create a new object that includes the old flight data and the new forecast
		const updatedFlight = { ...data.flight, skeyesForecast };

		// Write the updated flight back to the cache
		this.apollo.client.writeQuery({
			query: GetFlightDocument,
			variables: { flightId },
			data: { flight: updatedFlight },
		});
	}
}
