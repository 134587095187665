import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import dayjs from 'dayjs';
import { LocationType, OperatorSettingsManifest, PaymentType, TenantCurrency } from '../graphql/generated/gen-types';
import { TranslateCustomService } from '../utility/translate-custom.service';

import { addMadouBetaalOverzicht } from './manifest-madou';
import { addMontgolfiereBetaalOverzicht } from './manifest-montgolfiere';
import { addBallonschateaudoexBetaalOverzicht } from './manifest-ballonschateaudoex';
import { TenantSettingService } from '../services/tenantSetting/tenant-setting.service';
import { addAdballonDetailPassagiers } from './manifest-adballon';

@Injectable({
	providedIn: 'root',
})
export class ManifestService {
	domain: string;
	margin_top = 15;
	margin_left = 15;
	column_width = 85;
	margin_blocks = 3;
	fillinStyle = null;
	montgolfiere: boolean = false;
	madou: boolean = false;
	stepin: boolean = false;
	culiair: boolean = false;
	ballonschateaudoex: boolean = false;
	adballon: boolean = false;

	constructor(private translateCustom: TranslateCustomService, private tenantSettingService: TenantSettingService) {}

	async generatePDF(tenant, flight, manifestOptions?: OperatorSettingsManifest, currency?: TenantCurrency) {
		this.stepin = tenant.includes('stepin');
		this.montgolfiere = tenant.includes('montgolfiere');
		this.madou = tenant.includes('madou');
		this.ballonschateaudoex = tenant.includes('ballonschateaudoex');
		this.culiair = tenant.includes('culiair');
		this.adballon = tenant.includes('Ad Ballon'); 
		
		if (this.montgolfiere) {
			this.fillinStyle = { lineColor: 'green', minCellWidth: 12 };
		}

		const Yfuel = 111;
		const Yfooter = 220;
		const font = 'Helvetica';
		const fontSizeTitle = 12;
		const fontSizeFootnote = 6;
		const document = this.mapFlightManifest(flight);
		const title = dayjs(flight.date).format('YYYYMMDD') + '_' + flight.period + '_' + document.data.header.balloon + '.pdf';
		const doc = new jsPDF(),
			margins = {
				top: this.margin_top,
				left: this.margin_left,
			};
		// Title
		doc.setFontSize(14);
		doc.text('OPERATIONAL FLIGHT PLAN', 105, this.margin_top, {
			align: 'center',
		});
		// Header
		const headerBody = [
			[
				document.labels.header.operator,
				{
					content: tenant,
					colSpan: 2,
				},
				document.labels.header.docsn,
				'', // document.data.header.docsn,
			],
			[
				document.labels.header.balloon,
				{
					content: document.data.header.balloon,
					colSpan: 2,
				},
				document.labels.header.balloonType,
				document.data.header.balloonType,
			],
			[
				document.labels.header.pic,
				{
					content: document.data.header.pic,
					colSpan: 2,
				},
				document.labels.header.date,
				document.data.header.date,
			],

			[
				document.labels.header.takeoffLocation,
				{
					content: document.data.header.takeoffLocation,
					colSpan: 2,
				},
				document.labels.header.landingLocation,
				{
					content: document.data.header.landingLocation,
					styles: document.data.header.landingLocation === '' && this.montgolfiere ? { lineColor: 'green', minCellWidth: 12 } : null,
				},
			],
			[
				document.labels.header.takeoffTime,
				document.data.header.flightHour,
				{
					content: document.data.header.takeoffTime,
					styles: this.fillinStyle,
				},
				document.labels.header.landingTime,
				{
					content: document.data.header.landingTime,
					styles: document.data.header.landingTime === '' && this.montgolfiere ? { lineColor: 'green', minCellWidth: 12 } : null,
				},
			],
		];
		// Flight crew ico Ballonschateauxdoex
		if (this.ballonschateaudoex) {
			headerBody.push([
				document.labels.header.crew,
				{
					content: document.data.header.crew,
					colSpan: 4,
				},
			]);
		}

		// Flight comment ico Madou
		if (this.madou && document.data.header.flightComments) {
			headerBody.push([
				{
					content: document.data.header.flightComments,
					colSpan: 5,
					styles: { fontSize: 8 },
				},
			]);
		}

		// Flight comment ico Madou
		if (this.madou && flight.locationType === LocationType.Priv) {
			headerBody.push([
				{
					content: `${document.data.header.takeoffLocation}: ${document.data.header.takeoffAddress}`,
					colSpan: 5,
					styles: { fontSize: 8 },
				},
			]);
		}

		autoTable(doc, {
			head: [
				[
					{
						content: document.labels.header.load_chart_and_passenger_list,
						colSpan: 5,
					},
				],
			],
			body: headerBody,
			startY: this.margin_top + 3,
			theme: 'grid',
			styles: { halign: 'left', valign: 'middle', fontSize: 9, cellPadding: 1 },
			margin: { left: this.margin_left },
			tableWidth: 2 * this.column_width + this.margin_blocks,
			headStyles: {
				fillColor: '#d3d3d3',
				textColor: '#000000',
				fontSize: 14,
				halign: 'center',
			},
		});
		let Yheader = (doc as any).lastAutoTable.finalY;

		// Passenger List
		const passengers = [];
		let disabilityCount = 0;
		let childCount = 0;
		let transportCount = 0;
		if (!this.madou) {
			document.data.passengers.forEach((p) => {
				let paxName = p.age ? p.name + ' ' + `(${p.age}y)` : p.name;
				if (p.child) childCount += 1;
				if (p.disability) disabilityCount += 1;
				if (p.transport) transportCount += 1;
				passengers.push([
					{ content: p.id, styles: { fontSize: 8, halign: 'center' } },
					{
						content: paxName,
						styles: { fontSize: 8 },
					},
					{ content: `${p.weight} (+7kg)`, styles: { fontSize: 8, halign: 'center' } },

					{
						content: p.child ? 'C' : '',
						styles: { fontSize: 8, fontStyle: p.child ? 'bold' : 'normal', halign: 'center' },
					},
					{
						content: p.disability ? 'A' : '',
						styles: { fontSize: 8, fontStyle: p.disability ? 'bold' : 'normal', halign: 'center' },
					},
					{
						content: p.transport ? 'T' : '',
						styles: { fontSize: 8, fontStyle: p.transport ? 'bold' : 'normal', halign: 'center' },
					},
				]);
			});
		} else {
			document.data.passengers.forEach((p) => {
				let category = '';
				if (p.category) category = p.category.name;
				if (p.child) category = '(C) ' + category;
				if (p.disability) category = '(A) ' + category;
				if (p.transport) category = '(T) ' + category;
				passengers.push([
					{ content: p.id, styles: { halign: 'center' } },
					{
						content: p.name,
						styles: { fontSize: 8 },
					},
					{ content: `${p.weight} (+7kg)`, styles: { fontSize: 8, halign: 'center' } },
					{
						content: category,
						styles: { fontSize: 8, halign: 'center' },
						colSpan: 3
					},
				]);
			});
		}
		passengers.push([
			{
				content: 'Totals',
				colSpan: 2,
				styles: { halign: 'right', fontStyle: 'bold', cellPadding: 1 },
			},
			{
				content: document.data.passengers_total_weight + ' kg',
				colSpan: 1,
				styles: { halign: 'center', fontStyle: 'bold', cellPadding: 1 },
			},
			{
				content: childCount === 0 ? '' : `${childCount}C`,
				colSpan: 1,
				styles: { halign: 'center', fontStyle: 'bold', cellPadding: 1 },
			},
			{
				content: disabilityCount === 0 ? '' : `${disabilityCount}A`,
				colSpan: 1,
				styles: { halign: 'center', fontStyle: 'bold', cellPadding: 1 },
			},
			{
				content: transportCount === 0 ? '' : `${transportCount}T`,
				colSpan: 1,
				styles: { halign: 'center', fontStyle: 'bold', cellPadding: 1 },
			},
		]);
		let cellPaddingPassengers = 1;
		if (passengers.length > 19) cellPaddingPassengers = 0.2;
		autoTable(doc, {
			head: [[document.labels.header.passenger_list]],
			startY: Yheader + this.margin_blocks,
			theme: 'grid',
			margin: { left: this.margin_left },
			tableWidth: this.column_width,
			headStyles: {
				fillColor: '#d3d3d3',
				textColor: '#000000',
				fontSize: 12,
				halign: 'center',
			},
		});
		autoTable(doc, {
			head: [
				[
					'No',
					'NAME',
					'WEIGHT',
					{ content: '(C)', styles: { minCellWidth: 6, halign: 'center' } },
					{ content: '(A)', styles: { minCellWidth: 6, halign: 'center' } },
					{ content: '(T)', styles: { minCellWidth: 6, halign: 'center' } },
				],
			],
			body: passengers,
			startY: Yheader + this.margin_blocks + 8,
			theme: 'grid',
			styles: {
				halign: 'left',
				valign: 'middle',
				fontSize: 9,
				cellPadding: cellPaddingPassengers,
			},
			margin: { left: this.margin_left },
			tableWidth: this.column_width,
			headStyles: { fillColor: '#d3d3d3', textColor: '#000000', fontSize: 9 },
		});
		const Ypassengers = (doc as any).lastAutoTable.finalY;
		doc.setFontSize(fontSizeFootnote);
		doc.text('(C) CHILD, (A) ASSISTANCE, (T) TRANSPORT', this.margin_left, Ypassengers + 3);

		// ALL - pilotDeclaration
		if (manifestOptions?.pilotDeclaration) {
			this.addPilotDeclaration(doc, manifestOptions);
		}

		// Title Weather List
		autoTable(doc, {
			head: [
				[
					{
						content: document.labels.header.weather_conditions,
						colSpan: 4,
					},
				],
			],
			body: [
				[document.labels.weather.source, document.data.weather.source, 'DATE', document.data.weather.date],
				[document.labels.weather.visual, document.data.weather.visual, document.labels.weather.clouds, document.data.weather.clouds],
				['TEMP', document.data.weather.temp, document.labels.weather.qfe, document.data.weather.qfe],
				[
					{
						content: document.data.weather.windSummary,
						colSpan: 4,
						styles: { fontSize: 8 },
					},
				],
			],
			startY: Yheader + this.margin_blocks,
			theme: 'grid',
			styles: { halign: 'left', valign: 'middle', fontSize: 9, cellPadding: 1 },
			margin: {
				left: this.margin_left + this.column_width + this.margin_blocks,
			},
			tableWidth: this.column_width,
			headStyles: {
				fillColor: '#d3d3d3',
				textColor: '#000000',
				fontSize: 12,
				halign: 'center',
			},
		});

		// Title Fuel Calculations
		autoTable(doc, {
			head: [
				[
					{
						content: document.labels.header.fuel_calculations,
						colSpan: 2,
					},
				],
			],
			body: [
				[document.labels.fuel.plannedTime, document.data.fuel.plannedTime],
				[document.labels.fuel.available, document.data.fuel.available],
				[document.labels.fuel.needed, document.data.fuel.needed],
				[document.labels.fuel.consumption, document.data.fuel.consumption],
			],
			startY: Yfuel,
			theme: 'grid',
			styles: { halign: 'left', valign: 'middle', fontSize: 9, cellPadding: 1 },
			margin: {
				left: this.margin_left + this.column_width + this.margin_blocks,
			},
			tableWidth: this.column_width,
			headStyles: {
				fillColor: '#d3d3d3',
				textColor: '#000000',
				fontSize: 12,
				halign: 'center',
			},
		});
		const Ylift = (doc as any).lastAutoTable.finalY;

		// Title Lift Calculations
		autoTable(doc, {
			head: [
				[
					{
						content: document.labels.header.load_chart,
						colSpan: 2,
					},
				],
			],
			body: [
				[document.labels.lift.temp, document.data.lift.temp],
				[document.labels.lift.pressure, document.data.lift.pressure],
				[document.labels.lift.takeoffElevation, document.data.lift.takeoffElevation],
				[document.labels.lift.maxElevation, document.data.lift.maxElevation],
				// [document.labels.lift.balloonTemp, document.data.lift.balloonTemp],
				[document.labels.lift.volume, document.data.lift.volume],
				[document.labels.lift.lift, document.data.lift.lift],
				[document.labels.lift.liftNeeded, document.data.lift.liftNeeded],
			],
			startY: Ylift,
			theme: 'grid',
			styles: { halign: 'left', valign: 'middle', fontSize: 9, cellPadding: 1 },
			margin: {
				left: this.margin_left + this.column_width + this.margin_blocks,
			},
			tableWidth: this.column_width,
			headStyles: {
				fillColor: '#d3d3d3',
				textColor: '#000000',
				fontSize: 12,
				halign: 'center',
			},
		});
		const Ylift1 = (doc as any).lastAutoTable.finalY;

		// Lift Calculations - asset weights
		autoTable(doc, {
			head: [],
			body: [
				[document.labels.lift.envelope, document.labels.lift.basket, document.labels.lift.burner, document.labels.lift.cylinders],
				[document.data.lift.envelope, document.data.lift.basket, document.data.lift.burner, document.data.lift.cylinders],
			],
			startY: Ylift1,
			theme: 'grid',
			styles: { halign: 'left', valign: 'middle', fontSize: 9, cellPadding: 1 },
			margin: {
				left: this.margin_left + this.column_width + this.margin_blocks,
			},
			tableWidth: this.column_width,
		});
		const Ylift2 = (doc as any).lastAutoTable.finalY;
		// Lift Calculations - signature
		autoTable(doc, {
			head: [],
			body: [
				['Last minute updates', document.labels.footer.signature],
				[
					{
						content: document.data.header.pilotComments,
						styles: { minCellHeight: 12 },
					},
					{
						content: '',
						styles: this.fillinStyle,
					},
				],
			],
			startY: Ylift2,
			theme: 'grid',
			styles: { halign: 'left', valign: 'middle', fontSize: 9, cellPadding: 1 },
			margin: {
				left: this.margin_left + this.column_width + this.margin_blocks,
			},
			tableWidth: this.column_width,
		});

		// footer
		const tableWidthfooter = 2 * this.column_width + this.margin_blocks;
		const cellWidthfooter = tableWidthfooter / 4;

		autoTable(doc, {
			head: [[{ content: document.labels.footer.after_flight, colSpan: 4 }]],
			body: [
				[
					document.labels.footer.visible_defects,
					{
						content: document.labels.footer.yes_no,
						styles: this.fillinStyle,
					},
					document.labels.footer.date,
					{
						content: '',
						styles: this.fillinStyle,
					},
				],
				[
					{ content: '', styles: { minCellHeight: 12 } },
					{ content: '' },
					{ content: document.labels.footer.signature },
					{
						content: '',
						styles: this.fillinStyle,
					},
				],
				[
					{ content: document.labels.footer.defect, colSpan: 2 },
					{ content: document.labels.footer.action, colSpan: 2 },
				],
				[
					{
						content: '',
						colSpan: 2,
						styles: this.fillinStyle,
					},
					{
						content: '',
						colSpan: 2,
						styles: this.fillinStyle,
					},
				],
			],
			startY: Yfooter,
			theme: 'grid',
			margin: { left: this.margin_left },
			styles: {
				halign: 'left',
				valign: 'middle',
				fontSize: 9,
				cellPadding: 1,
				cellWidth: cellWidthfooter,
			},
			tableWidth: tableWidthfooter,
			headStyles: {
				fillColor: '#d3d3d3',
				textColor: '#000000',
				fontSize: 12,
				halign: 'center',
			},
		});
		const Yfooter1 = (doc as any).lastAutoTable.finalY;
		const cellWidthfooter1 = tableWidthfooter / 5;
		autoTable(doc, {
			head: [],
			body: [
				[
					document.labels.footer.certificate,
					document.labels.footer.defect,
					document.labels.footer.authority,
					document.labels.footer.initials,
					document.labels.footer.date,
				],
				[{ content: '', styles: { minCellHeight: 12 } }],
			],
			startY: Yfooter1,
			theme: 'grid',
			margin: { left: this.margin_left },
			styles: {
				halign: 'left',
				valign: 'middle',
				fontSize: 9,
				cellPadding: 1,
				cellWidth: cellWidthfooter1,
			},
			tableWidth: tableWidthfooter,
		});
		const Yfooter2 = (doc as any).lastAutoTable.finalY;

		// ALL - footer
		if (manifestOptions?.footer) {
			this.addFooter(doc, manifestOptions, Yfooter2 + 3);
		}

		// MADOU betaaloverzicht
		if (this.madou)
			addMadouBetaalOverzicht(doc, document, {
				margin_top: this.margin_top,
				margin_left: this.margin_left,
				column_width: this.column_width,
				margin_blocks: this.margin_blocks,
			});

		// MONTGOLFIERE betaaloverzicht
		if (this.montgolfiere)
			addMontgolfiereBetaalOverzicht(doc, document, currency, {
				margin_left: this.margin_left,
				column_width: this.column_width,
				margin_blocks: this.margin_blocks,
			});

		// Ballonschateauxdoex betaaloverzicht
		if (this.ballonschateaudoex)
			addBallonschateaudoexBetaalOverzicht(doc, document, currency, {
				column_width: this.column_width,
				margin_blocks: this.margin_blocks,
				margin_top: this.margin_top,
				margin_left: this.margin_left,
			});

		// Adballon betaaloverzicht
		if (this.adballon)
			addAdballonDetailPassagiers(doc, document, currency, {
				column_width: this.column_width,
				margin_blocks: this.margin_blocks,
				margin_top: this.margin_top,
				margin_left: this.margin_left,
			});

		doc.setProperties({ title: title });
		window.open(doc.output('bloburl').toString());
		doc.save(title);
	}

	addPilotDeclaration(doc, manifestOptions) {
		const Ydeclaration = 200;
		doc.setFontSize(6);
		manifestOptions.pilotDeclaration.forEach((line, index) => {
			doc.text(line, this.margin_left, Ydeclaration + index * 3);
		});
	}
	addFooter(doc, manifestOptions, Y) {
		doc.setFontSize(6);
		manifestOptions.footer.forEach((line, index) => {
			doc.text(line, 105, Y + index * 3, { align: 'center' });
		});
	}

	mapFlightManifest(flight) {
		let manifestFlight: any;
		let callSign = '';
		let balloonType = '';
		let balloonName = '';
		let pic = '';
		let crew = '';
		let flightHour = '';
		let landingTime = '';
		let takeoffTime = '';
		let consumption = '______ L';
		let visual = flight.vfrVisual?.toString(10);
		let clouds = flight.vfrClouds?.toString(10);
		let windSummary = '';
		let i = 0;
		let passengersCount = 0;
		const passengers = [];
		const payments = [];
		const distinct = (value, index, self) => {
			return self.indexOf(value) === index;
		};

		if (flight.balloon) {
			callSign = flight.balloon.callSign;
			balloonType = flight.balloon.envelopeType;
			balloonName = flight.balloon.name;
		}
		if (clouds === '0') {
			clouds = 'CLEAR';
		}
		if (visual === '0') {
			visual = 'CLEAR';
		}
		if (flight.refuelVolume) {
			consumption = flight.refuelVolume + ' L';
		}
		if (flight.bookings.list) {
			flight.bookings.list.forEach((b) => {
				const categories = [];
				b.passengers
					.map((p) => p.category)
					.filter(distinct)
					.sort((c1, c2) => +c1 - +c2)
					.forEach((cat) => {
						categories.push({
							category: cat,
							count: b.passengers.filter((p) => p.category === cat).length,
						});
					});
				const booking_passengers = [];
				b.passengers.forEach((p) => {
					booking_passengers.push({
						name: p.name,
						paymentType: this.getLabel('BOOKINGS.' + p.paymentType),
						paymentTypeEnum: p.paymentType,
						paymentReference: p.paymentReference,
						paymentAmount: p.paymentAmount,
						paymentStatus: this.getLabel('BOOKINGS.' + p.paymentStatus),
						paymentDate: p.paymentDate,
						category: p.category,
						voucher: p.voucher ? p.voucher.voucherReference : null,
						voucherNumber: p.voucherNumber,
						age: p.age,
						weight: p.weight,
						totalWeight: p.totalWeight,
					});
				});

				payments.push({
					contactName: b.contactName,
					contactPhone: b.contactPhone,
					contactEmail: b.contactEmail,
					totalAmount: b.passengerCounters.totalAmount,
					openAmount: b.passengerCounters.openAmount,
					paidAmount: b.passengerCounters.paidAmount,
					prepaymentAmount: b.prepaymentAmount,
					prepaymentDate: b.prepaymentDate,
					passengerCount: b.passengerCounters.passengerCount,
					passengers: booking_passengers,
					categories: categories,
					comments: b.comments,
					city: b.city,
					invoiceName: b.invoiceName,
					VATnumber: b.VATnumber,
				});
				if (b.passengers) {
					b.passengers.forEach((p) => {
						i += 1;
						passengers.push({
							booking: b.contactPhone,
							id: i,
							name: p.name,
							age: p.age,
							weight: p.weight,
							totalWeight: p.totalWeight,
							luggage: 1,
							child: p.child ? 1 : 0,
							disability: p.disability ? 1 : 0,
							transport: p.transport ? 1 : 0,
							signature: p.signature,
							category: p.category,
						});
					});
				}
			});
		}
		passengersCount = passengers.length;
		if (flight.pilot) {
			pic = flight.pilot.crew.name;
			passengers.push({
				booking: flight.pilot.crew.phone,
				id: i + 1,
				name: `${flight.pilot.crew.name} (PIC)`,
				weight: flight.pilot.crew.medicalWeight,
				totalWeight: flight.pilot.crew.totalWeight,
				luggage: 1,
				child: 0,
				disability: 0,
				tranport: 0,
				signature: '',
			});
		}
		if (flight.groundCrews) {
			flight.groundCrews.forEach((c) => {
				crew += c.crew.name + ', ';
			});
		}
		let passengers_total_weight = flight.passengerCounters.passengersWeight;

		if (flight.hour) {
			flightHour = dayjs(flight.hour).format('HH:mm');
		}
		if (flight.landingTime) {
			landingTime = dayjs(flight.landingTime).format('HH:mm');
		}
		if (flight.takeoffTime) {
			takeoffTime = dayjs(flight.takeoffTime).format('HH:mm');
		}
		if (flight.windSummary !== '') {
			windSummary = flight.windSummary;
		} else {
			windSummary = `SURFACE: ${flight.windGlDirection}/${flight.windGl}KT`;
			windSummary = windSummary + '\n' + `1000FT: ${flight.wind10Direction}/${flight.wind10}KT`;
			windSummary = windSummary + '\n' + `2000FT: ${flight.wind20Direction}/${flight.wind20}KT`;
		}

		const flightPeriod = flight.period === 'EVE' ? ' PM' : ' AM';
		const flightDate = dayjs(flight.date).format('dd D-MM-YY') + flightPeriod;
		const flightDateShort = dayjs(flight.date).format('DD/MM/YYYY');
		const weatherDate = dayjs(flight.weatherForecastDate).format('D-MM-YYYY');
		manifestFlight = {
			data: {
				header: {
					type: 'Deprecated',
					docsn: 0, // TODO: flight.id,
					balloon: `${callSign} (${balloonName})`,
					balloonType: balloonType,
					pic: pic,
					crew: crew,
					pilot: flight.pilot,
					ground_crews: flight.groundCrews,
					pilotSignature: flight.pilotSignature,
					pilotComments: flight.pilotComments ? flight.pilotComments : '',
					date: flightDate,
					dateShort: flightDateShort,
					takeoffLocation: flight.location.name,
					takeoffAddress: flight.location.address + ', ' + flight.location.city,
					flightHour: flightHour,
					takeoffTime: takeoffTime,
					landingLocation: flight.landingLocation ? flight.landingLocation : '',
					landingTime: landingTime,
					flightComments: flight.comment,
					passengersCount,
				},
				weather: {
					source: flight.weatherSource,
					date: weatherDate,
					wind: `${flight.windGl} kt`,
					direction: `${flight.windGlDirection}°`,
					wind10: `${flight.wind10} kt - ${flight.wind10Direction}°`,
					wind20: `${flight.wind20} kt - ${flight.wind20Direction}°`,
					windSummary: windSummary,
					visual: visual,
					clouds: clouds,
					temp: `${flight.tempTakeoff} °C`,
					qfe: `${flight.weatherQnh} hPa`,
				},
				fuel: {
					plannedTime: `${flight.plannedFlightTime} min`,
					available: `${flight.maximumFlightTime} min`,
					needed: `${+flight.plannedFlightTime + 30} min`,
					consumption: consumption,
				},
				lift: {
					temp: `${flight.tempTakeoff} °C`,
					pressure: `${flight.weatherQnh} hPA`,
					takeoffElevation: `${flight.location.height} ft`,
					maxElevation: `${flight.maximumHeight} ft`,
					balloonTemp: `${flight.tempBalloon} °C`,
					volume: `${flight.balloon.envelopeVolume} m³`,
					lift: `${flight.liftAvailable} kg`,
					liftNeeded: `${flight.liftNeeded} kg`,
					envelope: `${flight.balloon.envelopeWeight} kg`,
					burner: `${flight.balloon.burnerWeight} kg`,
					basket: `${flight.balloon.basketWeight} kg`,
					cylinders: `${flight.balloon.balloonWeight.cylindersWeight} kg`,
					signature: flight.pilotSignature,
				},
				passengers: passengers,
				passengers_total_weight: passengers_total_weight + (flight.pilot ? flight.pilot.crew.totalWeight : 0),
				payments: payments,
			},
			labels: {
				header: {
					operator: 'OPERATOR',
					docsn: 'DOC S/N',
					balloon: this.getLabel('FLIGHTS.balloon'),
					balloonType: this.getLabel('FLIGHTS.type'),
					pic: 'PIC',
					crew: 'CREW',
					date: this.getLabel('FLIGHTS.date'),
					takeoffLocation: this.getLabel('FLIGHTS.takeoff_location'),
					takeoffAddress: this.getLabel('LOCATIONS.address'),
					takeoffTime: this.getLabel('FLIGHTS.takeoffTime'),
					landingLocation: this.getLabel('FLIGHTS.landingLocation'),
					landingTime: this.getLabel('FLIGHTS.landingTime'),
					load_chart_and_passenger_list: this.getLabel('MANIFEST.load_chart_and_passenger_list'),
					passenger_list: this.getLabel('MANIFEST.passenger_list'),
					weather_conditions: this.getLabel('MANIFEST.weather_conditions'),
					fuel_calculations: this.getLabel('MANIFEST.fuel_calculations'),
					load_chart: this.getLabel('MANIFEST.load_chart'),
					flightComments: this.getLabel('FLIGHTS.comment'),
				},
				weather: {
					source: this.getLabel('FLIGHTS.weather_source'),
					date: this.getLabel('FLIGHTS.weather_forecast_date'),
					wind: this.getLabel('FLIGHTS.wind_gl_short'),
					direction: this.getLabel('FLIGHTS.wind_dir'),
					wind10: this.getLabel('FLIGHTS.wind_10'),
					wind20: this.getLabel('FLIGHTS.wind_20'),
					windSummary: this.getLabel('FLIGHTS.windSummary'),
					visual: this.getLabel('FLIGHTS.vfr_visual'),
					clouds: this.getLabel('FLIGHTS.vfr_clouds'),
					temp: this.getLabel('FLIGHTS.temp'),
					qfe: 'QNH',
				},
				fuel: {
					plannedTime: this.getLabel('FLIGHTS.planned_flight_time'),
					available: this.getLabel('MANIFEST.fuel_available'),
					needed: this.getLabel('MANIFEST.fuel_needed'),
					consumption: this.getLabel('MANIFEST.fuel_consumption'),
				},
				lift: {
					temp: this.getLabel('FLIGHTS.temp_takeoff'),
					pressure: this.getLabel('FLIGHTS.weather_qfe'),
					takeoffElevation: this.getLabel('LOCATIONS.height'),
					maxElevation: this.getLabel('FLIGHTS.maximum_height'),
					balloonTemp: this.getLabel('FLIGHTS.balloon_temp'),
					volume: this.getLabel('BALLOONS.envelope_volume'),
					lift: this.getLabel('FLIGHTS.lift_available'),
					liftNeeded: this.getLabel('FLIGHTS.lift_needed'),
					envelope: this.getLabel('BALLOONS.envelope'),
					burner: this.getLabel('BALLOONS.burner'),
					basket: this.getLabel('BALLOONS.basket'),
					cylinders: this.getLabel('BALLOONS.cylinder'),
					signature: this.getLabel('FLIGHTS.signature'),
				},
				passengers: {
					name: this.getLabel('BOOKINGS.name'),
					weight: this.getLabel('BOOKINGS.weight'),
					luggage: this.getLabel('BOOKINGS.luggage'),
					child: this.getLabel('BOOKINGS.child'),
					disability: this.getLabel('BOOKINGS.disability'),
					signature: this.getLabel('BOOKINGS.signature'),
				},
				payments: {
					total_amount: this.getLabel('BOOKINGS.totalAmount'),
					open_amount: this.getLabel('BOOKINGS.openAmount'),
					paid_amount: this.getLabel('BOOKINGS.paid_amount'),
					paid_prepaymentamount: this.getLabel('BOOKINGS.prepayment'),
					prepayment_date: this.getLabel('BOOKINGS.prepaymentDate'),
					payment_type: this.getLabel('BOOKINGS.paymentType'),
					payment_reference: this.getLabel('BOOKINGS.paymentReference'),
					payment_amount: this.getLabel('BOOKINGS.paymentAmount'),
					payment_status: this.getLabel('BOOKINGS.paymentStatus'),
					payment_date: this.getLabel('BOOKINGS.paymentDate'),
					voucher: this.getLabel('VOUCHERS.voucher'),
				},
				footer: {
					confirmation: this.getLabel('MANIFEST.confirmation'),
					after_flight: this.getLabel('MANIFEST.after_flight'),
					visible_defects: this.getLabel('MANIFEST.visible_defects'),
					yes_no: this.getLabel('MANIFEST.yes_no'),
					date: this.getLabel('MANIFEST.date'),
					signature: this.getLabel('MANIFEST.signature'),
					defect: this.getLabel('MANIFEST.defect'),
					action: this.getLabel('MANIFEST.action'),
					certificate: this.getLabel('MANIFEST.certificate'),
					authority: this.getLabel('MANIFEST.authority'),
					initials: this.getLabel('MANIFEST.initials'),
				},
			},
		};
		return manifestFlight;
	}

	getLabel(value: any) {
		if (value) {
			return this.translateCustom.translate(value, 'en').toUpperCase();
		}
		return value;
	}
	capitalize(value: string) {
		if (value) {
			return value.charAt(0).toUpperCase() + value.slice(1);
		}
		return value;
	}

	async generatePaymentPDF(tenant, flight, save: boolean = true, display: boolean = true, currency = 'EUR') {
		if (tenant.toLowerCase() === 'culiair') {
			return this.generatePaymentPDFCuliAir(flight, save, display, currency);
		} else {
			return this.generatePaymentPDFAll(flight, save, display, currency);
		}
	}

	async generatePaymentPDFAll(flight, save: boolean = true, display: boolean = true, currency = 'EUR') {
		const document = this.mapFlightManifest(flight);
		const title = dayjs(flight.date).format('YYYYMMDD') + '_' + flight.period + '_' + document.data.header.balloon + '-payments.pdf';
		const doc = new jsPDF(),
			margins = {
				top: this.margin_top,
				left: this.margin_left,
			};
		autoTable(doc, {
			head: [[{ content: 'PAYMENTS', colSpan: 4 }]],
			body: [
				[document.data.header.balloon, document.data.header.date, document.data.header.pic, document.data.header.takeoffLocation],
				[
					{
						content: document.data.header.flightComments,
						colSpan: 4,
						styles: { fontSize: 9 },
					},
				],
			],
			startY: this.margin_top,
			theme: 'grid',
			margin: { left: this.margin_left },
			styles: {
				halign: 'left',
				valign: 'middle',
				fontSize: 14,
				cellPadding: 1,
			},
			tableWidth: 2 * this.column_width + this.margin_blocks,
			headStyles: {
				fillColor: '#d3d3d3',
				textColor: '#000000',
				fontSize: 14,
				halign: 'center',
			},
		});

		// Booking overview
		const categories = [];
		document.data.payments.forEach((p) => {
			let invoiceAndVat = `${p.invoiceName ? p.invoiceName : ''}\n${p.VATnumber ? p.VATnumber : ''}`;
			categories.push([
				{
					content: `${p.contactName}\n${p.contactPhone}`,
					styles: { fontStyle: 'bold', fontSize: 12 },
					colSpan: 2,
				},
				{
					content: p.passengerCount,
					styles: { halign: 'center', fontSize: 12 },
				},
				{
					content: p.prepaymentAmount ? currency + ' ' + p.prepaymentAmount : '',
					styles: {
						halign: 'center',
						fontSize: 12,
					},
				},
				{
					content: currency + ' ' + p.openAmount,
					styles: {
						fontSize: 12,
						fontStyle: 'bold',
						textColor: p.openAmount > 0 ? '#B50023' : '#555555',
						halign: 'center',
					},
				},
				{
					content: invoiceAndVat,
					styles: { fontStyle: 'bold', fontSize: 12 },
				},
				{
					content: currency + ' ' + p.totalAmount,
					styles: {
						fontSize: 12,
						fontStyle: 'bold',
						textColor: '#555555',
						halign: 'center',
					},
				},
			]);
			categories.push([{ content: p.comments ? p.comments : '', colSpan: 7 }]);
			p.passengers.forEach((pax, index) => {
				let paxName = pax.age ? pax.name + ' ' + `(${pax.age}y)` : pax.name;
				categories.push([
					{ content: index + 1 + '. ' + paxName, colSpan: 3 },
					{ content: pax.paymentType },
					{ content: currency + ' ' + (pax.paymentAmount ? pax.paymentAmount : 0), styles: { halign: 'center' } },
					{
						content:
							(pax.category ? 'Cat: ' + pax.category.name : '') +
							(pax.paymentTypeEnum === PaymentType.Coup ? ', Voucher: ' + pax.voucher : '') +
							(pax.paymentTypeEnum === PaymentType.Vouc ? ', Voucher: ' + pax.voucherNumber : ''),
						colSpan: 2,
					},
				]);
			});
		});
		const Ybetaaloverzicht = (doc as any).lastAutoTable.finalY;

		autoTable(doc, {
			head: [['BOOKING', '', '#', 'PREPAID', 'OPEN', 'INVOICE NAME & VAT', 'TOTAL']],
			body: categories,
			startY: Ybetaaloverzicht + 3,
			theme: 'grid',
			margin: { left: this.margin_left },
			styles: {
				halign: 'left',
				valign: 'middle',
				fontSize: 8,
				// cellPadding: 4,
				cellPadding: {
					top: 1,
					bottom: 1,
					left: 2,
					right: 2,
				},
			},
			tableWidth: 2 * this.column_width + this.margin_blocks,
			headStyles: {
				fillColor: '#d3d3d3',
				textColor: '#000000',
				fontSize: 12,
				halign: 'left',
			},
		});

		doc.setProperties({ title: title });
		if (display) window.open(doc.output('bloburl').toString());
		if (save) doc.save(title);
		return { title, pdf: doc.output('blob') };
	}

	async generatePaymentPDFCuliAir(
		flight,
		save: boolean = true,
		display: boolean = true,
		currency = 'EUR'
	): Promise<{ title: string; pdf: any }> {
		this.tenantSettingService.getTenantSettingMyFlight().valueChanges.subscribe((result) => {
			const logo = result.data.tenantSetting.operatorSettings.myflight.logo;
			let imgWidth = 40;
			let imgHeight = 30;

			const fillColor = result.data.tenantSetting.operatorSettings.myflight.colors[3];
			const textColor = result.data.tenantSetting.operatorSettings.myflight.colors[0];

			const document = this.mapFlightManifest(flight);
			const title = dayjs(flight.date).format('YYYYMMDD') + '_' + flight.period + '_' + document.data.header.balloon + '-passengerlist.pdf';
			const doc = new jsPDF(),
				margins = {
					top: this.margin_top,
					left: this.margin_left,
				};

			doc.addImage(logo, 'jpg', 150, 15, imgWidth, imgHeight);
			autoTable(doc, {
				head: [[{ content: 'VAART', colSpan: 3 }]],
				body: [
					[{ content: document.data.header.balloon, colSpan: 3 }],
					[document.data.header.takeoffLocation, document.data.header.date, document.data.header.flightHour],
					[
						{
							content: document.data.header.flightComments,
							colSpan: 3,
							styles: { fontSize: 9 },
						},
					],
				],
				startY: this.margin_top + 30,
				theme: 'grid',
				margin: { left: this.margin_left },
				styles: {
					halign: 'left',
					valign: 'middle',
					fontSize: 14,
					cellPadding: 1,
				},
				tableWidth: 2 * this.column_width + this.margin_blocks,
				headStyles: {
					fillColor,
					textColor,
					fontSize: 14,
					halign: 'center',
				},
			});

			// Booking overview
			const categories = [];
			document.data.payments.forEach((p) => {
				categories.push([
					{ content: p.contactName, styles: { fontStyle: 'bold', fontSize: 12 }, colSpan: 2 },
					{ content: p.contactPhone, styles: { fontSize: 12 } },
					{ content: p.contactEmail, styles: { fontSize: 12 } },
				]);
				categories.push([{ content: p.comments ? p.comments : '', colSpan: 4 }]);
				p.passengers.forEach((pax, index) => {
					let paxAge = pax.age ? `${pax.age} y` : '';
					categories.push([
						{ content: index + 1 + '. ' + pax.name },
						{ content: paxAge },
						{ content: `${pax.weight} kg` },
						{ content: '' },
					]);
				});
			});
			// add pic from data.header.pic as last line
			// categories.push([
			// 	{ content: document.data.header.pilot.crew.name, styles: { fontStyle: 'bold', fontSize: 12 }, colSpan: 2 },
			// 	{ content: document.data.header.pilot.crew.phone, styles: { fontSize: 12 }, },
			// 	{ content: document.data.header.pilot.crew.email, styles: { fontSize: 12, }, },

			// ]);
			// categories.push([
			// 	{ content: document.data.header.pilot.crew.name, colSpan: 4 },
			// ]);
			// crew each of them as line
			document.data.header.ground_crews.forEach((g) => {
				categories.push([{ content: g.crew.name, colSpan: 4 }]);
			});
			const Ybetaaloverzicht = (doc as any).lastAutoTable.finalY;

			autoTable(doc, {
				head: [['RESERVATIE', '', 'TELEFOON', 'EMAIL']],
				body: categories,
				startY: Ybetaaloverzicht + 3,
				theme: 'grid',
				margin: { left: this.margin_left },
				styles: {
					halign: 'left',
					valign: 'middle',
					fontSize: 8,
					// cellPadding: 4,
					cellPadding: {
						top: 1,
						bottom: 1,
						left: 2,
						right: 2,
					},
				},
				tableWidth: 2 * this.column_width + this.margin_blocks,
				headStyles: {
					fillColor: '#FADECB',
					textColor: '#EC660B',
					fontSize: 12,
					halign: 'left',
				},
			});

			doc.setProperties({ title: title });
			if (display) window.open(doc.output('bloburl').toString());
			if (save) doc.save(title);
			return { title, pdf: doc.output('blob') };
		});
		return { title: '', pdf: null };
	}

	// passenger checkin PDF for Ballooning/Filva
	generateCheckinPDF(flight, save: boolean = true, display: boolean = true) {
		const document = this.mapFlightManifest(flight);
		const title = dayjs(flight.date).format('YYYYMMDD') + '_' + flight.period + '_' + document.data.header.balloon + '-checkin.pdf';
		const doc = new jsPDF(),
			margins = {
				top: this.margin_top,
				left: this.margin_left,
			};
		autoTable(doc, {
			head: [[{ content: 'CHECK-IN', colSpan: 4 }]],
			body: [
				[document.data.header.balloon, document.data.header.date, document.data.header.pic, document.data.header.takeoffLocation],
				[
					{
						content: document.data.header.flightComments,
						colSpan: 4,
						styles: { fontSize: 9 },
					},
				],
			],
			startY: this.margin_top,
			theme: 'grid',
			margin: { left: this.margin_left },
			styles: {
				halign: 'left',
				valign: 'middle',
				fontSize: 14,
				cellPadding: 1,
				textColor: '#000000',
			},
			tableWidth: 2 * this.column_width + this.margin_blocks,
			headStyles: {
				fillColor: '#d3d3d3',
				textColor: '#000000',
				fontSize: 14,
				halign: 'center',
			},
		});

		// Booking overview
		const passengers = [];
		document.data.passengers.forEach((pax, index) => {
			let paxName = pax.age ? pax.name + ' ' + `(${pax.age}y)` : pax.name;
			let paxWeight = pax.totalWeight ? pax.totalWeight : '';
			passengers.push([{ content: index + 1 + '. ' + paxName }, { content: paxWeight }, { content: '' }]);
		});

		const Ybetaaloverzicht = (doc as any).lastAutoTable.finalY;

		autoTable(doc, {
			head: [['BOOKING', 'WEIGHT', 'SIGNATURE']],
			body: passengers,
			startY: Ybetaaloverzicht + 3,
			theme: 'grid',
			margin: { left: this.margin_left },
			styles: {
				halign: 'left',
				valign: 'middle',
				fontSize: 12,
				textColor: '#000000',
				// cellPadding: 4,
				cellPadding: {
					top: 1,
					bottom: 1,
					left: 2,
					right: 2,
				},
				minCellHeight: 8,
			},
			tableWidth: 2 * this.column_width + this.margin_blocks,
			headStyles: {
				fillColor: '#d3d3d3',
				textColor: '#000000',
				fontSize: 12,
				halign: 'left',
			},
		});

		const Yfooter = 260;

		const footerText1 = 'Door deze lijst af te tekenen geeft de passagier aan dat';
		const footerText2 = '- hij een veiligheidsbriefing gekregen heeft van de piloot';
		const footerText3 = '- hij kennis neemt van de voorwaarden die aan de ommezijde van dit formulier staan om mee te ballonvaren';
		const footerText4 = '- en deze aanvaardt';
		// Footer
		doc.setFontSize(10);
		doc.text(footerText1, this.margin_left, Yfooter + 10);
		doc.text(footerText2, this.margin_left, Yfooter + 14);
		doc.text(footerText3, this.margin_left, Yfooter + 18);
		doc.text(footerText4, this.margin_left, Yfooter + 22);

		doc.setProperties({ title: title });
		if (display) window.open(doc.output('bloburl').toString());
		if (save) doc.save(title);
		return { title, pdf: doc.output('blob') };
	}
}
