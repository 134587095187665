<div style="display: flex; flex-direction: column; gap: 2px">
	<mat-card style="width: 360px" class="mat-elevation-z8">
		<mat-card-content>
			<div style="display: flex; gap: 5px; align-items: center">
				<div style="display: flex; gap: 10px; width: 315px; font-size: medium; font-weight: bold; align-items: center">
					<mat-icon>{{ group.icon }}</mat-icon>
					{{ group.name | uppercase }}
				</div>
			</div>
		</mat-card-content>
	</mat-card>
	<div *ngIf="draggable" style="display: flex; flex-direction: column; gap: 2px">
		<div cdkDropList [id]="group.id" [cdkDropListData]="group.bookings" (cdkDropListDropped)="drop($event)">
			@for (booking of group.bookings; track booking) {
			<div cdkDrag [cdkDragData]="booking">
				<app-booking-card [bookingId]="booking?.id" [draggable]="draggable"></app-booking-card>
			</div>
			}
			<mat-list dense *ngIf="group.bookings.length === 0">
				<mat-list-item>
					<h3 *ngIf="group.id === -1" style="display: flex; justify-content: center; align-items: center" cdkDragHandle mat-line>
						{{ 'CALENDAR.drag_and_drop_reservations_here_to_remove_from_this_flight' | translate | capitalize }}
					</h3>
					<h3 *ngIf="group.id === 0" style="display: flex; justify-content: center; align-items: center" mat-line>
						{{ 'CALENDAR.good_job' | translate | uppercase }}
					</h3>
					<h3 *ngIf="group.id === 0" style="display: flex; justify-content: center; align-items: center" mat-line>
						{{ 'CALENDAR.all_bookings_are_added_to_a_flight' | translate | capitalize }}
					</h3>
					<h3 *ngIf="group.id === 0" style="display: flex; justify-content: center; align-items: center" mat-line>
						{{ 'CALENDAR.you_cannot_drop_bookings_here' | translate | capitalize }}
					</h3>
				</mat-list-item>
			</mat-list>
		</div>
	</div>
</div>
